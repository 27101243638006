import { selfUpdateUserComprehensive } from "@/services/user-service";
import { useUserStore } from "@/stores/user-store";
import { Card, Switch, Typography, message } from "antd";
import type { FC } from "react";
import { useCallback, useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";

const { Title, Text } = Typography;

interface SettingsState {
	autoSaveDrafts: boolean;
	useCameraOnPhotoCapture: boolean;
	isLoading: boolean;
}

type SettingsAction =
	| { type: "SET_AUTO_SAVE"; payload: boolean }
	| { type: "SET_CAMERA_USAGE"; payload: boolean }
	| { type: "SET_LOADING"; payload: boolean }
	| {
			type: "SYNC_WITH_USER";
			payload: { autoSave?: boolean; cameraUsage?: boolean };
	  };

const initialState: SettingsState = {
	autoSaveDrafts: true,
	useCameraOnPhotoCapture: false,
	isLoading: false,
};

const settingsReducer = (
	state: SettingsState,
	action: SettingsAction,
): SettingsState => {
	switch (action.type) {
		case "SET_AUTO_SAVE":
			return {
				...state,
				autoSaveDrafts: action.payload,
			};
		case "SET_CAMERA_USAGE":
			return {
				...state,
				useCameraOnPhotoCapture: action.payload,
			};
		case "SET_LOADING":
			return {
				...state,
				isLoading: action.payload,
			};
		case "SYNC_WITH_USER":
			return {
				...state,
				autoSaveDrafts: action.payload.autoSave ?? state.autoSaveDrafts,
				useCameraOnPhotoCapture:
					action.payload.cameraUsage ?? state.useCameraOnPhotoCapture,
			};
		default:
			return state;
	}
};

export const SettingsTab: FC = () => {
	const navigate = useNavigate();
	const { user, setUser } = useUserStore();
	const [state, dispatch] = useReducer(settingsReducer, initialState);

	useEffect(() => {
		if (user) {
			dispatch({
				type: "SYNC_WITH_USER",
				payload: {
					autoSave: user.organization?.config?.auto_save_drafts ?? true,
					cameraUsage: user.user_config?.use_camera_on_photo_capture,
				},
			});
		}
	}, [user]);

	const handleAutoSaveChange = useCallback(
		async (checked: boolean) => {
			if (!user?.organization) return;

			const previousUser = { ...user };
			const updatedUser = {
				...user,
				organization: {
					...user.organization,
					config: {
						...user.organization.config,
						auto_save_drafts: checked,
					},
				},
			};

			try {
				dispatch({ type: "SET_LOADING", payload: true });

				// Optimistic update
				setUser(updatedUser);
				dispatch({ type: "SET_AUTO_SAVE", payload: checked });

				// Sync with backend
				await selfUpdateUserComprehensive(updatedUser);
				message.success("Auto-save setting updated successfully");
			} catch (error: any) {
				// Rollback on failure
				setUser(previousUser);
				dispatch({ type: "SET_AUTO_SAVE", payload: !checked });
				message.error(error.message || "Failed to update auto-save setting");
			} finally {
				dispatch({ type: "SET_LOADING", payload: false });
			}
		},
		[user, setUser],
	);

	const handleCameraChange = useCallback(
		async (checked: boolean) => {
			if (!user) return;

			const previousUser = { ...user };
			const updatedUser = {
				...user,
				user_config: {
					...user.user_config,
					use_camera_on_photo_capture: checked,
				},
			};

			try {
				dispatch({ type: "SET_LOADING", payload: true });

				// Optimistic update
				setUser(updatedUser);
				dispatch({ type: "SET_CAMERA_USAGE", payload: checked });

				// Sync with backend
				const result = await selfUpdateUserComprehensive(updatedUser);
				message.success("Camera setting updated successfully");
			} catch (error: any) {
				// Rollback on failure
				setUser(previousUser);
				dispatch({ type: "SET_CAMERA_USAGE", payload: !checked });
				message.error(error.message || "Failed to update camera setting");
			} finally {
				dispatch({ type: "SET_LOADING", payload: false });
			}
		},
		[user, setUser],
	);

	if (!user) {
		return null;
	}

	return (
		<div className="max-w-2xl mx-auto p-6">
			<Title level={4} className="mb-2">
				Settings
			</Title>
			<Text className="text-gray-500 block mb-6">
				Manage your application settings here.
			</Text>

			<Card className="shadow-sm">
				<div className="space-y-6">
					<div className="flex justify-between items-center">
						<div>
							<Text strong className="block">
								Auto Save Drafts
							</Text>
							<Text className="text-gray-500">
								Automatically save your work as drafts
							</Text>
						</div>
						<Switch
							checked={state.autoSaveDrafts}
							onChange={handleAutoSaveChange}
							disabled={state.isLoading}
						/>
					</div>

					<div className="flex justify-between items-center">
						<div>
							<Text strong className="block">
								Use Camera on Photo Capture
							</Text>
							<Text className="text-gray-500">
								Enable camera access for photo capture
							</Text>
						</div>
						<Switch
							checked={state.useCameraOnPhotoCapture}
							onChange={handleCameraChange}
							disabled={state.isLoading}
						/>
					</div>

					<div className="pt-4 border-t">
						<Text
							className="text-blue-600 cursor-pointer hover:underline"
							onClick={() => navigate("/releases")}
						>
							View Releases
						</Text>
					</div>
				</div>
			</Card>
		</div>
	);
};
