// GeneratedPropIcon.tsx

import FacilityAI_Logo_Mini from "@/assets/img/FacilityAI_Logo_Mini.svg";
import { Image, Tooltip, theme } from "antd";
import type { FC } from "react";

interface GeneratedPropIconProps {
	message?: string;
}

export const GeneratedPropIcon: FC<GeneratedPropIconProps> = ({ message }) => {
	const { token } = theme.useToken();

	return (
		<Tooltip
			title={
				message ||
				"This data was generated using FacilityAI's proprietary algorithm."
			}
		>
			<Image
				preview={false}
				src={FacilityAI_Logo_Mini}
				alt="FacilityAI Generated Content"
				color={token.colorPrimary}
				height={22}
				width={22}
			/>
		</Tooltip>
	);
};
