import logo from "@/assets/img/FacilityAI_Logo.svg";
import { type FC } from "react";

export const AppInitLoader: FC = () => {
	return (
		<div className="w-screen h-screen flex flex-col justify-center items-center bg-gradient-to-br from-blue-50 to-indigo-100">
			<div className="flex flex-col items-center gap-6 p-8 bg-white/80 backdrop-blur-sm rounded-xl shadow-lg">
				<div className="relative">
					<img src={logo} alt="Logo" className="w-32 h-32" />
					<div className="absolute inset-0 animate-ping bg-blue-200 rounded-full opacity-20"></div>
				</div>
				<div className="text-indigo-700 text-2xl font-semibold animate-pulse tracking-wide">
					Loading Application...
				</div>
				<div className="text-gray-500 flex flex-col items-center">
					<span className="text-lg font-medium animate-pulse">
						Preparing your workspace
					</span>
					<span className="text-sm mt-2 animate-pulse opacity-75">
						This may take a few moments
					</span>
					<div className="mt-4 flex gap-2">
						<div className="w-2 h-2 bg-indigo-400 rounded-full animate-bounce"></div>
						<div className="w-2 h-2 bg-indigo-400 rounded-full animate-bounce delay-100"></div>
						<div className="w-2 h-2 bg-indigo-400 rounded-full animate-bounce delay-200"></div>
					</div>
				</div>
			</div>
		</div>
	);
};
