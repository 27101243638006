import { DFPEmpty } from "@/components/empty/dfp-empty";
import { CopyableCellRenderer } from "@/components/tables/renderers/copyable-cell-renderer";
import { decamelize, parseDateToUTCString } from "@/utils/transforms";
import {
	Button,
	Card,
	List,
	Space,
	Tag,
	Timeline,
	Typography,
	theme,
} from "antd";
import React, { useState } from "react";
import { BsArrowRight } from "react-icons/bs";

const { Text } = Typography;

// ---------- Type Definitions ----------
interface EditLogProps {
	editLog: HistoryEntry[];
}

interface HistoryEntry {
	table_name: string;
	id: number;
	row_id: number;
	firebase_uuid: string | null;
	action: "I" | "D" | "U";
	new_data: Array<{ [key: string]: any }>;
	original_data: Array<{ [key: string]: any }>;
	schema_name: string;
	system_account: string;
	timestamp: string; // ISO string
	user: User | null;
}

interface User {
	id: number;
	name: string;
	// ... other user fields as needed
}

interface ParsedField {
	field: string;
	oldValue: string | number | boolean;
	newValue: string | number | boolean;
}

// ---------- Main Component ----------
const EditLog: React.FC<EditLogProps> = ({ editLog }) => {
	const [isCollapsed, setIsCollapsed] = useState(false);
	const [collapsedItems, setCollapsedItems] = useState<{
		[key: number]: boolean;
	}>({});
	const { token } = theme.useToken();

	// Helper to nicely display the user
	const renderEditedBy = (user: string) => {
		return (
			<Tag color={token.colorWarningBorderHover}>{user || "Unknown User"}</Tag>
		);
	};

	// Helper to display a date in UTC
	const renderDateTitle = (timestamp: string) => {
		return <Text strong>{parseDateToUTCString(timestamp, true)}</Text>;
	};

	// Given two objects, returns an array of field differences
	const renderChanges = (originalData: any, newData: any) => {
		const fields = new Set([
			...Object.keys(originalData),
			...Object.keys(newData),
		]);
		return Array.from(fields).map((field) => {
			const oldValue =
				originalData[field] !== undefined ? originalData[field] : "N/A";
			const newValue = newData[field] !== undefined ? newData[field] : "N/A";

			return {
				field: decamelize(field),
				oldValue,
				newValue,
			};
		});
	};

	// Expand/collapse a single timeline item
	const toggleItemCollapse = (index: number) => {
		setCollapsedItems((prev) => ({
			...prev,
			[index]: !prev[index],
		}));
	};

	const timelineItems = editLog?.map((entry, index) => {
		const isItemCollapsed = collapsedItems[index];
		const userName =
			entry.user?.name || decamelize(entry.system_account) || "Unknown User";

		// Merge all array elements in original_data / new_data
		const mergedOriginal = entry?.original_data?.length
			? Object.assign({}, ...entry.original_data)
			: {};
		const mergedNew = entry?.new_data?.length
			? Object.assign({}, ...entry.new_data)
			: {};

		// Now generate the list of differences
		const changes = renderChanges(mergedOriginal, mergedNew);

		return (
			<Timeline.Item key={`${index}-${entry.timestamp}`}>
				<Card
					type="inner"
					title={renderDateTitle(entry.timestamp)}
					extra={
						<Space>
							{renderEditedBy(userName)}
							<Button
								size="small"
								type="primary"
								onClick={() => toggleItemCollapse(index)}
							>
								{isItemCollapsed ? "Expand" : "Collapse"}
							</Button>
						</Space>
					}
					bordered={!isItemCollapsed}
				>
					{!isItemCollapsed && (
						<List
							locale={{
								emptyText: (
									<DFPEmpty description="There is no edit history for this submission." />
								),
							}}
							dataSource={changes}
							renderItem={(change: ParsedField) => (
								<List.Item>
									<Space direction="vertical">
										<Text strong>{change.field}</Text>
										<Space>
											<CopyableCellRenderer
												className="red"
												value={
													<Text type="danger">
														{change.oldValue.toString()}
													</Text>
												}
											/>
											<BsArrowRight />
											<CopyableCellRenderer
												className="green"
												value={change.newValue.toString()}
											/>
										</Space>
									</Space>
								</List.Item>
							)}
						/>
					)}
				</Card>
			</Timeline.Item>
		);
	});

	return (
		<Card
			title="Edit History"
			extra={
				<Button type="primary" onClick={() => setIsCollapsed(!isCollapsed)}>
					{isCollapsed ? "Show" : "Hide"}
				</Button>
			}
		>
			{!isCollapsed && (
				<Timeline>
					{timelineItems.length > 0 ? (
						timelineItems
					) : (
						<Timeline.Item>
							<DFPEmpty description="There is no edit history for this submission." />
						</Timeline.Item>
					)}
				</Timeline>
			)}
		</Card>
	);
};

export { EditLog };
