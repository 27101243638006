import { DynamicLocationPicker } from "@/components/inputs/dynamic-location-input";
import { getOrganizationReport } from "@/services/organization-service";
import type { IOrganization, IReport } from "@/stores/user-store";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Card, Select, Tooltip, message } from "antd";
import type { FC } from "react";
import { useEffect, useReducer } from "react";

interface ReportState {
	selectedReport: IReport | null;
	selectedLocation: string | null | undefined;
	isLoading: boolean;
}

type ReportAction =
	| { type: "SET_SELECTED_REPORT"; payload: IReport | null }
	| { type: "SET_SELECTED_LOCATION"; payload: string | null | undefined }
	| { type: "SET_LOADING"; payload: boolean }
	| { type: "RESET_STATE" };

// Initial state
const initialState: ReportState = {
	selectedReport: null,
	selectedLocation: null,
	isLoading: false,
};

// Reducer
const reportReducer = (
	state: ReportState,
	action: ReportAction,
): ReportState => {
	console.log("state", state);
	console.log("action", action);
	switch (action.type) {
		case "SET_SELECTED_REPORT":
			return {
				...state,
				selectedReport: action.payload,
			};
		case "SET_SELECTED_LOCATION":
			return {
				...state,
				selectedLocation: action.payload,
			};
		case "SET_LOADING":
			return {
				...state,
				isLoading: action.payload,
			};
		case "RESET_STATE":
			return initialState;
		default:
			return state;
	}
};

// Component
export const ReportsTab: FC<{ organization: IOrganization }> = ({
	organization,
}) => {
	const [state, dispatch] = useReducer(reportReducer, initialState);
	const { selectedReport, selectedLocation, isLoading } = state;
	// Filter only enabled reports
	const availableReports = organization.reports.filter(
		(report) => report.enabled,
	);

	// Handlers
	const handleReportSelect = (reportId: number) => {
		const report = availableReports.find((r) => r.report_id === reportId);
		dispatch({ type: "SET_SELECTED_REPORT", payload: report || null });
	};

	const handleLocationSelect = (locationId: string | null) => {
		dispatch({
			type: "SET_SELECTED_LOCATION",
			payload: locationId ?? undefined,
		});
	};

	// biome-ignore lint/correctness/useExhaustiveDependencies: Reset state when organization changes
	useEffect(() => {
		dispatch({ type: "RESET_STATE" });
	}, [organization.external_firebase_id]);

	const handleGenerateReport = async () => {
		if (!selectedReport) return;

		dispatch({ type: "SET_LOADING", payload: true });

		try {
			await getOrganizationReport({
				reportId: selectedReport.report_id.toString(),
				locationId: selectedLocation ?? undefined,
			});
			message.success(
				"Report generation started. You will be notified when it's ready.",
			);
		} catch (error) {
			message.error("Failed to generate report. Please try again.");
			console.error("Report generation error:", error);
		} finally {
			dispatch({ type: "SET_LOADING", payload: false });
		}
	};

	return (
		<Card
			className="my-3"
			title={<span className="fw-bold">Download Reports</span>}
		>
			<div className="space-y-4">
				<Select
					className="w-full"
					placeholder="Select a report"
					value={selectedReport?.report_id}
					onChange={handleReportSelect}
					options={availableReports.map((report) => ({
						value: report.report_id,
						label: report.master_report_key.report_name,
					}))}
				/>

				{selectedReport?.master_report_key.description && (
					<div className="text-gray-500 text-sm mt-2">
						{selectedReport.master_report_key.description}
					</div>
				)}

				<div className="mt-4">
					<label htmlFor="location-select" className="text-sm !mb-2">
						Select a location
						<Tooltip
							className="ml-2"
							title="If you don't select a location, the report will be generated for all locations."
						>
							<InfoCircleOutlined />
						</Tooltip>
					</label>
					<DynamicLocationPicker
						id="location-select"
						value={selectedLocation || null}
						onChange={handleLocationSelect}
						disabled={false}
						placeholder="Select a location (optional)"
						allowClear={true}
					/>
				</div>

				<div className="mt-4">
					<Button
						type="primary"
						onClick={handleGenerateReport}
						disabled={!selectedReport}
						loading={isLoading}
					>
						Generate Report
					</Button>
				</div>
			</div>
		</Card>
	);
};
