import { DFPModal } from "@/components/modals/dfp-modal";
import { CSVExporter } from "@/components/pure/csv-expoter/csv-exporter";
import { csvFieldTransformFnMap } from "@/components/pure/csv-expoter/field-transforms";
import { searchAssets } from "@/services/asset-service";
import { selfOrganizationAssetTypes } from "@/services/organization-service";
import { isUnknownValue } from "@/utils/isUnknownValue";
import { Button, Select, message } from "antd";
import { Dispatch, FC, useCallback, useEffect, useState } from "react";
import { State } from "./AssetsTable";

type Action = { type: "SET_SHOW_SC_EXPORT"; payload: boolean };

interface SCExportDialogProps {
	state: State;
	dispatch: Dispatch<Action>;
	browserLocationId: string | undefined;
}

type CategoryOption = {
	label: string;
	value: string;
};

type AssetType = {
	name: string;
};

export const SCExportDialog: FC<SCExportDialogProps> = ({
	state,
	dispatch,
	browserLocationId,
}) => {
	const { searchVal, pageNum, pageSize, sortBy, sortOrder, dateRange } = state;
	const [loading, setLoading] = useState<boolean>(false);
	const [chosenCategory, setChosenCategory] = useState<string | null>(null);
	const [categories, setCategories] = useState<CategoryOption[]>([]);
	const [csvData, setCsvData] = useState<any[] | null>(null);

	const loadAssetTypesData = async () => {
		try {
			const res: AssetType[] = await selfOrganizationAssetTypes();
			const options = res.map((type) => ({
				label: type.name,
				value: type.name,
			}));
			setCategories(options);
		} catch (error) {
			console.error("Failed to load asset types", error);
			setCategories([]);
		}
	};

	useEffect(() => {
		loadAssetTypesData();
	}, []);

	const handleExport = useCallback(async () => {
		message.info("Exporting...");
		setLoading(true);

		const columnFilters = chosenCategory
			? { asset_type: [chosenCategory] }
			: {};

		try {
			const assetResults = await searchAssets(
				searchVal,
				pageNum,
				pageSize,
				sortBy,
				sortOrder,
				browserLocationId,
				dateRange[0],
				dateRange[1],
				columnFilters,
			);

			if (!assetResults || !assetResults.results.length) {
				message.error("No assets found to export");
				return;
			}

			const filteredUnknownResults = assetResults.results.map((asset: any) => {
				const newAsset = { ...asset };
				const { model, manufacturer } = newAsset;

				if (model && isUnknownValue(model)) {
					newAsset.model = "N/A";
				}
				if (manufacturer && isUnknownValue(manufacturer)) {
					newAsset.manufacturer = "N/A";
				}
				return newAsset;
			});

			setCsvData(filteredUnknownResults);
			message.success("Export successful");
		} catch (error) {
			console.error("Error exporting assets:", error);
		} finally {
			setLoading(false);
		}
	}, [
		browserLocationId,
		chosenCategory,
		dateRange,
		pageNum,
		pageSize,
		searchVal,
		sortBy,
		sortOrder,
	]);

	const toggleSCExport = () => {
		dispatch({ type: "SET_SHOW_SC_EXPORT", payload: !state.showSCExport });
	};

	// Reset csvData after export to prevent multiple downloads
	const handleExportComplete = () => {
		setCsvData(null);
	};

	return (
		<>
			<DFPModal
				title="Which type would you like to export?"
				open={state.showSCExport}
				onCancel={toggleSCExport}
				confirmLoading={loading}
				footer={[
					<Button key="cancel" onClick={toggleSCExport}>
						Cancel
					</Button>,
					<Button
						key="export"
						type="primary"
						disabled={!chosenCategory}
						loading={loading}
						onClick={handleExport}
					>
						Export
					</Button>,
				]}
			>
				<Select
					style={{ width: "100%" }}
					placeholder="Select a category"
					onChange={(value) => {
						setChosenCategory(value);
					}}
					value={chosenCategory}
				>
					{categories.map((category) => (
						<Select.Option key={category.value} value={category.value}>
							{category.label}
						</Select.Option>
					))}
				</Select>
			</DFPModal>

			{/* Conditionally render CSVExporter when csvData is available */}
			{csvData && csvData.length > 0 && (
				<CSVExporter
					data={csvData}
					filename="exported-data.csv"
					autoDownload
					fieldTransforms={csvFieldTransformFnMap}
					onComplete={handleExportComplete}
				/>
			)}
		</>
	);
};
