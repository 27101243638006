import { ALFRED_SERVICE_URL } from "@/constants/env";
import type {
	LocationTableColumnFilters,
	LocationTableSearchResponse,
	LocationTableSearchResult,
} from "@/models/tables/location-table/location-table.model";
import { LocationTableSearchParams } from "@/models/tables/location-table/location-table.model";
import { auth, db } from "@/services/auth-service";
import { DB_PATH } from "@constants/db";
import {
	collection,
	doc,
	getDoc,
	getDocs,
	updateDoc,
} from "firebase/firestore";
import type { SetStateAction } from "react";

/**
 * Converts json to b64, used for sending small amounts of json
 * @param json
 * @returns {string || null}
 */
export const jsonToBase64 = (json: any) => {
	return btoa(encodeURIComponent(JSON.stringify(json)));
};

export const getLocationsCount = async () => {
	try {
		const url = `${ALFRED_SERVICE_URL}/location/self/count`;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
			},
		});

		if (response.status !== 200) {
			return response.json().then((errorDetails) => {
				throw new Error(
					`Failed to fetch locations total count: ${errorDetails.detail}`,
				);
			});
		}
		return await response.json();
	} catch (exception: any) {
		throw new Error(exception);
	}
};

export const getOrganizationLocations = async () => {
	try {
		const url = `${ALFRED_SERVICE_URL}/location/self/all`;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
			},
		});

		if (response.status !== 200) {
			return response.json().then((errorDetails) => {
				throw new Error(
					`Failed to fetch all of the organization's locations: ${errorDetails.detail}`,
				);
			});
		}
		return await response.json();
	} catch (exception: any) {
		throw new Error(exception);
	}
};

export const getLocationOld = async (organization: string, id: string) => {
	const locSnap = await getDoc(
		doc(db, DB_PATH.ORGANIZATIONS, organization, DB_PATH.LOCATIONS, id),
	);
	return locSnap;
};

/**
 * Fetches a given location, can accept a firebase id or sql id
 */
export const getLocation = async (
	id: string | number,
	is_firebase_id = false,
): Promise<any> => {
	try {
		const url = `${ALFRED_SERVICE_URL}/location${
			is_firebase_id ? "/firebase" : ""
		}/${id}`;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
			},
		});

		if (response.status !== 200) {
			return response.json().then((errorDetails) => {
				throw new Error(`Failed to fetch location: ${errorDetails.detail}`);
			});
		}
		return await response.json();
	} catch (exception: any) {
		throw new Error(exception);
	}
};

/**
 * Updates a location, can accept a sql id or firebase id
 */
export const updateLocation = async (
	id: string | number,
	is_firebase_id: boolean,
	payload: Record<string, unknown>,
): Promise<any> => {
	try {
		const url = `${ALFRED_SERVICE_URL}/location${
			is_firebase_id ? "/firebase" : ""
		}/${id}/update`;

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
			},
			body: JSON.stringify(payload),
		});

		if (response.status !== 200) {
			return response.json().then((errorDetails) => {
				throw new Error(`Failed to fetch location: ${errorDetails.detail}`);
			});
		}
		return await response.json();
	} catch (exception: any) {
		throw new Error(exception);
	}
};

// Gets location from database for Cushman's form
// If no location is found, return 'false'
export const getLocationForCushmanForm = async (
	organization: string,
	id: string,
) => {
	const locSnap = await getDoc(
		doc(db, DB_PATH.ORGANIZATIONS, organization, DB_PATH.LOCATIONS, id),
	);
	return locSnap;
};

export const getLocationTickets = async (locId: any) => {
	try {
		const url = `${ALFRED_SERVICE_URL}/cushman/tickets/${locId}`;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
			},
		});

		if (response.status !== 200) {
			return response.json().then((errorDetails) => {
				throw new Error(
					`Failed to get latest submission: ${errorDetails.detail}`,
				);
			});
		}
		return await response.json();
	} catch (exception: any) {
		throw new Error(exception);
	}
};

export const updateLocationOld = async (
	orgId: string,
	locId: string,
	data: {},
) => {
	const locRef = doc(
		db,
		DB_PATH.ORGANIZATIONS,
		orgId,
		DB_PATH.LOCATIONS,
		locId,
	);
	await updateDoc(locRef, data);
};

/**
 * Adds a location, will add location to the user's current organization
 */
export const addLocation = async (body: any): Promise<any> => {
	try {
		const url = `${ALFRED_SERVICE_URL}/location/add`;

		const response = await fetch(url, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
			},
			body: JSON.stringify(body),
		});

		if (response.status !== 200) {
			return response.json().then((errorDetails) => {
				throw new Error(`Failed to fetch location: ${errorDetails.detail}`);
			});
		}
		return await response.json();
	} catch (exception: any) {
		console.error(exception);
		return exception;
	}
};

export const getLocationMaintenanceReport = async ({
	organization,
	locationId,
}: any) => {
	const colRef = collection(
		db,
		DB_PATH.ORGANIZATIONS,
		organization,
		DB_PATH.LOCATIONS,
		locationId,
		DB_PATH.MAINTENANCE,
	);
	const snap = await getDocs(colRef);

	return snap.docs.map((doc) => doc.data());
};

// Queries database for org locations and passes to state arg
export const getLocationsArr = async (
	_organization: any,
	setState: {
		(value: SetStateAction<never[]>): void;
		(value: SetStateAction<never[]>): void;
		(arg0: any): void;
	},
) => {
	const locs = await getOrganizationLocations();
	const newLocs = locs.map((loc: { id: any }) => ({
		id: loc.id,
		data: loc,
	}));
	setState(newLocs);
};

/**
 * Uses the SQL database to search for locations
 */
export const searchLocations = async (
	searchValue: string | undefined,
	offset: number,
	pageSize: number,
	sort: string,
	sort_direction: string,
	columnFilters: LocationTableColumnFilters,
): Promise<LocationTableSearchResult> => {
	try {
		const searchParams: LocationTableSearchParams = {
			limit: pageSize,
			sort,
			sort_direction,
			offset,
		};

		// Only add search_value if it's a non-empty string
		if (searchValue?.trim()) {
			searchParams.search_value = searchValue.trim();
		}

		const params = new URLSearchParams(
			Object.entries(searchParams).map(([key, value]) => [
				key,
				value.toString(),
			]),
		);

		if (Object.keys(columnFilters).length > 0) {
			params.append("column_filter", jsonToBase64(columnFilters));
		}

		const url = `${ALFRED_SERVICE_URL}/location/search?${params.toString()}`;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
			},
		});

		if (response.status !== 200) {
			const errorDetails = await response.json();
			throw new Error(`Failed to search for locations: ${errorDetails.detail}`);
		}

		const responseJson = (await response.json()) as LocationTableSearchResponse;
		return {
			results: responseJson.hits,
			count: responseJson.total_hits,
		};
	} catch (exception: any) {
		throw new Error(exception.message || "Failed to search locations");
	}
};

export const getOrganizationLocationsPredSpend = async () => {
	try {
		const url = `${ALFRED_SERVICE_URL}/location/self/pred_spend_subset`;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
			},
		});

		if (response.status !== 200) {
			return response.json().then((errorDetails) => {
				throw new Error(
					`Failed to fetch locations for pred spend subset: ${errorDetails.detail}`,
				);
			});
		}
		return await response.json();
	} catch (exception: any) {
		throw new Error(exception);
	}
};

export const getLocationGeoLocation = async (address: {
	google_address: any;
}) => {
	try {
		const apiKey = import.meta.env.VITE_GEOLOC_API_KEY;
		const response = await fetch(
			`https://maps.googleapis.com/maps/api/geocode/json?address=${address.google_address}&key=${apiKey}`,
		);
		return await response.json();
	} catch (exception: any) {
		throw new Error(exception);
	}
};

export const deleteLocations = async (
	organization: string,
	locationId: string,
) => {
	const locRef = doc(
		db,
		DB_PATH.ORGANIZATIONS,
		organization,
		DB_PATH.LOCATIONS,
		locationId,
	);
	await updateDoc(locRef, { deleted: true });
	console.log("Inside location-service", locRef);
};

/**
 * If A location exists, get the locations assets (User must also be a part of the organization)
 */
export const getAssets = async (locationId: number): Promise<any> => {
	try {
		const url = `${ALFRED_SERVICE_URL}/location/assets/${locationId}`;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
			},
		});

		if (response.status !== 200) {
			return response.json().then((errorDetails) => {
				throw new Error(
					`Failed to fetch location assets: ${errorDetails.detail}`,
				);
			});
		}
		return await response.json();
	} catch (exception: any) {
		throw new Error(exception);
	}
};
