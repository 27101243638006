import { DB_ORG } from "@/constants/db";
import { useUserStore } from "@/stores/user-store";
import { normalizeStringForComparison } from "@/utils/transforms";
import { Select } from "antd";
import { type FC } from "react";

interface QASubmissionDraftSelectProps {
	isLoading: boolean;
	selectedFormMode: string;
	onModeChange: (mode: string, isQAForm: boolean, isDraft: boolean) => void;
}

const QASubmissionDraftSelect: FC<QASubmissionDraftSelectProps> = ({
	isLoading,
	selectedFormMode,
	onModeChange,
}) => {
	const user = useUserStore((state) => state.user);

	const handleChange = (value: string) => {
		onModeChange(value, value === "1", value === "2");
	};

	let options = [
		{ value: "0", label: "Submissions" },
		{ value: "1", label: "QA Submissions" },
		{ value: "2", label: "Drafts" },
	];

	// remove qa submissions option if not cushman wakefield
	if (
		normalizeStringForComparison(
			user?.organization?.external_firebase_id! || "",
		) === normalizeStringForComparison(DB_ORG?.CUSHMANWAKEFIELD)
	) {
		options = options.filter((option) => option.label !== "QA Submissions");
	}

	return (
		<Select
			style={{ width: 200 }}
			onChange={handleChange}
			value={selectedFormMode || options[0]?.value}
			options={options}
			placeholder={isLoading ? "Loading Forms..." : "Select a form"}
			disabled={isLoading}
		/>
	);
};

export { QASubmissionDraftSelect };
