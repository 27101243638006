import { DFPEmpty } from "@/components/empty/dfp-empty";
import { DFPTable } from "@/components/tables/dfp-table";
import {
	type BaseTableAction,
	type BaseTableState,
	baseInitialState,
	baseTableReducer,
} from "@/components/tables/shared-table-state";
import { DB_ORG } from "@/constants/db";
import { selfOrganizationAssetTypes } from "@/services/organization-service";
import { useUserStore } from "@/stores";
import { DownloadOutlined } from "@ant-design/icons";
import { Button, Divider, Input, Space, Tooltip } from "antd";
import { FC, useCallback, useEffect, useReducer } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ColumnConfigDropdown } from "../../SubmissionsTable/ColumnConfigDropdown";
import { DeleteButton } from "../../SubmissionsTable/DeleteButton";
import { DeleteModal } from "../../SubmissionsTable/DeleteModal";
import { getTableProps } from "../../SubmissionsTable/childFunctions/getTableProps";
import { fetchData } from "../childFunctions/fetchData";
import {
	getColumnsShown,
	initializeColumns,
} from "../childFunctions/initializeColumns";
import { initialColumns } from "./AssetsTableColumns";
import { SCExportDialog } from "./scExportDialog";

const { Search } = Input;

export const ASCENDING = "asc";
export const DESCENDING = "desc";

export interface Asset {
	id: string | number;
	warranty: any;
	asset_tag: string;
	asset_type: string;
	address: string;
	manufacturer: any;
	model: string;
	date_of_birth: string;
	asset_type_category: string;
	healthScore: number;
	temp_alert_id: string;
	sub_org: string;
	submission_id: string;
}

interface AssetsTableProps {
	browserLocationId: string;
}
interface FilterOptions {
	assetTypes: string[];
}

export type Action =
	| {
			type: "SHOW_DECOMMISSIONED";
			payload: boolean | "ALL";
	  }
	| {
			type: "SET_DATE_RANGE";
			payload: [Date | null, Date | null];
	  }
	| {
			type: "SET_SHOW_DELETE_MODAL";
			payload: boolean;
	  }
	| {
			type: "SET_SHOW_SC_EXPORT";
			payload: boolean;
	  }
	| {
			type: "SET_FILTERS";
			payload: unknown;
	  }
	| {
			type: "SET_FILTER_OPTIONS";
			payload: unknown;
	  }
	| BaseTableAction;

// Initial state
export interface State extends BaseTableState {
	dateRange: [Date | null, Date | null];
	showDecommissioned: boolean | "ALL";
	showDeleteModal: boolean;
	showSCExport: boolean;
	filters: unknown;
	filterOptions: FilterOptions | null;
}

const initialState: State = {
	...baseInitialState,
	sortBy: "other.asset_tag",
	dateRange: [null, null],
	showDecommissioned: "ALL",
	showDeleteModal: false,
	showSCExport: false,
	filters: {},
	filterOptions: null,
	tableColumns: initialColumns,
	columnsShown: getColumnsShown(initialColumns),
};

function reducer(state: State, action: Action): State {
	switch (action.type) {
		case "SET_DATE_RANGE":
			return { ...state, dateRange: action.payload };
		case "SHOW_DECOMMISSIONED":
			return { ...state, showDecommissioned: action.payload };
		case "SET_SHOW_DELETE_MODAL":
			return { ...state, showDeleteModal: action.payload };
		case "SET_SHOW_SC_EXPORT":
			return { ...state, showSCExport: action.payload };
		case "SET_FILTERS":
			return { ...state, filters: action.payload };
		case "SET_FILTER_OPTIONS":
			return { ...state, filterOptions: action.payload };
		default:
			return baseTableReducer(state, action);
	}
}

const AssetsTable: FC<AssetsTableProps> = (props: AssetsTableProps) => {
	const { browserLocationId } = props;
	const location = useLocation();
	const navigate = useNavigate();
	const user = useUserStore((state) => state.user);

	const preservedState = location?.state?.assetsTableParams
		? location.state.assetsTableParams
		: {};

	const organization = user?.organization?.external_firebase_id;
	const [state, dispatch] = useReducer(reducer, {
		...initialState,
		pageNum: preservedState.pageNum || initialState.pageNum,
		pageSize: preservedState.pageSize || initialState.pageSize,
		searchVal: preservedState.searchVal || initialState.searchVal,
		sortBy: preservedState.sortBy || initialState.sortBy,
		sortOrder:
			(preservedState.sortOrder as typeof ASCENDING | typeof DESCENDING) ||
			initialState.sortOrder,
		dateRange: preservedState.dateRange || initialState.dateRange,
		filters: preservedState.filters || initialState.filters,
		filterOptions: preservedState.filterOptions || initialState.filterOptions,
	});

	const {
		sortBy,
		sortOrder,
		pageNum,
		pageSize,
		searchVal,
		isLoading,
		columnsShown,
		dateRange,
		filters,
		filterOptions,
		tableColumns,
	} = state;

	const getFilterOptions = async (dispatch) => {
		const assetTypes = await selfOrganizationAssetTypes(false);
		dispatch({ type: "SET_FILTER_OPTIONS", payload: { assetTypes } });
	};

	// get filter options if no preserved state
	useEffect(() => {
		if (!filterOptions) {
			getFilterOptions(dispatch);
		}
	}, [organization]);

	// initialize columns after options fetched
	useEffect(() => {
		if (filterOptions !== null) {
			initializeColumns(state, dispatch, organization);
		}
	}, [filterOptions]);

	useEffect(() => {
		fetchData(state, dispatch, browserLocationId);
	}, [
		pageNum,
		pageSize,
		sortBy,
		sortOrder,
		searchVal,
		dateRange,
		organization,
		filters,
	]);

	const navigateToAssetProfile = useCallback(
		(id: string, locationId: string) => {
			if (organization === DB_ORG.CUSHMANWAKEFIELD) {
				return;
			}
			const tableState = {
				searchVal,
				sortBy,
				sortOrder,
				pageNum,
				pageSize,
				dateRange,
				filters,
				filterOptions,
			};
			if (locationId) {
				return navigate(`/locations/${locationId}/submissions/${id}`, {
					state: {
						...location.state,
						assetsTableParams: { ...tableState },
					},
				});
			}
			navigate(`/admin/submissions/${id}`, {
				state: {
					...location.state,
					assetsTableParams: { ...tableState },
				},
			});
		},
		[
			pageNum,
			pageSize,
			navigate,
			organization,
			searchVal,
			sortBy,
			sortOrder,
			columnsShown,
			dateRange,
			filterOptions,
		],
	);

	const handleRowSelection = {
		selectedRowKeys: state.checkedItems,
		onChange: (selectedRowKeys: React.Key[], selectedRows: unknown[]) => {
			const submissionIdArr = selectedRows.map((row) => row["submission_id"]);
			dispatch({ type: "SET_CHECKED_ITEMS", payload: submissionIdArr });
		},
	};

	const tableProps = getTableProps(state, dispatch, {
		handleRowClick: (record: Asset) => {
			navigateToAssetProfile(
				record.submission_id.toString(),
				browserLocationId,
			);
		},
		rowSelection: handleRowSelection,
		rowKey: "submission_id",
	});

	const toggleDeleteModal = () => {
		dispatch({
			type: "SET_SHOW_DELETE_MODAL",
			payload: !state.showDeleteModal,
		});
	};

	const toggleSCExport = () => {
		dispatch({ type: "SET_SHOW_SC_EXPORT", payload: !state.showSCExport });
	};

	return (
		<div className="table-card">
			<div className="table-header d-flex justify-content-between">
				<Space>
					<Search
						placeholder="Search"
						allowClear
						style={{ width: 300 }}
						defaultValue={state?.searchVal}
						onSearch={(value: string) => {
							dispatch({ type: "SET_SEARCH_VAL", payload: value });
						}}
						disabled={isLoading}
					/>
					{/* Fix later, does not work */}
					{/* <DateFilter state={state} dispatch={dispatch} /> */}
					{/* <DecommissionedSelect state={state} dispatch={dispatch} /> */}
				</Space>

				<Space>
					<ColumnConfigDropdown state={state} dispatch={dispatch} />
					<DeleteButton state={state} onClick={toggleDeleteModal} />
					<Tooltip title="Export">
						<Button
							icon={<DownloadOutlined style={{ fontSize: "20px" }} />}
							type="text"
							onClick={toggleSCExport}
						/>
					</Tooltip>
				</Space>
			</div>
			<Divider />
			<DFPTable
				locale={{
					emptyText: state.isLoading ? null : (
						<DFPEmpty
							className="w-100 h-100"
							description="No assets returned. Please modify your search term or update your filters and try again."
						/>
					),
				}}
				{...tableProps}
			/>
			<DeleteModal
				state={state}
				dispatch={dispatch}
				browserLocationId={browserLocationId}
				tableType="assets"
				fetchData={fetchData}
			/>
			<SCExportDialog
				state={state}
				dispatch={dispatch}
				browserLocationId={browserLocationId}
			/>
		</div>
	);
};

export { AssetsTable };
