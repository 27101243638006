import { storage } from "@/services/auth-service";
import { useUserStore } from "@/stores";
import { UserOutlined } from "@ant-design/icons";
import { getDownloadURL, ref } from "@firebase/storage";
import { Spin } from "antd";
import type { FC } from "react";
import { useCallback, useEffect, useReducer, useRef } from "react";

interface AvatarProps {
	alt?: string;
	className?: string;
	size?: number;
}

interface AvatarState {
	imageUrl: string | null;
	isLoading: boolean;
	error: string | null;
}

type AvatarAction =
	| { type: "FETCH_START" }
	| { type: "FETCH_SUCCESS"; payload: string }
	| { type: "FETCH_ERROR"; payload: string }
	| { type: "RESET" };

const initialState: AvatarState = {
	imageUrl: null,
	isLoading: false,
	error: null,
};

const DEFAULT_AVATAR = "ProfilePics/default-profile-pic.png";

const avatarReducer = (
	state: AvatarState,
	action: AvatarAction,
): AvatarState => {
	switch (action.type) {
		case "FETCH_START":
			return {
				...state,
				isLoading: true,
				error: null,
			};
		case "FETCH_SUCCESS":
			return {
				...state,
				imageUrl: action.payload,
				isLoading: false,
				error: null,
			};
		case "FETCH_ERROR":
			return {
				...state,
				isLoading: false,
				error: action.payload,
				imageUrl: state.imageUrl,
			};
		case "RESET":
			return initialState;
		default:
			return state;
	}
};

const Avatar: FC<AvatarProps> = ({ alt, className, size = "100%" }) => {
	const user = useUserStore((state) => state.user);
	const [state, dispatch] = useReducer(avatarReducer, initialState);
	const currentPathRef = useRef<string | null>(null);

	const fetchAvatar = useCallback(async (profilePicUrl: string | null) => {
		const imgPath = profilePicUrl || DEFAULT_AVATAR;

		if (currentPathRef.current === imgPath) {
			return;
		}

		currentPathRef.current = imgPath;
		dispatch({ type: "FETCH_START" });

		try {
			const imgRef = ref(storage, imgPath);
			const url = await getDownloadURL(imgRef);
			dispatch({ type: "FETCH_SUCCESS", payload: url });
		} catch (error) {
			console.error("Error loading avatar:", error);

			if (imgPath !== DEFAULT_AVATAR) {
				currentPathRef.current = null;
				fetchAvatar(DEFAULT_AVATAR);
			} else {
				dispatch({ type: "FETCH_ERROR", payload: "Failed to load avatar" });
			}
		}
	}, []);

	useEffect(() => {
		if (user?.user_config) {
			fetchAvatar(user.user_config.profile_picture_url);
		}

		return () => {
			currentPathRef.current = null;
			dispatch({ type: "RESET" });
		};
	}, [user?.user_config?.profile_picture_url, fetchAvatar]);

	if (state.isLoading && !state.imageUrl) {
		return (
			<div
				className="flex items-center justify-center"
				style={{ width: size, height: size }}
			>
				<Spin size="small" />
			</div>
		);
	}

	if (!state.imageUrl) {
		return (
			<div
				className="flex items-center justify-center rounded-full bg-gray-100"
				style={{ width: size, height: size }}
			>
				<UserOutlined style={{ fontSize: size * 0.6 }} />
			</div>
		);
	}

	return (
		<div
			className={`relative overflow-hidden rounded-full ${className || ""}`}
			style={{ width: size, height: size }}
		>
			<img
				src={state.imageUrl}
				alt={alt || `${user?.name || "User"}'s avatar`}
				className="absolute inset-0 w-full h-full object-cover"
			/>
		</div>
	);
};

export { Avatar };
