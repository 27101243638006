interface IGuideTabProps {
	iguide: string;
}

const IGuideTab = ({ iguide }: IGuideTabProps) => {
	return (
		<div className="iguide-wrapper">
			<iframe
				src={`//youriguide.com/embed/${iguide}`}
				scrolling="no"
				frameBorder="0"
				width={"100%"}
				height={"100%"}
				title="IGUIDE"
			></iframe>
		</div>
	);
};

export { IGuideTab };
