import { useUserStore } from "@/stores";
import { Card, Col, Input, Row } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Forms.scss";
import { DFPEmpty } from "@/components/empty/dfp-empty";
import { Loader } from "@/layouts/signed-in/Loader/Loader";
import { IForm } from "@/stores/user-store";

const Forms = () => {
	const navigate = useNavigate();
	const { user } = useUserStore();
	const [searchVal, setSearchVal] = useState<string>("");

	if (!user?.organization) {
		return <Loader />;
	}

	// Get forms from user organization
	const forms = user.organization.forms.filter((form) => form.enabled) || [];

	// Filter forms based on search
	const filteredForms = forms.filter((form) =>
		form.config.formName?.toLowerCase().includes(searchVal.toLowerCase()),
	);

	const handleFormClick = (form: IForm) => {
		navigate(`/forms/${form.form_id}`, {
			state: { data: { config: form.config } },
		});
	};

	return (
		<div className="forms-container">
			<Card className="forms-card">
				<Input.Search
					placeholder="Search forms"
					value={searchVal}
					onChange={(e) => setSearchVal(e.target.value)}
					style={{ marginBottom: 24 }}
				/>

				{filteredForms.length > 0 ? (
					<Row gutter={[16, 16]}>
						{filteredForms.map((form: IForm) => (
							<Col xs={24} sm={12} md={8} lg={6} key={form.form_id}>
								<Card
									hoverable
									onClick={() => handleFormClick(form)}
									cover={
										user.organization.config.logo_url && (
											<div className="form-logo-container">
												<img
													alt="form logo"
													src={user.organization.config.logo_url}
													className="form-logo"
													loading="lazy"
												/>
											</div>
										)
									}
								>
									<Card.Meta
										title={form.config.formName}
										description={form.config.description}
									/>
								</Card>
							</Col>
						))}
					</Row>
				) : (
					<DFPEmpty
						description={searchVal ? "No forms found" : "No forms available"}
					/>
				)}
			</Card>
		</div>
	);
};

export { Forms };
