import { storage } from "@/services/auth-service";
import { selfUpdateUserComprehensive } from "@/services/user-service";
import { useUserStore } from "@/stores";
import type { IUser } from "@/stores/user-store";
import { LoadingOutlined, UserOutlined } from "@ant-design/icons";
import { getDownloadURL, ref, uploadBytes } from "@firebase/storage";
import { Avatar, Upload, message } from "antd";
import type { UploadProps } from "antd";
import type { RcFile } from "antd/es/upload/interface";
import type { FC } from "react";
import { useCallback, useEffect, useState } from "react";

interface AvatarUploadProps {
	className?: string;
	size?: number;
}

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB
const ALLOWED_FILE_TYPES = ["image/png", "image/jpg", "image/jpeg"];
const DEFAULT_AVATAR = "ProfilePics/default-profile-pic.png";

const AvatarUpload: FC<AvatarUploadProps> = ({
	className,
	size = 96, // default to 96px
}) => {
	const { user, setUser } = useUserStore();
	const [imageUrl, setImageUrl] = useState<string>();
	const [loading, setLoading] = useState(false);

	const fetchProfileImage = useCallback(async () => {
		try {
			const imgPath = user?.user_config?.profile_picture_url || DEFAULT_AVATAR;
			const imgRef = ref(storage, imgPath);
			const url = await getDownloadURL(imgRef);
			setImageUrl(url);
		} catch (error) {
			message.error("Failed to load profile picture");
			console.error("Error loading profile picture:", error);
		}
	}, [user?.user_config?.profile_picture_url]);

	useEffect(() => {
		fetchProfileImage();
	}, [fetchProfileImage]);

	const beforeUpload = (file: RcFile) => {
		if (!ALLOWED_FILE_TYPES.includes(file.type)) {
			message.error("You can only upload JPG/PNG files!");
			return false;
		}

		if (file.size > MAX_FILE_SIZE) {
			message.error("Image must be smaller than 10MB!");
			return false;
		}

		return true;
	};

	const handleUpload: UploadProps["customRequest"] = async ({
		file,
		onSuccess,
		onError,
	}) => {
		if (!user) return;

		const rcFile = file as RcFile;
		if (!beforeUpload(rcFile)) {
			return;
		}

		const previousUser = user;
		const path = `ProfilePics/${rcFile.name}`;

		try {
			setLoading(true);

			// Upload image to storage
			const storageRef = ref(storage, path);
			await uploadBytes(storageRef, rcFile);
			const downloadUrl = await getDownloadURL(storageRef);

			// Create updated user object
			const updatedUser: IUser = {
				...user,
				user_config: {
					...user.user_config,
					profile_picture_url: path,
				},
			};

			// Sync with backend and get complete user data
			const response = await selfUpdateUserComprehensive(updatedUser);
			setUser(response);
			setImageUrl(downloadUrl);

			message.success("Profile picture updated successfully");
			onSuccess?.(downloadUrl);
		} catch (error) {
			console.error("Error uploading image:", error);
			// Rollback on failure
			setUser(previousUser);
			setImageUrl(undefined);
			message.error("Failed to upload profile picture");
			onError?.(error as Error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Upload
			name="avatar"
			listType="picture-circle"
			className={`avatar-uploader ${className || ""}`}
			showUploadList={false}
			customRequest={handleUpload}
			accept={ALLOWED_FILE_TYPES.join(",")}
		>
			{imageUrl ? (
				<Avatar
					src={imageUrl}
					alt="avatar"
					size={size}
					className="rounded-full"
				/>
			) : (
				<div className="flex items-center justify-center w-full h-full">
					{loading ? <LoadingOutlined /> : <UserOutlined />}
				</div>
			)}
		</Upload>
	);
};

export { AvatarUpload };
