import { ALFRED_SERVICE_URL } from "@/constants/env";
import { AuthProviders, auth } from "@/services/auth-service";
import { type IUser } from "@/stores/user-store";
import { message } from "antd";

export const getUserSelf = async () => {
	try {
		console.log("Fetching user!");
		const url = `${ALFRED_SERVICE_URL}/users/self?get_config=true&get_organization=true`;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
			},
		});

		if (response.status !== 200) {
			return response.json().then((errorDetails) => {
				console.error(`Failed to fetch user self: ${errorDetails.detail}`);
				throw new Error("Failed to fetch user");
			});
		}
		return await response.json();
	} catch (exception: unknown) {
		console.error(`Failed to fetch user self: ${exception}`);
		throw new Error("Failed to fetch user");
	}
};

// Fetches a user based on id
export const getUserOther = async (userId: string | number) => {
	try {
		const url = `${ALFRED_SERVICE_URL}/users/${userId}`;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
			},
		});

		if (response.status !== 200) {
			return response.json().then((errorDetails) => {
				console.error(`Failed to fetch other user: ${errorDetails.detail}`);
				throw new Error("Failed to fetch other user");
			});
		}
		const results = await response.json();
		return results;
	} catch (exception: unknown) {
		console.error(`Failed to fetch other user: ${exception}`);
		throw new Error("Failed to fetch other user");
	}
};

// Fetches a user based on firebase id
export const getUserOtherByFirebaseId = async (firebaseId: string) => {
	try {
		const url = `${ALFRED_SERVICE_URL}/users/firebase/${firebaseId}`;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
			},
		});

		//User Not Found Error Code
		if (response.status === 400) {
			console.error(`User Search, not found: ${firebaseId}`);
			throw new Error("User not found");
		}

		if (response.status !== 200) {
			return response.json().then((errorDetails) => {
				console.error(`Failed to fetch other user: ${errorDetails.detail}`);
				throw new Error("Failed to fetch other user");
			});
		}
		const results = await response.json();
		return results;
	} catch (exception: unknown) {
		console.error(`Failed to fetch other user: ${exception}`);
		throw new Error("Failed to fetch other user");
	}
};

// Fetches all users from an organization by using the backend. Will fetch all users with the same org as the requester.
export const getOrganizationUsers = async () => {
	try {
		const url = `${ALFRED_SERVICE_URL}/users/getOrganizationUsers`;
		const token = await auth?.currentUser?.getIdToken();

		const response = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		if (response.status !== 200) {
			return response.json().then((errorDetails) => {
				console.error(
					`Failed to fetch organization users: ${errorDetails.detail}`,
				);
				throw new Error("Failed to fetch organization users");
			});
		}

		return await response.json();
	} catch (exception: unknown) {
		console.error(`Failed to fetch organization users: ${exception}`);
		throw new Error("Failed to fetch organization users");
	}
};

/**
 * Fetches all the existing roles (Will only fetch users role and ones below it)
 * @returns {Promise<>}
 */
export const getRoles = async () => {
	try {
		const url = `${ALFRED_SERVICE_URL}/users/getRoles`;
		const token = await auth?.currentUser?.getIdToken();

		const response = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		if (response.status !== 200) {
			return response.json().then((errorDetails) => {
				console.error(`Failed to fetch roles: ${errorDetails.detail}`);
				throw new Error("Failed to fetch roles");
			});
		}

		return await response.json();
	} catch (exception: unknown) {
		console.error(`Failed to fetch roles: ${exception}`);
		throw new Error("Failed to fetch roles");
	}
};

export const getOrganizationSubmittedUsers = async (
	formId: string | number,
) => {
	const url = `${ALFRED_SERVICE_URL}/submission/users/${formId}`;
	const response = await fetch(url, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
		},
	});

	if (response.status !== 200) {
		return response.json().then((errorDetails) => {
			console.error(
				`Failed to get organization submitted users: ${errorDetails.detail}`,
			);
			throw new Error("Failed to get organization submitted users");
		});
	}
	const data = await response.json();
	return data;
};

export const getNotifications = async () => {
	const url = `${ALFRED_SERVICE_URL}/self/notifications`;
	const response = await fetch(url, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
		},
	});

	if (response.status !== 200) {
		return response.json().then((errorDetails) => {
			console.error(`Failed to get user notifications: ${errorDetails.detail}`);
			// throw new Error("Failed to get user notifications");
		});
	}

	return await response.json();
};

export const setNotificationsToRead = async () => {
	const url = `${ALFRED_SERVICE_URL}/self/notifications/read_all`;
	const response = await fetch(url, {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
		},
	});

	if (response.status !== 200) {
		return response.json().then((errorDetails) => {
			console.error(
				`Failed to set all notifications to read: ${errorDetails.detail}`,
			);
			throw new Error("Failed to set all notifications to read");
		});
	}

	return await response.json();
};

export const deleteNotification = async (notificationId: string | number) => {
	const url = `${ALFRED_SERVICE_URL}/self/notifications/${notificationId}/delete`;
	const response = await fetch(url, {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
		},
	});

	if (response.status !== 200) {
		return response.json().then((errorDetails) => {
			console.error(`Failed to delete notification: ${errorDetails.detail}`);
			throw new Error("Failed to delete notification");
		});
	}

	return await response.json();
};

// Used interally in Admin tab to add a user, will use the same organization id as the requester
export const addUserInternal = async (
	name: string,
	email: string,
	password: string,
	role_id: string | number,
) => {
	const url = `${ALFRED_SERVICE_URL}/users/add`;
	const token = await auth?.currentUser?.getIdToken();
	const payload = {
		name: name,
		email: email,
		password: password,
		role_id: role_id,
	};
	const response = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(payload),
	});

	if (response.status !== 200) {
		return response.json().then((errorDetails) => {
			console.error(`Failed to add user: ${errorDetails.detail}`);
			throw new Error("Failed to add user");
		});
	}

	return await response.json();
};

// Updates a user with the given name and role
export const adminUpdateUser = async (
	userId: string | number,
	body: {
		name: string;
		role_id: string | number;
		organization_id: string | number;
	},
) => {
	const url = `${
		import.meta.env.VITE_ALFRED_SERVICE_URL
	}/users/${userId}/update`;
	const token = await auth?.currentUser?.getIdToken();

	const response = await fetch(url, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(body),
	});

	if (response.status !== 200) {
		return response.json().then((errorDetails) => {
			console.error(`Failed to update user: ${errorDetails.detail}`);
			message.error(`${errorDetails.detail}`);
			throw new Error("Failed to update user");
		});
	}

	return await response.json();
};

// Used to update the current (self) user's
export const selfUpdateUser = async (body: {
	saved_filters?: { [x: string]: unknown };
	profile_picture_url?: string;
}) => {
	const url = `${import.meta.env.VITE_ALFRED_SERVICE_URL}/self/update`;

	const response = await fetch(url, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
		},
		body: JSON.stringify(body),
	});

	if (response.status !== 200) {
		return response.json().then((errorDetails) => {
			console.error(`Failed to update user: ${errorDetails.detail}`);
			throw new Error("Failed to update user");
		});
	}

	return await response.json();
};

export const selfUpdateUserComprehensive = async (user: Partial<IUser>) => {
	const url = `${import.meta.env.VITE_ALFRED_SERVICE_URL}/self/comprehensive-update`;

	const response = await fetch(url, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
		},
		body: JSON.stringify(user),
	});

	if (response.status !== 200) {
		return response.json().then((errorDetails) => {
			console.error(`Failed to update user: ${errorDetails.detail}`);
			throw new Error("Failed to update user");
		});
	}

	return await response.json();
};

export const deleteUser = async (userId: undefined) => {
	const url = `${
		import.meta.env.VITE_ALFRED_SERVICE_URL
	}/users/${userId}/delete`;
	const token = await auth?.currentUser?.getIdToken();

	const response = await fetch(url, {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});

	if (response.status !== 200) {
		return response.json().then((errorDetails) => {
			console.error(`Failed to delete user: ${errorDetails.detail}`);
			throw new Error("Failed to delete user");
		});
	}

	return await response.json();
};

// Called for a user signup
export const userSignup = async (
	name: string,
	email: string,
	password: string,
	organization: string,
) => {
	try {
		//First we create the authentication account in firebase
		const authResponse = await AuthProviders.createUserWithEmailAndPassword(
			auth,
			email,
			password,
		);
		const url = `${ALFRED_SERVICE_URL}/users/signup`;
		const payload = {
			name: name,
			email: authResponse.user.email,
			password: password,
			organization: organization,
			external_firebase_id: authResponse.user.uid,
		};
		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(payload),
		});

		// If the user triggers a 403 (forbidden) they might be snooping around, so we give less detail
		if (response.status !== 200) {
			if (response.status === 403) {
				console.error(
					"Failed to signup, please contact an Admin for assistance",
				);
				throw new Error(
					"Failed to signup, please contact an Admin for assistance",
				);
			}
			return response.json().then((errorDetails) => {
				console.error(`Failed to add user: ${errorDetails.detail}`);
				throw new Error("Failed to add user");
			});
		}

		const results = await response.json();
		return results;
	} catch (exception: unknown) {
		console.error(`Failed to signup: ${exception}`);
		throw new Error("Failed to signup");
	}
};
