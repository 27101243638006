import { CopyableTableCell } from "@/components/typography/dfp-typography";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Input, Select, Tooltip } from "antd";
import { type FC, useState } from "react";

interface EditableCellProps {
	value: any;
	record: any;
	dataIndex: string;
	handleSave: (record: any) => void;
	options?: { value: number; label: string }[];
	displayValue?: string;
}

export const EditableCell: FC<EditableCellProps> = ({
	value,
	record,
	dataIndex,
	handleSave,
	options,
	displayValue,
}) => {
	const [editing, setEditing] = useState(false);
	const [cellValue, setCellValue] = useState(value);

	const toggleEdit = () => {
		setEditing(!editing);
	};

	const save = async () => {
		try {
			await handleSave({ ...record, [dataIndex]: cellValue });
			setEditing(false);
		} catch (error) {
			console.error("Failed to save:", error);
			// Optionally, show an error message here
		}
	};

	const cancel = () => {
		setCellValue(value);
		setEditing(false);
	};

	if (editing) {
		if (options) {
			return (
				<div style={{ display: "flex", alignItems: "center", gap: 8 }}>
					<Select
						value={cellValue}
						onChange={(val) => setCellValue(val)}
						style={{ flex: 1 }}
						options={options}
					/>
					<Button type="text" icon={<CheckOutlined />} onClick={save} />
					<Button type="text" icon={<CloseOutlined />} onClick={cancel} />
				</div>
			);
		}
		return (
			<div style={{ display: "flex", alignItems: "center", gap: 8 }}>
				<Input
					value={cellValue}
					onChange={(e) => setCellValue(e.target.value)}
					style={{ flex: 1 }}
				/>
				<Button
					type="text"
					icon={<CheckOutlined />}
					onClick={save}
					onMouseDown={(e) => e.preventDefault()} // Prevent blur
				/>
				<Button
					type="text"
					icon={<CloseOutlined />}
					onClick={cancel}
					onMouseDown={(e) => e.preventDefault()} // Prevent blur
				/>
			</div>
		);
	}

	let displayText = displayValue || value;
	if (options) {
		const option = options.find((opt) => opt.value === value);
		displayText = option ? option.label : "";
	}

	return (
		<Tooltip
			title={"Click to edit"}
			placement="topLeft"
			overlayInnerStyle={{ transform: "translateX(-50px)" }}
		>
			<div
				className="editable-cell-value-wrap"
				style={{ paddingRight: 240 }}
				onMouseDown={toggleEdit}
			>
				<CopyableTableCell text={displayText} />
			</div>
		</Tooltip>
	);
};
