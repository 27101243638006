import { DFPTypography } from "@/components/typography/dfp-typography";
import type { TextProps } from "antd/es/typography/Text";

interface CopyableCellRendererProps extends TextProps {
	value: string;
}
const { Text } = DFPTypography;

export const CopyableCellRenderer = ({
	value,
	className,
	style,
}: CopyableCellRendererProps) => {
	return (
		<Text className={className} style={style} copyable>
			{value}
		</Text>
	);
};
