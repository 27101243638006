import { Loader } from "@/layouts/signed-in/Loader/Loader";
import { getReleases } from "@/services/organization-service";
import { parseDateToUTCString } from "@/utils/transforms";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Alert, Button, Card, Empty, Typography } from "antd";
import { useEffect, useReducer, useState } from "react";
import "./releases.style.scss";
import { useNavigate } from "react-router-dom";
import ReleaseCardTitle from "./ReleaseCardTitle";
import ReleaseSection from "./ReleaseSection";

// Define interfaces for the release data
interface ReleaseData {
	version: string;
	date: string;
	updates: { title: string; details: string[] }[];
	bugs: { title: string; details: string[] }[];
	other: { title: string; details: string[] }[];
}

// Define state and action types
interface ReleasesState {
	loading: boolean;
	releases: ReleaseData[];
	error: string | null;
}

type ReleasesAction =
	| { type: "FETCH_INIT" }
	| { type: "FETCH_SUCCESS"; payload: ReleaseData[] }
	| { type: "FETCH_FAILURE"; payload: string };

// Reducer for managing state
const initialState: ReleasesState = {
	loading: true,
	releases: [],
	error: null,
};

const releaseReducer = (
	state: ReleasesState,
	action: ReleasesAction,
): ReleasesState => {
	switch (action.type) {
		case "FETCH_INIT":
			return { ...state, loading: true, error: null };
		case "FETCH_SUCCESS":
			return {
				...state,
				loading: false,
				releases: action.payload,
				error: null,
			};
		case "FETCH_FAILURE":
			return { ...state, loading: false, error: action.payload };
		default:
			throw new Error(`Unsupported action type: ${action.type}`);
	}
};

// Custom hook for fetching releases
const useReleases = (): ReleasesState => {
	const [state, dispatch] = useReducer(releaseReducer, initialState);

	useEffect(() => {
		const fetchReleases = async () => {
			dispatch({ type: "FETCH_INIT" });
			try {
				const releasesSnap = await getReleases();
				if (releasesSnap.empty) {
					dispatch({ type: "FETCH_SUCCESS", payload: [] });
					return;
				}
				const fetchedReleases: ReleaseData[] = [];
				releasesSnap.forEach((doc) =>
					fetchedReleases.push(doc.data() as ReleaseData),
				);
				dispatch({ type: "FETCH_SUCCESS", payload: fetchedReleases });
			} catch (error: any) {
				dispatch({ type: "FETCH_FAILURE", payload: error.message });
			}
		};

		fetchReleases();
	}, []);

	return state;
};

const Releases: React.FC = () => {
	const { loading, releases, error } = useReleases();
	const [currentIndex, setCurrentIndex] = useState<number>(0);
	const navigate = useNavigate();

	if (loading) return <Loader />;
	if (error)
		return <Alert message="Error" description={error} type="error" showIcon />;
	if (!releases || releases.length === 0)
		return <Empty description="No releases found" />;

	// Get the current release based on the index
	const currentRelease = releases[currentIndex];

	// Navigation functions
	const goToPrevious = (): void => {
		if (currentIndex > 0) {
			setCurrentIndex(currentIndex - 1);
		}
	};

	const goToNext = (): void => {
		if (currentIndex < releases.length - 1) {
			setCurrentIndex(currentIndex + 1);
		}
	};

	return (
		<div className="releases-container">
			<Typography.Title level={3} className="releases-title">
				Release Notes
			</Typography.Title>
			<div className="releases-card-wrapper">
				<Card className="releases-card" bordered={false}>
					<Button
						type="link"
						onClick={() => navigate(-1)}
						icon={<LeftOutlined />}
					>
						Back
					</Button>
					<ReleaseCardTitle
						version={currentRelease.version}
						date={parseDateToUTCString(currentRelease.date) || ""}
					/>
					<ReleaseSection
						title="Updates & Improvements"
						emoji="🆕"
						data={currentRelease.updates}
						badgeColor="green"
					/>
					<ReleaseSection
						title="Bugs & Fixes"
						emoji="🔧"
						data={currentRelease.bugs}
						badgeColor="orange"
					/>
					<ReleaseSection
						title="Other Enhancements"
						emoji="✨"
						data={currentRelease.other}
						badgeColor="blue"
					/>
				</Card>
				<div className="navigation-buttons">
					<Button
						type="primary"
						icon={<LeftOutlined />}
						onClick={goToPrevious}
						disabled={currentIndex === 0}
					>
						Previous
					</Button>
					<Button
						type="primary"
						icon={<RightOutlined />}
						onClick={goToNext}
						disabled={currentIndex === releases.length - 1}
					>
						Next
					</Button>
				</div>
			</div>
		</div>
	);
};

export { Releases };
