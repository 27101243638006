import type { IOrganization, IUser } from "@/stores/user-store";
import type { FC } from "react";
import { useState } from "react";
import {
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from "reactstrap";

export interface OrgDropdownProps {
	options: IOrganization[];
	user: IUser;
	updateUserOrg: (externalFirebaseId: string, fullName: string) => void;
}

export const OrgDropdown: FC<OrgDropdownProps> = ({
	options,
	user,
	updateUserOrg,
}) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const toggle = () => setDropdownOpen((prev) => !prev);

	const sortedOptions = [...options].sort((a, b) =>
		a.full_name.toLowerCase() > b.full_name.toLowerCase() ? 1 : -1,
	);

	return (
		<Dropdown
			disabled={!options.length}
			isOpen={dropdownOpen}
			toggle={toggle}
			direction="up"
		>
			{options.length ? (
				<DropdownToggle>{user?.organization.full_name}</DropdownToggle>
			) : (
				<span className="text-white">{user?.organization.full_name}</span>
			)}

			<DropdownMenu>
				{sortedOptions.map((org) => (
					<DropdownItem
						key={org.id}
						onClick={() => {
							updateUserOrg(org.external_firebase_id, org.full_name);
						}}
					>
						{org.full_name}
					</DropdownItem>
				))}
			</DropdownMenu>
		</Dropdown>
	);
};
