import { DeleteOutlined, RestOutlined } from "@ant-design/icons";
import { Switch, Tooltip } from "antd";
import { type FC } from "react";

interface DeletedSwitchProps {
	isLoading: boolean;
	viewingDeleted: boolean;
	onViewDeletedChange: (checked: boolean) => void;
}

const DeletedSwitch: FC<DeletedSwitchProps> = ({
	isLoading,
	viewingDeleted,
	onViewDeletedChange,
}) => {
	return (
		<Tooltip title={viewingDeleted ? "Hide deleted" : "Show deleted"}>
			<Switch
				onChange={onViewDeletedChange}
				disabled={isLoading}
				checked={viewingDeleted}
				checkedChildren={<RestOutlined />}
				unCheckedChildren={<DeleteOutlined />}
			/>
		</Tooltip>
	);
};

export { DeletedSwitch };
