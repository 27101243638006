import { MutatingDots } from "react-loader-spinner";

interface LoaderProps {
	small?: boolean;
	message?: string;
}

const Loader = ({ small = false, message }: LoaderProps) => {
	return (
		<div
			className={`d-flex justify-content-center align-items-center h-100 w-100 ${small ? "h-100" : "py-5 vh-100"}`}
		>
			<div className="d-flex flex-column align-items-center">
				<MutatingDots
					width={small ? 40 : 120}
					height={small ? 40 : 120}
					color="#BF1E2D"
					secondaryColor="#BF1E2D"
				/>
				{message && <span className="fw-bold mt-2">{message}</span>}
			</div>
		</div>
	);
};

export { Loader };
