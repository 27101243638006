import { DownloadOutlined } from "@ant-design/icons";
import { Button, Divider, Input, Space } from "antd";
import {
	FC,
	ReactNode,
	useCallback,
	useEffect,
	useMemo,
	useReducer,
	useRef,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { DFPEmpty } from "@/components/empty/dfp-empty";
import { DFPTable } from "@/components/tables/dfp-table";
import {
	type BaseTableAction,
	type BaseTableState,
	baseInitialState,
	baseTableReducer,
} from "@/components/tables/shared-table-state";

import { selfOrganizationAssetTypes } from "@/services/organization-service";
import {
	type IForm,
	type IOrganization,
	useUserStore,
} from "@/stores/user-store";
import { SubmissionData } from "../../SingleLocation/SubmissionsTab/children/DynamicSubmissionDetailAssetRenderer";
import { ColumnConfigDropdown } from "./ColumnConfigDropdown";
import { getOrgColumns } from "./Columns/SubmissionsColumns";
import { DeleteButton } from "./DeleteButton";
import { DeleteModal } from "./DeleteModal";
import { DeletedSwitch } from "./DeletedSwitch";
import { FormSelect } from "./FormSelect";
import { QASubmissionDraftSelect } from "./QASubmissionDraftSelect";
import { fetchSubmissionTableData } from "./childFunctions/fetchData";
import { getTableProps } from "./childFunctions/getTableProps";
import { initializeColumnsShown } from "./childFunctions/initializeColumnsShown";
import { ExportModal } from "./children/ExportModal";

const { Search } = Input;

/* ---------------------------------------------------------------------
   Types
   --------------------------------------------------------------------- */
export interface Submission {
	id: string | number;
	formIcon: ReactNode;
	asset_tag: string;
	asset_type: string;
	address: string;
	submitting_user: string;
	submitted_date: string;
	isLoading?: boolean;
}

export interface Draft {
	id: string | number;
	address: string;
	submitting_user: string;
	submitted_date: string;
	isLoading?: boolean;
}

interface FilterOptions {
	assetTypes: string[];
}

interface MasterFormKey {
	description: string | null;
	id: number;
	handler_function_key: string | null;
	name: string;
}

interface FormConfig {
	formName: string;
}

interface Form {
	form_id: number;
	config: FormConfig;
	required_access_level: number;
	organization_id: number;
	collections: unknown | null;
	enabled: boolean;
	master_form_key: MasterFormKey;
}

interface SubmissionsTableProps {
	organization: IOrganization;
	browserLocationId: string;
}

/* ---------------------------------------------------------------------
   Reducer Types
   --------------------------------------------------------------------- */
export type SubmissionTableAction =
	| BaseTableAction
	| { type: "SET_VIEWING_DRAFTS"; payload: boolean }
	| { type: "SET_VIEWING_DELETED"; payload: boolean }
	| { type: "SET_FORMS_ARR"; payload: Form[] }
	| { type: "SET_SELECTED_FORM"; payload: number }
	| { type: "SET_SELECTED_FORM_MODE"; payload: string }
	| { type: "SET_DATA_FLEET_FORMS"; payload: unknown }
	| { type: "SET_FILTERS"; payload: Record<string, unknown> }
	| { type: "SET_SHOW_EXPORT_MODAL"; value: boolean }
	| { type: "SET_SHOW_DELETE_MODAL"; value: boolean }
	| { type: "SET_FILTER_OPTIONS"; payload: FilterOptions }
	| { type: "BATCH_UPDATE"; payload: SubmissionTableState }
	| { type: "SET_IS_VIEWING_QA_FORM"; payload: boolean }
	| { type: "SET_IS_LOADING"; payload: boolean }
	| { type: "SET_REFETCH_TRIGGER"; payload: number }
	| { type: "SET_COLUMNS_SHOWN"; payload: Record<string, boolean> }
	| { type: "SET_PAGE_NUM"; payload: number };

export interface SubmissionTableState extends BaseTableState {
	viewingDrafts: boolean;
	viewingDeleted: boolean;
	formsArr: IForm[];
	selectedForm: number | undefined;
	selectedFormMode: string;
	filters: Record<string, unknown>;
	filterOptions: FilterOptions | null;
	showExportModal: boolean;
	showDeleteModal: boolean;
	isViewingQAForm: boolean;
	refetchTrigger: number;
	isLoading: boolean;
	columnsShown: Record<string, boolean>;
}

/* ---------------------------------------------------------------------
   Initial State + Reducer
   --------------------------------------------------------------------- */
const initialState: SubmissionTableState = {
	...baseInitialState,
	tableColumns: [],
	sortBy: "end_time",
	viewingDrafts: false,
	viewingDeleted: false,
	formsArr: [],
	selectedForm: undefined,
	isViewingQAForm: false,
	selectedFormMode: "0",
	filters: {},
	filterOptions: null,
	showExportModal: false,
	showDeleteModal: false,
	refetchTrigger: 0,
	isLoading: true,
	columnsShown: {},
};

/**
 * Merges only those fields from `newValues` which are actually present (defined).
 * Other fields in `oldState` remain untouched.
 */
function partialMerge<T extends object>(oldState: T, newValues: Partial<T>): T {
	const updated: T = { ...oldState };
	for (const [key, val] of Object.entries(newValues) as [
		keyof T,
		T[keyof T],
	][]) {
		if (val !== undefined) {
			updated[key] = val;
		}
	}
	return updated;
}

function reducer(
	state: SubmissionTableState,
	action: SubmissionTableAction,
): SubmissionTableState {
	switch (action.type) {
		case "SET_VIEWING_DRAFTS":
			if (state.viewingDrafts !== action.payload) {
				return { ...state, viewingDrafts: action.payload };
			}
			return state;
		case "SET_VIEWING_DELETED":
			if (state.viewingDeleted !== action.payload) {
				return { ...state, viewingDeleted: action.payload };
			}
			return state;
		case "SET_IS_VIEWING_QA_FORM":
			if (state.isViewingQAForm !== action.payload) {
				return { ...state, isViewingQAForm: action.payload };
			}
			return state;
		case "SET_FORMS_ARR":
			if (JSON.stringify(state.formsArr) !== JSON.stringify(action.payload)) {
				return { ...state, formsArr: action.payload };
			}
			return state;
		case "SET_SELECTED_FORM":
			if (state.selectedForm !== action.payload) {
				return { ...state, selectedForm: action.payload };
			}
			return state;
		case "SET_SELECTED_FORM_MODE":
			if (state.selectedFormMode !== action.payload) {
				return { ...state, selectedFormMode: action.payload };
			}
			return state;
		case "SET_IS_LOADING":
			if (state.isLoading !== action.payload) {
				return { ...state, isLoading: action.payload };
			}
			return state;
		case "SET_FILTERS":
			if (JSON.stringify(state.filters) !== JSON.stringify(action.payload)) {
				return { ...state, filters: action.payload };
			}
			return state;
		case "SET_FILTER_OPTIONS":
			return { ...state, filterOptions: action.payload };
		case "SET_SHOW_EXPORT_MODAL":
			return { ...state, showExportModal: action.value };
		case "SET_SHOW_DELETE_MODAL":
			return { ...state, showDeleteModal: action.value };
		case "BATCH_UPDATE":
			return partialMerge(state, action.payload);
		case "SET_REFETCH_TRIGGER":
			return { ...state, refetchTrigger: action.payload };
		case "SET_COLUMNS_SHOWN":
			return { ...state, columnsShown: action.payload };
		case "SET_PAGE_NUM":
			return { ...state, pageNum: action.payload };
		default:
			return baseTableReducer(state, action as BaseTableAction);
	}
}

/* ---------------------------------------------------------------------
   SubmissionsTable
   --------------------------------------------------------------------- */
const SubmissionsTable: FC<SubmissionsTableProps> = ({
	organization,
	browserLocationId,
}) => {
	const { user } = useUserStore();
	const location = useLocation();
	const navigate = useNavigate();

	// Memoize preserved state
	const preservedState = useMemo(
		() => location?.state?.submissionsTableParams,
		[location?.state?.submissionsTableParams],
	);

	// Add a ref to track if we've handled the initial preserved state
	const initialPreservedStateHandledRef = useRef(false);

	// Memoize initial state
	const initialTableState = useMemo(() => {
		const forms = user?.organization?.forms ?? [];
		const defaultForm =
			forms.length > 0
				? forms.find(
						(form) => form.config.formName === "Inventory & Tagging Form",
					)?.form_id || forms[0]?.form_id
				: undefined;

		return {
			...initialState,
			pageNum: preservedState?.pageNum ?? initialState.pageNum,
			pageSize: preservedState?.pageSize ?? initialState.pageSize,
			searchVal: preservedState?.searchVal ?? initialState.searchVal,
			sortBy: preservedState?.sortBy ?? initialState.sortBy,
			sortOrder: preservedState?.sortOrder ?? initialState.sortOrder,
			selectedFormMode:
				preservedState?.selectedFormMode ?? initialState.selectedFormMode,
			selectedForm: preservedState?.selectedForm ?? defaultForm,
			viewingDeleted:
				preservedState?.viewingDeleted ?? initialState.viewingDeleted,
			filters: preservedState?.filters ?? initialState.filters,
			filterOptions:
				preservedState?.filterOptions ?? initialState.filterOptions,
			tableColumns: getOrgColumns(organization, undefined),
			columnsShown: initializeColumnsShown(
				getOrgColumns(organization, undefined),
			),
			formsArr: forms,
		};
	}, [organization, preservedState, user?.organization?.forms]);

	const [state, dispatch] = useReducer(reducer, initialTableState);

	// Memoize form change handler
	const handleFormChange = useCallback((formId: number) => {
		dispatch({
			type: "SET_SELECTED_FORM",
			payload: formId,
		});
		dispatch({
			type: "SET_PAGE_NUM",
			payload: 1,
		});
	}, []);

	// Memoize search handler
	const handleSearch = useCallback((value: string) => {
		dispatch({ type: "SET_SEARCH_VAL", payload: value });
	}, []);

	// Memoize fetch dependencies
	const fetchDependencies = useMemo(
		() => ({
			selectedForm: state.selectedForm,
			selectedFormMode: state.selectedFormMode,
			viewingDrafts: state.viewingDrafts,
			viewingDeleted: state.viewingDeleted,
			sortBy: state.sortBy,
			sortOrder: state.sortOrder,
			pageNum: state.pageNum,
			pageSize: state.pageSize,
			searchVal: state.searchVal,
			filters: state.filters,
			refetchTrigger: state.refetchTrigger,
		}),
		[
			state.selectedForm,
			state.selectedFormMode,
			state.viewingDrafts,
			state.viewingDeleted,
			state.sortBy,
			state.sortOrder,
			state.pageNum,
			state.pageSize,
			state.searchVal,
			state.filters,
			state.refetchTrigger,
		],
	);

	// Add a loading ref to track concurrent loading states
	const loadingRef = useRef<{
		assetTypes: boolean;
		data: boolean;
	}>({
		assetTypes: false,
		data: false,
	});

	// Consolidate loading state management
	const setLoading = useCallback(
		(type: "assetTypes" | "data", isLoading: boolean) => {
			loadingRef.current[type] = isLoading;
			const isAnyLoading = Object.values(loadingRef.current).some(Boolean);
			dispatch({ type: "SET_IS_LOADING", payload: isAnyLoading });
		},
		[],
	);

	// Consolidate initialization and data fetching into a single effect
	useEffect(() => {
		let isMounted = true;
		const controller = new AbortController();

		const initialize = async () => {
			try {
				// Only load asset types if we haven't already and we're on initial load
				if (
					!state.filterOptions?.assetTypes &&
					!initialPreservedStateHandledRef.current
				) {
					setLoading("assetTypes", true);
					const assetTypes = await selfOrganizationAssetTypes(false);
					if (!isMounted) return;

					dispatch({ type: "SET_FILTER_OPTIONS", payload: { assetTypes } });
					setLoading("assetTypes", false);
				}

				// Handle preserved state only once
				if (!initialPreservedStateHandledRef.current) {
					initialPreservedStateHandledRef.current = true;
					if (preservedState) {
						// Keep loading true while we apply preserved state
						setLoading("data", true);
						// Apply preserved state
						dispatch({
							type: "BATCH_UPDATE",
							payload: preservedState,
						});
						// Let the pagination effect handle the data fetch
						return;
					}
				}

				// Fetch data if we have the necessary dependencies
				if (
					state.selectedForm &&
					state.selectedFormMode &&
					state.formsArr.length > 0
				) {
					setLoading("data", true);
					await fetchSubmissionTableData(state, dispatch, browserLocationId);
					if (!isMounted) return;
					setLoading("data", false);
				}
			} catch (error) {
				console.error("Error in initialization:", error);
				if (isMounted) {
					setLoading("assetTypes", false);
					setLoading("data", false);
				}
			}
		};

		initialize();

		return () => {
			isMounted = false;
			controller.abort();
		};
	}, [
		browserLocationId,
		preservedState,
		state.selectedForm,
		state.selectedFormMode,
		state.formsArr.length,
		state.refetchTrigger,
	]);

	// Handle organization changes
	useEffect(() => {
		if (user?.organization?.forms) {
			const forms = user.organization.forms.filter((form) => form.enabled);
			const defaultForm =
				forms.length > 0
					? forms.find(
							(form) => form.config.formName === "Inventory & Tagging Form",
						)?.form_id || forms[0]?.form_id
					: undefined;

			dispatch({
				type: "BATCH_UPDATE",
				payload: {
					formsArr: forms,
					selectedForm: defaultForm,
					pageNum: 1,
					dataSource: [],
					selectedFormMode: "0",
					viewingDrafts: false,
					viewingDeleted: false,
					isViewingQAForm: false,
					filters: {},
					filterOptions: state.filterOptions,
					showExportModal: false,
					showDeleteModal: false,
					refetchTrigger: Date.now(),
				},
			});
		}
	}, [user?.organization?.external_firebase_id]);

	// Add effect to handle pagination and filter changes
	useEffect(() => {
		// Skip during initialization
		if (!initialPreservedStateHandledRef.current) {
			return;
		}

		// Only fetch if we have the necessary dependencies
		if (
			state.selectedForm &&
			state.selectedFormMode &&
			state.formsArr.length > 0
		) {
			setLoading("data", true);
			fetchSubmissionTableData(state, dispatch, browserLocationId)
				.then(() => {
					setLoading("data", false);
				})
				.catch((error) => {
					console.error("Error fetching data:", error);
					setLoading("data", false);
				});
		}
	}, [
		state.pageNum,
		state.pageSize,
		state.sortBy,
		state.sortOrder,
		state.searchVal,
		state.filters,
		state.viewingDeleted,
		state.viewingDrafts,
		browserLocationId,
		// Don't include state.selectedForm or state.selectedFormMode here
		// as they're handled by the main initialization effect
	]);

	// Memoize row click handler
	const handleRowClick = useCallback(
		(record: SubmissionData) => {
			const isAdminView = location.pathname.includes("admin");
			const locId = browserLocationId || "";

			if (state.viewingDrafts) {
				navigate(`/forms/${record.form_id}`, {
					state: {
						responseData: record?.raw_input,
						draftId: record.id,
						formId: record.form_id,
						isSubmissionCompleted: Boolean(record?.end_time),
						locationId: record.location?.id,
						savedLocation: record.location,
						chosenAssetType: record?.raw_input?.assetType,
						userLocation: record?.draft?.userLocation,
						isQA: false,
						fromDraftsTable: state.viewingDrafts,
						filters: state.filters,
						filterOptions: state.filterOptions,
						formsArr: state.formsArr,
					},
				});
				return;
			}

			navigate(
				isAdminView
					? `/admin/submissions/${record.id}`
					: `/locations/${locId}/submissions/${record.id}`,
				{
					state: {
						formId: state.selectedForm,
						submissionsTableParams: {
							pageNum: state.pageNum,
							pageSize: state.pageSize,
							sortBy: state.sortBy,
							sortOrder: state.sortOrder,
							searchVal: state.searchVal,
							viewingDeleted: state.viewingDeleted,
							selectedFormMode: state.selectedFormMode,
							viewingDrafts: state.viewingDrafts,
							selectedForm: state.selectedForm,
							filters: state.filters,
							filterOptions: state.filterOptions,
							formsArr: state.formsArr,
						},
					},
				},
			);
		},
		[
			browserLocationId,
			location.pathname,
			navigate,
			state.filters,
			state.filterOptions,
			state.formsArr,
			state.pageNum,
			state.pageSize,
			state.searchVal,
			state.selectedForm,
			state.selectedFormMode,
			state.sortBy,
			state.sortOrder,
			state.viewingDeleted,
			state.viewingDrafts,
		],
	);

	// Memoize actual data source to prevent flashing
	const actualDataSource = useMemo(() => {
		if (
			state.isLoading ||
			loadingRef.current.data ||
			loadingRef.current.assetTypes
		) {
			return Array(state.pageSize || 10).fill({ isLoading: true });
		}
		return state.dataSource;
	}, [state.isLoading, state.dataSource, state.pageSize]);

	// Memoize table props
	const tableProps = useMemo(
		() =>
			getTableProps(state, dispatch, {
				handleRowClick,
				dataSource: actualDataSource,
			}),
		[actualDataSource, handleRowClick, state],
	);

	/* ---------------------------------------------------------------------
     Modal toggles
     --------------------------------------------------------------------- */
	const toggleExportModal = useCallback(() => {
		dispatch({ type: "SET_SHOW_EXPORT_MODAL", value: !state.showExportModal });
	}, [state.showExportModal]);

	const toggleDeleteModal = useCallback(() => {
		dispatch({ type: "SET_SHOW_DELETE_MODAL", value: !state.showDeleteModal });
	}, [state.showDeleteModal]);

	// Add new handlers
	const handleModeChange = useCallback(
		(mode: string, isQAForm: boolean, isDraft: boolean) => {
			dispatch({ type: "SET_PAGE_NUM", payload: 1 });
			dispatch({ type: "SET_SELECTED_FORM_MODE", payload: mode });
			dispatch({ type: "SET_IS_VIEWING_QA_FORM", payload: isQAForm });
			dispatch({ type: "SET_VIEWING_DRAFTS", payload: isDraft });
		},
		[],
	);

	const handleViewDeletedChange = useCallback((checked: boolean) => {
		dispatch({ type: "SET_VIEWING_DELETED", payload: checked });
	}, []);

	const handleColumnVisibilityChange = useCallback(
		(key: string, isVisible: boolean) => {
			dispatch({
				type: "SET_COLUMNS_SHOWN",
				payload: { ...state.columnsShown, [key]: isVisible },
			});
		},
		[state.columnsShown],
	);

	/* ---------------------------------------------------------------------
     Render
     --------------------------------------------------------------------- */
	return (
		<div className="table-card">
			<div className="table-header d-flex justify-content-between">
				<Search
					placeholder="Search"
					allowClear
					style={{ width: 300 }}
					onSearch={handleSearch}
					disabled={state.isLoading}
					defaultValue={state.searchVal}
				/>
				<Space>
					<FormSelect
						forms={state.formsArr}
						selectedFormId={state.selectedForm}
						isLoading={state.isLoading}
						onFormChange={handleFormChange}
					/>
					<QASubmissionDraftSelect
						isLoading={state.isLoading}
						selectedFormMode={state.selectedFormMode}
						onModeChange={handleModeChange}
					/>
					<DeletedSwitch
						isLoading={state.isLoading}
						viewingDeleted={state.viewingDeleted}
						onViewDeletedChange={handleViewDeletedChange}
					/>
				</Space>
				<Space>
					<ColumnConfigDropdown
						columns={state.tableColumns}
						columnsShown={state.columnsShown}
						onColumnVisibilityChange={handleColumnVisibilityChange}
					/>
					<DeleteButton
						checkedItems={state.checkedItems || []}
						viewingDeleted={state.viewingDeleted}
						onClick={toggleDeleteModal}
					/>
					<Button
						icon={<DownloadOutlined style={{ fontSize: "20px" }} />}
						type="text"
						onClick={toggleExportModal}
					/>
				</Space>
			</div>

			<Divider />

			<DFPTable
				locale={{
					emptyText:
						!state.isLoading &&
						!loadingRef.current.data &&
						!loadingRef.current.assetTypes &&
						actualDataSource.length === 0 ? (
							<DFPEmpty
								className="w-100 h-100"
								style={{ minHeight: "50dvh" }}
								description={
									!state.selectedForm
										? "Please select a form to view submissions"
										: "No submissions returned. Please modify your search term or update your filters and try again."
								}
							/>
						) : null,
				}}
				{...tableProps}
			/>

			<ExportModal
				showExport={state.showExportModal}
				toggle={toggleExportModal}
				browserLocationId={browserLocationId}
				tableSelectedForm={state.selectedForm ?? null}
				tableSelectedFormMode={state.selectedFormMode}
			/>
			<DeleteModal
				state={state}
				dispatch={dispatch}
				browserLocationId={browserLocationId}
				tableType="submissions"
				fetchData={fetchSubmissionTableData}
			/>
		</div>
	);
};

export { SubmissionsTable };
