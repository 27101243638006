import { type IOrganization, type IRole } from "@/stores/user-store";
import { useUserStore } from "@/stores/user-store";
import { getLatestSubmission } from "@services/submission-service";
import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { DistanceToCoastCard } from "./children/DistanceToCoastCard";
import { LocationInfoCard } from "./children/LocationInfoCard";

const GeneralTab = (props: {
	organization: IOrganization;
	info: any;
	setToast: any;
	healthScore: any;
	locationId: any;
	userRole: IRole;
}) => {
	const { organization, info, locationId } = props;

	const isVerizonUser = useUserStore(
		(state) => state?.user?.role.access_level === 125,
	);

	// Last submission state
	const [lastSubmission, setLastSubmission] = useState<any>(null);

	// Queries the db for the last submission in this org / location
	const updateLastSubmission = async () => {
		const response = await getLatestSubmission(locationId);
		if (response) {
			const id = response.id;
			const data = response.raw_input || [];
			data.submissionId = response.id;
			data.formId = response.form_id;
			data.logo = null;
			data.submittingUser = response.user.name;
			data.address = `${response.location.address1}, ${response.location.state} ${response.location.zip}`;
			response.end_time = formatDate(data.submittedDate);
			data.organization = organization.external_firebase_id;
			const latestResponse = { id: id, data: data };

			setLastSubmission(latestResponse);
		} else {
			setLastSubmission(undefined);
		}
	};

	// Formats submission date to "YYYY-MM-DD HH:MM:SS" format
	const formatDate = (oldDate: string | number | Date) => {
		const date = new Date(oldDate);

		const month = String(date.getMonth() + 1).padStart(2, "0");
		const day = String(date.getDate()).padStart(2, "0");
		const hours = String(date.getHours()).padStart(2, "0");
		const minutes = String(date.getMinutes()).padStart(2, "0");
		const seconds = String(date.getSeconds()).padStart(2, "0");
		const year = String(date.getFullYear());

		return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
	};

	// Updates lastSubmission when info changes
	useEffect(() => {
		if (info !== undefined) {
			updateLastSubmission();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [info, isVerizonUser]);

	// Updates generalTab state when lastSubmission state changes
	useEffect(() => {
		if (lastSubmission !== null) {
			// setGeneralTab();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lastSubmission]);

	return (
		<div color="light" className="general-tab tab-content py-2">
			<Row className="light-border-b mb-2 md-0">
				<Col className="col-12 col-sm-12 col-xl-4 mb-5 mb-lg-0 d-flex flex-column max-h-none">
					<div className="card mt-0">
						<DistanceToCoastCard
							latitude={info?.latitude}
							longitude={info?.longitude}
							nearestShorePoint={info?.shore_point}
						/>
					</div>
				</Col>
				<Col className="col-12 col-xl-12 mb-2 mb-md-0 justify-content-start align-items-center my-3">
					<LocationInfoCard info={info} />
				</Col>
			</Row>
		</div>
	);
};

export { GeneralTab };
