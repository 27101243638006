import { type IRole } from "@/stores/user-store";
import { Button, Card } from "antd";

const SettingsTab = ({
	userRole,
	resetVirtual,
}: {
	userRole: IRole;
	resetVirtual: () => void;
}) => {
	return (
		<div className="settings-tab">
			<Card title="Location Settings">
				{userRole.access_level >= 500 && (
					<Button type="primary" onClick={resetVirtual}>
						Reset Virtual ID
					</Button>
				)}
			</Card>
		</div>
	);
};

export { SettingsTab };
