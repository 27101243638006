import { ALFRED_SERVICE_URL } from "@/constants/env";
import { auth } from "@/services/auth-service";

export async function exportSubmissionToCSV(submissionId: string | number) {
	const url = `${ALFRED_SERVICE_URL}/submission/export-csv/${String(submissionId)}`;
	const token = await auth?.currentUser?.getIdToken();
	const resp = await fetch(url, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});

	if (!resp.ok) {
		const text = await resp.text();
		throw new Error(`Failed to export CSV: ${resp.status} - ${text}`);
	}

	return resp.blob();
}
