import type { StringMap } from "@/models/abstract-types";
import { BellOutlined, CloseOutlined } from "@ant-design/icons";
import {
	deleteNotification,
	getNotifications,
	setNotificationsToRead,
} from "@services/user-service";
import { usePageStore } from "@stores";
import {
	Badge,
	Button,
	Dropdown,
	List,
	Modal,
	Typography,
	message,
	theme,
} from "antd";
import { useEffect, useState } from "react";
import { MdDeleteOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import "./Alerts.scss";

type AlertType = "drafts" | "releases" | "submission_qa";

interface Alert {
	id: string;
	type: AlertType;
	message: string;
	submissionId?: string;
	is_unread: boolean;
	is_deleted: boolean;
	data: StringMap;
}

const Alerts = () => {
	const navigate = useNavigate();
	const { alertModal, setAlertModal } = usePageStore() || {
		alertModal: { show: false, title: "", body: "" },
	};

	const [visible, setVisible] = useState(false);
	const [alerts, setAlerts] = useState<Alert[]>([]);
	const [unreadCount, setUnreadCount] = useState(0);

	const { token } = theme.useToken();

	// Fetch notifications on component mount
	useEffect(() => {
		const fetchNotifications = async () => {
			const notifications = await getNotifications();
			setAlerts(notifications);
			const unread = notifications.filter(
				(alert: Alert) => alert.is_unread,
			).length;
			setUnreadCount(unread);
		};
		fetchNotifications();
	}, []);

	const handleMenuClick = async (open: boolean) => {
		setVisible(open);
		if (open && alerts.length > 0) {
			await setNotificationsToRead();
			setUnreadCount(0);
		}
	};

	const handleAlertClick = (alert: Alert) => {
		setVisible(false);
		switch (alert.type) {
			case "drafts":
				navigate("/admin/submissions", {
					state: { submissionsTableParams: { selectedFormMode: 2 } },
				});
				break;
			case "releases":
				navigate("/releases");
				break;
			case "submission_qa":
				navigate(
					`/locations/${alert.data.location_id}/submissions/${alert.data.submission_id}`,
					{},
				);
				break;
			default:
				break;
		}
	};

	const handleRemoveAlert = async (id: string) => {
		try {
			await deleteNotification(id).then(() => {
				setAlerts((prevAlerts) =>
					prevAlerts.filter((alert) => alert.id !== id),
				);
				message.success("Notification removed successfully.");
			});
		} catch (error) {
			message.error("Failed to remove notification.");
		}
	};

	const closeAlertModal = () => {
		setAlertModal({
			show: false,
			title: "",
			body: "",
		});
	};

	const notificationMenu = (
		<div
			style={{
				maxHeight: 300,
				overflowY: "auto",
				backgroundColor: token.colorBgContainer,
				border: `1px solid ${token.colorBorder}`,
				borderRadius: token.borderRadius,
				boxShadow: token.boxShadow,
			}}
			className="notification-menu"
		>
			<List
				dataSource={alerts}
				locale={{ emptyText: "You have no alerts at this time." }}
				renderItem={(alert: Alert) => (
					<List.Item
						style={{
							position: "relative",
							padding: token.paddingSM,
							borderBottom: `1px solid ${token.colorBorder}`,
							transition: "background-color 0.3s",
							color: token.colorPrimary,
							backgroundColor: alert.is_unread
								? token.colorBgTextHover
								: token.colorBgContainer,
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
						onClick={() => handleAlertClick(alert)}
						className="d-flex flex-row gap-2"
						onMouseEnter={(e) => e.currentTarget.classList.add("hover")}
						onMouseLeave={(e) => e.currentTarget.classList.remove("hover")}
					>
						{/* {alert.message} */}
						<Typography.Text
							style={{
								color: alert.is_unread ? token.colorError : token.colorPrimary,
								fontWeight: alert.is_unread ? "bold" : "normal",
							}}
							copyable
						>
							{alert.message}
						</Typography.Text>
						<Button
							onClick={(e) => {
								e.stopPropagation();
								handleRemoveAlert(alert.id);
							}}
							type="default"
							variant="outlined"
							danger={true}
							style={{ padding: 5 }}
						>
							<MdDeleteOutline
								className="notification-remove-icon"
								style={{
									fontSize: "1.25rem",
									color: token.colorLink,
									opacity: 1,
									cursor: "pointer",
									transition: "opacity 0.3s",
								}}
							/>
						</Button>
					</List.Item>
				)}
			/>
		</div>
	);

	return (
		<>
			<Dropdown
				overlay={notificationMenu}
				trigger={["click"]}
				open={visible}
				onOpenChange={handleMenuClick}
				placement="bottomRight"
				className="mx-2"
			>
				<Badge count={unreadCount} offset={[-5, 5]}>
					<Button
						size="large"
						type="default"
						icon={
							visible ? (
								<CloseOutlined style={{ fontSize: token.fontSizeHeading2 }} />
							) : (
								<BellOutlined style={{ fontSize: token.fontSizeHeading2 }} />
							)
						}
					/>
				</Badge>
			</Dropdown>

			<Modal
				open={alertModal?.show}
				title={alertModal?.title}
				onCancel={closeAlertModal}
				footer={null}
				centered
				bodyStyle={{ color: token.colorText }}
			>
				{alertModal?.body}
			</Modal>
		</>
	);
};

export { Alerts };
