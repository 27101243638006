import { DFPModal } from "@/components/modals/dfp-modal.js";
import {
	selfOrganizationAssetTypeCategories,
	selfOrganizationAssetTypes,
} from "@/services/organization-service";
import { exportSubmissionsCsvApi } from "@/services/submission-service";
import { getOrganizationSubmittedUsers } from "@/services/user-service.js";
import { useUserStore } from "@/stores/index.js";
import { DB_ORG } from "@constants/db";
import { DatePicker, Form, Select, message } from "antd";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useReducer } from "react";

interface ExportModalState {
	loading: boolean;
	filterField: { label: string; value: string };
	filterOptions: Array<{ label: string; value: any }>;
	selectedOption: { label?: string; value: any } | null;
	sortOption: { label: string; value: string };
	sortDirection: { label: string; value: string };
	showApproved: { label: string; value: boolean | null };
	selectedForm: { label: string; value: number | string | null } | null;
	startDate: string | null;
	endDate: string | null;
	formOptions: Array<{ label: string; value: number | string | null }>;
	formModeOption: { label: string; value: number };
	isReturningDeleted: boolean;
	isFormValid: boolean;
}

const sortFieldOptions = [
	{ label: "Submission Date", value: "submitted_date" },
	{ label: "Submitting User", value: "submitting_user" },
];

const sortDirectionOptions = [
	{ label: "Ascending", value: "asc" },
	{ label: "Descending", value: "desc" },
];

const defaultFormOptions = [
	{ label: "Inventory & Tagging Form", value: 3 },
	{ label: "Facility Vision Form", value: 1 },
	{ label: "Property Condition Assessment", value: 7 },
];

const approvalOptions = [
	{ label: "Both", value: null },
	{ label: "Approved", value: true },
	{ label: "Unapproved", value: false },
];

const filterFieldOptions = [
	{ label: "(no filter applied)", value: "" },
	{ label: "Submitting User", value: "submitting_user" },
	{ label: "Asset Type Category", value: "asset_type_category" },
	{ label: "Asset Type", value: "asset_type" },
];

const formModeOptions = [
	{ label: "Submissions", value: 0 },
	{ label: "QA Submissions", value: 1 },
	{ label: "Draft Submissions", value: 2 },
];

const cushmanFormOptions = [
	{ label: "Submissions", value: 0 },
	{ label: "Draft Submissions", value: 2 },
];

const initialState: ExportModalState = {
	loading: false,
	filterField: filterFieldOptions[0],
	filterOptions: [],
	selectedOption: defaultFormOptions[0],
	sortOption: sortFieldOptions[0],
	sortDirection: sortDirectionOptions[1],
	showApproved: approvalOptions[0],
	selectedForm: null,
	startDate: null,
	endDate: null,
	formOptions: [],
	formModeOption: formModeOptions[0],
	isReturningDeleted: false,
	isFormValid: true,
};

interface ExportModalProps {
	showExport: boolean;
	toggle: () => void;
	browserLocationId: string;
	tableSelectedForm: number | null;
	tableSelectedFormMode: string;
}

function checkFormValidity(state: ExportModalState) {
	const requiredFields = [state.selectedForm, state.formModeOption];
	if (state.filterField && state.filterField.value !== "") {
		requiredFields.push(state.selectedOption as any);
	}
	return requiredFields.every(
		(field) => field && field.value !== "" && field.value != null,
	);
}

function reducer(
	state: ExportModalState,
	action: { type: string; payload?: any },
) {
	switch (action.type) {
		case "SET_LOADING":
			return { ...state, loading: action.payload };
		case "SET_FILTER_FIELD":
			return { ...state, filterField: action.payload, selectedOption: null };
		case "SET_FILTER_OPTIONS":
			return { ...state, filterOptions: action.payload, selectedOption: null };
		case "SET_SELECTED_OPTION":
			return { ...state, selectedOption: action.payload };
		case "SET_SORT_OPTION":
			return { ...state, sortOption: action.payload };
		case "SET_SORT_DIRECTION":
			return { ...state, sortDirection: action.payload };
		case "SET_SHOW_APPROVED":
			return { ...state, showApproved: action.payload };
		case "SET_SELECTED_FORM":
			return { ...state, selectedForm: action.payload };
		case "SET_START_DATE":
			return { ...state, startDate: action.payload };
		case "SET_END_DATE":
			return { ...state, endDate: action.payload };
		case "SET_FORM_OPTIONS":
			return { ...state, formOptions: action.payload };
		case "SET_FORM_MODE":
			return { ...state, formModeOption: action.payload };
		case "SET_RETURNING_DELETED":
			return { ...state, isReturningDeleted: action.payload };
		case "SET_IS_FORM_VALID":
			return { ...state, isFormValid: action.payload };
		case "RESET":
			return initialState;
		default:
			return state;
	}
}

export function ExportModal({
	showExport,
	toggle,
	browserLocationId,
	tableSelectedForm,
	tableSelectedFormMode,
}: ExportModalProps) {
	const [state, dispatch] = useReducer(reducer, initialState);
	const user = useUserStore((s) => s.user);
	const organization = user?.organization;
	const formOptions = useMemo(() => {
		return organization?.forms?.map((f) => ({
			label: f.config.formName,
			value: f.form_id,
		}));
	}, [organization?.forms]);

	useEffect(() => {
		if (formOptions) {
			dispatch({ type: "SET_FORM_OPTIONS", payload: formOptions });
		}
	}, [formOptions]);

	const handleToggle = useCallback(() => {
		dispatch({ type: "RESET" });
		toggle();
	}, [toggle]);

	useEffect(() => {
		if (tableSelectedForm != null) {
			// attempt to find a matching label from state.formOptions
			const found = state.formOptions.find(
				(opt) => opt.value === tableSelectedForm,
			);
			const newFormOption = found
				? found
				: { label: "Table's form", value: tableSelectedForm };
			dispatch({ type: "SET_SELECTED_FORM", payload: newFormOption });
		}
	}, [tableSelectedForm, state.formOptions]);

	useEffect(() => {
		if (tableSelectedFormMode) {
			const parsedMode = Number.parseInt(tableSelectedFormMode, 10);
			const foundMode = formModeOptions.find((opt) => opt.value === parsedMode);
			dispatch({
				type: "SET_FORM_MODE",
				payload: foundMode || { label: "Unknown Mode", value: parsedMode },
			});
		}
	}, [tableSelectedFormMode]);

	useEffect(() => {
		const fetchFieldOptions = async () => {
			let options: Array<{ label: string; value: any }> = [];
			if (state.filterField?.value === "submitting_user") {
				const users = await getOrganizationSubmittedUsers(
					state.selectedForm?.value,
				);
				options = users?.map((u: string) => ({ label: u, value: u }));
			} else if (state.filterField?.value === "reviewed") {
				options = [
					{ label: "True", value: true },
					{ label: "False", value: false },
				];
			} else if (state.filterField?.value === "asset_type_category") {
				const res = await selfOrganizationAssetTypeCategories();
				options = res?.map((cat: any) => ({
					label: cat.name,
					value: cat.name,
				}));
			} else if (state.filterField?.value === "asset_type") {
				const res = await selfOrganizationAssetTypes();
				options = res?.map((type: any) => ({
					label: type.name,
					value: type.name,
				}));
			} else if (state.filterField && state.filterField.value !== "") {
				const exportOpts =
					organization?.config?.export_options?.[state.filterField.value] || [];
				options = exportOpts?.map((opt: string) => ({
					label: opt,
					value: opt,
				}));
			}
			dispatch({ type: "SET_FILTER_OPTIONS", payload: options });
		};

		if (state.filterField) {
			dispatch({ type: "SET_FILTER_OPTIONS", payload: [] });
			fetchFieldOptions();
		}
	}, [state.filterField, state.selectedForm, organization]);

	useEffect(() => {
		dispatch({
			type: "SET_IS_FORM_VALID",
			payload: checkFormValidity(state),
		});
	}, [
		state.selectedForm,
		state.formModeOption,
		state.filterField,
		state.selectedOption,
		state.sortOption,
		state.sortDirection,
		state.startDate,
		state.endDate,
		state.isReturningDeleted,
	]);

	const handleExportCsv = useCallback(async () => {
		dispatch({ type: "SET_LOADING", payload: true });
		message.info("Exporting CSV...");

		const columnFilter =
			state.filterField?.value && state.selectedOption
				? { [state.filterField.value]: [state.selectedOption.value] }
				: {};

		try {
			if (!state.selectedForm?.value && state.formModeOption.value == null) {
				message.error("Please select a form and form mode.");
				return;
			}

			const isApproved =
				organization?.external_firebase_id === DB_ORG.CUSHMANWAKEFIELD &&
				state.showApproved?.value !== null
					? state.showApproved.value
					: undefined;

			const dateRange =
				state.startDate && state.endDate
					? [state.startDate, state.endDate]
					: undefined;

			// Here's where form_id is passed to the new export endpoint:
			const blob = await exportSubmissionsCsvApi({
				form_id: String(state.selectedForm.value),
				form_mode: state.formModeOption.value,
				offset: 0,
				limit: 25000,
				sort: state.sortOption.value,
				sort_direction: state.sortDirection.value,
				column_filter: columnFilter,
				location_id: browserLocationId,
				include_deleted: state.isReturningDeleted,
				date_range: dateRange,
				is_approved: isApproved,
			});

			const blobUrl = URL.createObjectURL(blob);
			const link = document.createElement("a");
			link.href = blobUrl;
			link.download = `submissions_export_${Date.now()}.csv`;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			URL.revokeObjectURL(blobUrl);

			message.success("CSV export completed.");
			handleToggle();
		} catch (err) {
			console.error("CSV export failed:", err);
			message.error("Failed to export CSV");
		} finally {
			dispatch({ type: "SET_LOADING", payload: false });
		}
	}, [
		browserLocationId,
		handleToggle,
		organization?.external_firebase_id,
		state.endDate,
		state.filterField,
		state.formModeOption.value,
		state.isReturningDeleted,
		state.selectedForm,
		state.selectedOption,
		state.showApproved,
		state.sortDirection.value,
		state.sortOption.value,
		state.startDate,
	]);

	return (
		<DFPModal
			open={showExport}
			className="export-modal"
			onCancel={handleToggle}
			onOk={handleExportCsv}
			title="Export CSV - Custom Options"
			confirmLoading={state.loading}
			okButtonProps={{ disabled: !state.isFormValid }}
			okText="Export"
		>
			<Form layout="vertical">
				<div className="mb-2 fs-5">Filter Your Submissions</div>
				{state.formOptions.length > 0 && (
					<Form.Item label="Select a Form to Export" required>
						<Select
							options={state.formOptions}
							value={state.selectedForm?.value ?? undefined}
							onChange={(value, option) => {
								// Ensure we store both label & value in the store
								if (!Array.isArray(option)) {
									dispatch({
										type: "SET_SELECTED_FORM",
										payload: { label: option?.label, value },
									});
								}
							}}
						/>
					</Form.Item>
				)}

				<Form.Item label="Select a Form Mode" required>
					<Select
						options={
							organization?.external_firebase_id === DB_ORG.CUSHMANWAKEFIELD
								? cushmanFormOptions
								: formModeOptions
						}
						value={state.formModeOption.value}
						onChange={(value, option) => {
							if (!Array.isArray(option)) {
								dispatch({ type: "SET_FORM_MODE", payload: option });
							}
						}}
					/>
				</Form.Item>

				<Form.Item label="Select a Date Range">
					<DatePicker.RangePicker
						format="MM/DD/YYYY"
						className="w-100"
						value={[
							state.startDate ? dayjs(state.startDate) : null,
							state.endDate ? dayjs(state.endDate) : null,
						]}
						onChange={(dates) => {
							const [start, end] = dates || [null, null];
							dispatch({
								type: "SET_START_DATE",
								payload: start ? start.format("YYYY-MM-DD") : null,
							});
							dispatch({
								type: "SET_END_DATE",
								payload: end ? end.format("YYYY-MM-DD") : null,
							});
						}}
					/>
				</Form.Item>

				<Form.Item label="Select a Field to Filter Results">
					<Select
						options={filterFieldOptions}
						value={state.filterField.value}
						onChange={(value, option) => {
							if (!Array.isArray(option)) {
								dispatch({ type: "SET_FILTER_FIELD", payload: option });
							}
						}}
					/>
				</Form.Item>

				{state.filterField.value && state.filterField.value !== "" && (
					<Form.Item label="Select an Option for the Filtering Field" required>
						<Select
							loading={state.filterOptions.length === 0 || state.loading}
							disabled={state.filterOptions.length === 0 || state.loading}
							options={state.filterOptions}
							value={state.selectedOption?.value ?? undefined}
							onChange={(val, option) => {
								if (!Array.isArray(option)) {
									dispatch({ type: "SET_SELECTED_OPTION", payload: option });
								}
							}}
						/>
					</Form.Item>
				)}

				<Form.Item label="Select Which Field to Sort By" required>
					<Select
						options={sortFieldOptions}
						value={state.sortOption.value}
						onChange={(value, option) => {
							if (!Array.isArray(option)) {
								dispatch({ type: "SET_SORT_OPTION", payload: option });
							}
						}}
					/>
				</Form.Item>

				<Form.Item label="Select Sort Direction" required>
					<Select
						options={sortDirectionOptions}
						value={state.sortDirection.value}
						onChange={(value, option) => {
							if (!Array.isArray(option)) {
								dispatch({ type: "SET_SORT_DIRECTION", payload: option });
							}
						}}
					/>
				</Form.Item>

				{organization?.external_firebase_id === DB_ORG.CUSHMANWAKEFIELD && (
					<Form.Item label="Include Approved, Unapproved, or Both?" required>
						<Select
							options={approvalOptions}
							value={state.showApproved.value}
							onChange={(value, option) => {
								if (!Array.isArray(option)) {
									dispatch({ type: "SET_SHOW_APPROVED", payload: option });
								}
							}}
						/>
					</Form.Item>
				)}

				<Form.Item label="Include Deleted Submissions?" required>
					<Select
						options={[
							{ label: "No", value: false },
							{ label: "Yes", value: true },
						]}
						value={state.isReturningDeleted}
						onChange={(val) => {
							dispatch({ type: "SET_RETURNING_DELETED", payload: val });
						}}
					/>
				</Form.Item>
			</Form>
		</DFPModal>
	);
}
