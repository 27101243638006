import { AuthProviders, auth } from "@/services/auth-service";
import { useUserStore } from "@/stores/user-store";
import { getUserSelf } from "@services/user-service";
import { useNavigate } from "react-router-dom";

interface LoginResult {
	success: boolean;
	user?: any;
	error?: string;
}

/**
 * Pure function to handle user login with email and password
 * @param email - User's email
 * @param password - User's password
 * @returns Promise<LoginResult>
 */
export const loginUser = async (
	email: string,
	password: string,
): Promise<LoginResult> => {
	try {
		const authResponse = await AuthProviders.signInWithEmailAndPassword(
			auth,
			email,
			password,
		);

		const user = await getUserSelf();

		if (user) {
			await AuthProviders.updateProfile(authResponse.user, {
				displayName: user.name,
			});
			user.displayName = user.name;
			return { success: true, user };
		}

		return {
			success: false,
			error: "Failed to fetch user data after authentication",
		};
	} catch (error: any) {
		return {
			success: false,
			error: error.message || "An error occurred during login",
		};
	}
};

/**
 * Pure function to handle user logout
 * @returns Promise<{ success: boolean, error?: string }>
 */
export const logoutUser = async (): Promise<{
	success: boolean;
	error?: string;
}> => {
	try {
		await AuthProviders.logout();
		return { success: true };
	} catch (error: any) {
		return {
			success: false,
			error: error.message || "An error occurred during logout",
		};
	}
};

/**
 * React hook to handle login state management
 * @returns Object with login function and loading state
 */
export const useLogin = () => {
	const setUserState = useUserStore.getState().setUser;
	const navigate = useNavigate();

	const handleLogin = async (email: string, password: string) => {
		const result = await loginUser(email, password);
		if (result.success && result.user) {
			setUserState(result.user);
			navigate("/");
		} else {
			return {
				success: false,
				error: result.error,
			};
		}

		return result;
	};

	return handleLogin;
};

/**
 * React hook to handle logout state management
 * @returns Object with logout function
 */
export const useLogout = () => {
	const setUser = useUserStore((state: any) => state.setUser);
	const handleLogout = async () => {
		const result = await logoutUser();

		if (result.success) {
			setUser(null);
			localStorage.removeItem("dfp-user");
		} else {
			return {
				success: false,
				error: result.error,
			};
		}

		return result;
	};

	return handleLogout;
};
