import {
	getManufacturers,
	getOrganizations,
	getSelfAllowedOrganizations,
	getSelfOrganization,
	getSelfOrganizationForms,
	getSelfOrganizationGraphs,
	getSelfOrganizationManufacturers,
	getSelfOrganizationReports,
	selfUpdateOrganization,
	updateWithRef,
} from "@services/organization-service";

import { useUserStore } from "@/stores";
import { useEffect, useState } from "react";

export interface SelfOrganization {
	config: OrganizationConfig;
	external_firebase_id: string;
	full_name: string;
	health: Record<string, any>;
	id: number;
}

export interface OrganizationConfig {
	organization_id: number;
	show_iguide?: boolean;
	show_matterport?: boolean;
	auto_save_drafts?: boolean;
	is_location_name_required?: boolean;
	allow_tag_id?: boolean;
	service_channel_export_enabled?: boolean;
	export_options?: Record<string, any>;
	org_specific_inputs?: Record<string, any>;
	sc_export_options?: Record<string, any>;
	unique_fields?: Record<string, any>;
	integration_settings?: Record<string, any>;
	logo_url?: string | null;
}

interface QueryResult<T> {
	data?: T;
	error?: unknown;
	isLoading: boolean;
}

// Pure Functions

export const updateOrganizationData = async (
	data: any,
): Promise<{ success: boolean; error?: string; data?: any }> => {
	try {
		const result = await selfUpdateOrganization(data);
		return { success: true, data: result };
	} catch (error: any) {
		return {
			success: false,
			error: error.message || "Failed to update organization",
		};
	}
};

export const updateRefData = async (
	ref: string,
	data: any,
): Promise<{ success: boolean; error?: string }> => {
	try {
		await updateWithRef(ref, data);
		return { success: true };
	} catch (error: any) {
		return {
			success: false,
			error: error.message || "Failed to update reference data",
		};
	}
};

// Hooks with state management

export const useGetOrganizations = (
	enabled: boolean = true,
): QueryResult<any[]> => {
	const [state, setState] = useState<QueryResult<any[]>>({
		isLoading: true,
	});

	useEffect(() => {
		const fetchData = async () => {
			if (!enabled) {
				setState({ isLoading: false });
				return;
			}

			try {
				const data = await getOrganizations();
				setState({ data, isLoading: false });
			} catch (error) {
				setState({ error, isLoading: false });
			}
		};

		fetchData();
	}, [enabled]);

	return state;
};

export const useGetSelfOrganization = (): QueryResult<SelfOrganization> => {
	const { userOrganization, setUserOrganization } = useUserStore();
	const [state, setState] = useState<QueryResult<SelfOrganization>>({
		isLoading: !userOrganization,
	});

	useEffect(() => {
		const fetchData = async () => {
			if (userOrganization) {
				setState({ data: userOrganization, isLoading: false });
				return;
			}

			try {
				const data = await getSelfOrganization();
				setUserOrganization(data);
				setState({ data, isLoading: false });
			} catch (error) {
				setState({ error, isLoading: false });
			}
		};

		fetchData();
	}, [userOrganization, setUserOrganization]);

	return state;
};

export const useGetSelfAllowedOrganizations = (): QueryResult<any[]> => {
	const { userAllowedOrganizations, setUserAllowedOrganizations } =
		useUserStore();
	const [state, setState] = useState<QueryResult<any[]>>({
		isLoading:
			!userAllowedOrganizations ||
			Object.keys(userAllowedOrganizations).length === 0,
	});

	useEffect(() => {
		const fetchData = async () => {
			if (
				userAllowedOrganizations &&
				Object.keys(userAllowedOrganizations).length > 0
			) {
				setState({ data: userAllowedOrganizations, isLoading: false });
				return;
			}

			try {
				const data = await getSelfAllowedOrganizations();
				setUserAllowedOrganizations(data);
				setState({ data, isLoading: false });
			} catch (error) {
				setState({ error, isLoading: false });
			}
		};

		fetchData();
	}, [userAllowedOrganizations, setUserAllowedOrganizations]);

	return state;
};

export const useGetSelfOrganizationForms = (): QueryResult<any> => {
	const { userOrganizationForms, setUserOrganizationForms } = useUserStore();
	const [state, setState] = useState<QueryResult<any>>({
		isLoading: !userOrganizationForms,
	});

	useEffect(() => {
		const fetchData = async () => {
			if (userOrganizationForms) {
				setState({ data: userOrganizationForms, isLoading: false });
				return;
			}

			try {
				const data = await getSelfOrganizationForms();
				setUserOrganizationForms(data);
				setState({ data, isLoading: false });
			} catch (error) {
				setState({ error, isLoading: false });
			}
		};

		fetchData();
	}, [userOrganizationForms, setUserOrganizationForms]);

	return state;
};

export const useGetSelfOrganizationReports = (): QueryResult<any> => {
	const { userOrganizationReports, setUserOrganizationReports } =
		useUserStore();
	const [state, setState] = useState<QueryResult<any>>({
		isLoading: !userOrganizationReports,
	});

	useEffect(() => {
		const fetchData = async () => {
			if (userOrganizationReports) {
				setState({ data: userOrganizationReports, isLoading: false });
				return;
			}

			try {
				const data = await getSelfOrganizationReports();
				setUserOrganizationReports(data);
				setState({ data, isLoading: false });
			} catch (error) {
				setState({ error, isLoading: false });
			}
		};

		fetchData();
	}, [userOrganizationReports, setUserOrganizationReports]);

	return state;
};

export const useGetSelfOrganizationGraphs = (): QueryResult<any> => {
	const { userOrganizationGraphs, setUserOrganizationGraphs } = useUserStore();
	const [state, setState] = useState<QueryResult<any>>({
		isLoading: !userOrganizationGraphs,
	});

	useEffect(() => {
		const fetchData = async () => {
			if (userOrganizationGraphs) {
				setState({ data: userOrganizationGraphs, isLoading: false });
				return;
			}

			try {
				const data = await getSelfOrganizationGraphs();
				setUserOrganizationGraphs(data);
				setState({ data, isLoading: false });
			} catch (error) {
				setState({ error, isLoading: false });
			}
		};

		fetchData();
	}, [userOrganizationGraphs, setUserOrganizationGraphs]);

	return state;
};

export const useGetManufacturers = (
	enabled: boolean = true,
): QueryResult<any[]> => {
	const [state, setState] = useState<QueryResult<any[]>>({
		isLoading: true,
	});

	useEffect(() => {
		const fetchData = async () => {
			if (!enabled) {
				setState({ isLoading: false });
				return;
			}

			try {
				const data = await getManufacturers();
				setState({ data, isLoading: false });
			} catch (error) {
				setState({ error, isLoading: false });
			}
		};

		fetchData();
	}, [enabled]);

	return state;
};

export const useGetSelfOrganizationManufacturers = (
	enabled: boolean = true,
	organizationId?: string,
): QueryResult<any[]> => {
	const [state, setState] = useState<QueryResult<any[]>>({
		isLoading: true,
	});

	useEffect(() => {
		const fetchData = async () => {
			if (!enabled) {
				setState({ isLoading: false });
				return;
			}

			try {
				const data = await getSelfOrganizationManufacturers();
				setState({ data, isLoading: false });
			} catch (error) {
				setState({ error, isLoading: false });
			}
		};

		fetchData();
	}, [enabled, organizationId]);

	return state;
};
