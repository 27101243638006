import {
	type DashboardAnalytics,
	FormStats,
} from "@/models/organization/dashboard-analytics/dashboard-analytics.model";
import { Card, Col, Empty, Row, Spin, Typography } from "antd";
import { FC } from "react";
import { useLocation } from "react-router-dom";
import "./AlfredsNotes.scss";

const { Title, Text } = Typography;

interface AlfredsNotesProps {
	analytics: DashboardAnalytics | null;
	loading: boolean;
}

const StatCard: FC<{
	title: string;
	value: number | string;
	color?: string;
	suffix?: string;
}> = ({ title, value, color = "#1890ff", suffix }) => (
	<div className="stat-card">
		<Text className="stat-title">{title}</Text>
		<div className="stat-value" style={{ color }}>
			{value?.toLocaleString() || 0}
			{suffix}
		</div>
	</div>
);

const InventoryCard: FC<{ analytics: DashboardAnalytics }> = ({
	analytics,
}) => {
	const { inventory_and_tagging: data } = analytics;

	return (
		<Card className="overview-card">
			<div className="card-header">
				<Title level={4}>Inventory & Tagging Overview</Title>
			</div>
			<div className="dashboard-content">
				<Row gutter={[24, 24]}>
					<Col xs={24} sm={12} lg={6}>
						<StatCard
							title="Total Assets"
							value={data?.organization_asset_count || 0}
							color="#1890ff"
						/>
					</Col>
					<Col xs={24} sm={12} lg={6}>
						<StatCard
							title="Health Score"
							value={data?.organization_health_score || 0}
							suffix="%"
							color={
								data?.organization_health_score >= 75
									? "#52c41a"
									: data?.organization_health_score >= 25
										? "#faad14"
										: "#f5222d"
							}
						/>
					</Col>
					<Col xs={24} sm={12} lg={6}>
						<StatCard
							title="Assets Added (Last 30 Days)"
							value={data?.organization_assets_added_in_last_thirty_days || 0}
							color="#722ed1"
						/>
					</Col>
					<Col xs={24} sm={12} lg={6}>
						<StatCard
							title="Assets in Poor Health"
							value={data?.organization_assets_in_poor_condition || 0}
							color="#f5222d"
						/>
					</Col>
				</Row>

				<div className="locations-info">
					<Text type="secondary" className="location-text">
						{`${data?.organization_location_count?.toLocaleString()} Total Locations`}
						{data?.organization_locations_with_no_asset_submissions > 0 && (
							<span className="location-separator">
								{` • ${data?.organization_locations_with_no_asset_submissions?.toLocaleString()} Without Submissions`}
							</span>
						)}
					</Text>
				</div>
			</div>
		</Card>
	);
};

const FormCard: FC<{ formName: string; stats: FormStats }> = ({
	formName,
	stats,
}) => (
	<Card className="overview-card">
		<div className="card-header">
			<Title level={4}>{formName} Overview</Title>
		</div>
		<div className="dashboard-content">
			<Row gutter={[24, 24]}>
				<Col xs={24} sm={12}>
					<StatCard
						title="Total Submissions"
						value={stats?.total_submissions || 0}
						color="#1890ff"
					/>
				</Col>
				<Col xs={24} sm={12}>
					<StatCard
						title="Recent Submissions"
						value={stats?.recent_submissions || 0}
						color="#722ed1"
					/>
				</Col>
			</Row>
		</div>
	</Card>
);

const AlfredsNotes: FC<AlfredsNotesProps> = ({ analytics, loading }) => {
	const location = useLocation();
	const isLocationPage = location.pathname.includes("/locations");

	if (isLocationPage) {
		return null;
	}

	if (loading) {
		return (
			<div className="loading-container">
				<Spin size="large" />
			</div>
		);
	}

	if (!analytics) {
		return <Empty description="No data available" className="empty-state" />;
	}

	return (
		<Row gutter={[0, 24]}>
			<Col xs={24}>
				<InventoryCard analytics={analytics} />
			</Col>
			{Object.entries(analytics.generic_forms).map(([formId, stats]) => (
				<Col xs={24} key={formId}>
					<FormCard formName={stats?.form_name || ""} stats={stats} />
				</Col>
			))}
		</Row>
	);
};

export { AlfredsNotes };
