import { GeneratedPropIcon } from "@/components/tooltips/generated-prop-icon";
import { parseDateToUTCString } from "@/utils/transforms";
import { Descriptions, Image, Typography } from "antd";
import dayjs from "dayjs";

/**
 * Example interfaces to define your data shape.
 * Adapt as needed for your actual data structures.
 */
interface AssetPhoto {
	id: string;
	url: string;
	type: string;
}

interface ManufacturerType {
	name: string;
}

interface ModelType {
	manufacturer?: ManufacturerType;
	model_number?: string;
	[key: string]: any;
}

interface OrganizationAssetType {
	name: string;
}

interface Asset {
	id: string;
	manufacturer?: string;
	model?: Record<string, any>;
	serial_number?: string;
	organization_asset_type?: OrganizationAssetType | string;
	condition?: number | string;
	photos?: AssetPhoto[];
	unique_fields?: Record<string, any>;
	refrigeration_details?: Record<string, any>;
	electrical_details?: Record<string, any>;
	warranty?: Record<string, any>;
	[key: string]: any; // fallback for unknown fields
}

export interface SubmissionData {
	asset?: Asset;
	assetAiDataMap?: Partial<Asset>; // optional AI data mapping
	[key: string]: any;
}

/** Utility to prettify labels, e.g. "model_number" => "Model Number" */
function formatLabel(key: string): string {
	return key
		.replace(/([a-z])([A-Z])/g, "$1 $2") // split camelCase into words
		.replace(/_/g, " ") // replace underscores with spaces
		.replace(/\b\w/g, (l) => l.toUpperCase()) // capitalize each word
		.trim();
}

/** Map of numeric condition => user-friendly text */
export const conditionMap: Record<string, string> = {
	"1": "BROKEN",
	"2": "POOR",
	"3": "FAIR",
	"4": "GOOD",
	"5": "EXCELLENT",
};

const reverseConditionMap: Record<string, string> = {
	broken: "1",
	poor: "2",
	fair: "3",
	good: "4",
	excellent: "5",
};

function interpretCondition(val: number | string): string | null {
	if (val == null) return null;

	// If already numeric (e.g. number or digit-only string), do a straightforward lookup
	if (typeof val === "number" || /^[0-9]+$/.test(String(val))) {
		const mapped = conditionMap[String(val)];
		return mapped ?? null; // if not in map, return null
	}

	// Else if textual => see if it matches something in reverseMap
	const lower = String(val).toLowerCase();
	if (reverseConditionMap[lower]) {
		// e.g. "broken" => "1" => "Broken"
		const numericKey = reverseConditionMap[lower];
		return conditionMap[numericKey] ?? null;
	}

	// If we can't interpret it, fallback to null
	return null;
}

/**
 * Fields to hide entirely
 */
const filteredKeys = new Set<string>([
	"id",
	"created_timestamp",
	"deleted_by",
	"form_id",
	"organization_id",
	"model_id",
	"organization_asset_type_id",
	"organization_equipment_area_id",
	"organization_equipment_sub_area_id",
	"submitting_user_id",
	"model_number",
	"location_id",
	"qa_flag",
	"external_firebase_id",
	"date_of_birth",
]);

function isFilteredKey(key: string): boolean {
	if (filteredKeys.has(key)) return true;

	// Additional patterns
	if (key.includes("not_found")) return true;
	if (key.startsWith("is_")) return true;
	if (key.startsWith("has_")) return true;
	if (key.startsWith("asset_ai_")) return true;

	return false;
}

/**
 * Once we decide which value to show (DB vs. AI),
 * this formats it for display (date => "YYYY-MM-DD", arrays => JSON, etc.)
 */
function formatValue(value: any, fieldName: string) {
	// 1) If Date object
	if (value instanceof Date) {
		return dayjs(value).format("YYYY-MM-DD");
	}

	// 2) If date string
	if (
		typeof value === "string" &&
		(value.match(/^\d{4}-\d{2}-\d{2}/) || value.endsWith("Z"))
	) {
		const parsed = dayjs(value);
		return parsed.isValid() ? parsed.format("YYYY-MM-DD") : value;
	}

	// 3) If array
	if (Array.isArray(value)) {
		if (fieldName === "photos") {
			return (
				<div className="d-flex flex-row flex-wrap gap-3">
					{value.map((p: any) => (
						<div key={`${p.id}-${p.type}`} className="text-center">
							<Image
								src={p.url}
								alt={p.type}
								className="img-fluid"
								style={{ maxWidth: "150px" }}
								preview={{ destroyOnClose: true }}
							/>
							<div className="mt-2">
								<strong>{formatLabel(p.type)}</strong>
							</div>
						</div>
					))}
				</div>
			);
		}
		// Otherwise, just JSON-stringify
		return JSON.stringify(value);
	}

	// 4) If object => fallback to JSON
	if (typeof value === "object" && value !== null) {
		return JSON.stringify(value);
	}

	// 5) fallback => string
	return String(value);
}

/**
 * If DB has something, show it.
 * If DB is missing, but AI has a value => show AI fallback + <GeneratedPropIcon />.
 * Else => "N/A".
 */
function renderFieldValue(dbValue: any, aiValue: any, fieldName: string) {
	// If DB is present, use DB
	if (dbValue !== null && dbValue !== undefined) {
		return formatValue(dbValue, fieldName);
	}
	// If DB is missing but AI is present => fallback
	if (aiValue !== null && aiValue !== undefined) {
		return (
			<Typography.Text type="secondary" italic>
				{formatValue(aiValue, fieldName)}{" "}
				<GeneratedPropIcon message="Backfilled via FacilityAI" />
			</Typography.Text>
		);
	}
	// If both are missing => "N/A"
	return "N/A";
}

/**
 * For nested "unique_fields" or other deep JSON, we recursively display them.
 */
function renderNestedValue(val: any): React.ReactNode {
	if (val === null || val === undefined) return "N/A";

	if (typeof val === "object" && !Array.isArray(val)) {
		const subItems = Object.keys(val).map((subKey) => (
			<Descriptions.Item key={subKey} label={formatLabel(subKey)}>
				{renderNestedValue(val[subKey])}
			</Descriptions.Item>
		));
		return (
			<Descriptions
				bordered
				column={1}
				labelStyle={{ fontWeight: "bold", width: "30%" }}
				style={{ marginBottom: "1rem" }}
			>
				{subItems}
			</Descriptions>
		);
	}

	if (Array.isArray(val)) {
		if (val.length === 0) return "[]";
		return val.map((item, idx) => (
			<div key={idx} style={{ marginLeft: "1rem" }}>
				{renderNestedValue(item)}
			</div>
		));
	}

	return String(val);
}

/**
 * Main renderer function.
 * Accepts a single `data` object that contains BOTH the asset
 * and the optional `assetAiDataMap`.
 * Preserves existing logic & special handling for condition, model, etc.,
 * and uses <GeneratedPropIcon /> for any AI fallback.
 */
export function renderAssetDetails(data: SubmissionData) {
	const { asset, submission_asset_ai_data_map = {}, location } = data;
	if (!asset) return null;

	const items: React.ReactNode[] = [];

	// We keep the original ordered fields plus everything else from the asset
	const orderedFields = [
		"submission_id",
		"asset_tag",
		"manufacturer",
		"model",
		"serial_number",
		"organization_asset_type",
		"location_in_facility",
		"manufacturer_date",
		"install_date",
		"condition",
		"warranty",
		"photos",
		"refrigeration_details",
		"electrical_details",
		"general_notes",
		"unique_fields",
	];

	const allKeys = new Set([...orderedFields, ...Object.keys(asset)]);

	// Refactor: for-of instead of forEach
	for (const key of allKeys) {
		if (isFilteredKey(key)) continue;

		const dbValue = asset[key];
		const aiValue = submission_asset_ai_data_map[key];

		// If both are truly empty => skip
		if (
			(dbValue === null || dbValue === undefined) &&
			(aiValue === null || aiValue === undefined)
		) {
			continue;
		}

		// 1) special handling: condition => numeric => text
		if (key === "condition") {
			// Attempt to interpret both DB & AI values
			const dbCondition = interpretCondition(dbValue);
			const aiCondition = interpretCondition(aiValue);

			items.push(
				<Descriptions.Item key={key} label={formatLabel(key)}>
					{renderFieldValue(dbCondition, aiCondition, key)}
				</Descriptions.Item>,
			);

			continue;
		}

		// 2) manufacturer_date => parse both DB & AI to string => fallback if empty
		if (key === "manufacturer_date") {
			const dbDateStr = dbValue ? parseDateToUTCString(dbValue) : null;
			const aiDateStr = aiValue ? parseDateToUTCString(aiValue) : null;

			items.push(
				<Descriptions.Item key={key} label={formatLabel(key)}>
					{renderFieldValue(dbDateStr, aiDateStr, key)}
				</Descriptions.Item>,
			);
			continue;
		}

		// 3) organization_asset_type => might be an object with .name
		if (key === "organization_asset_type" && typeof dbValue === "object") {
			const dbName = dbValue?.name;
			const aiName = (aiValue as any)?.name;
			items.push(
				<Descriptions.Item key={key} label={formatLabel(key)}>
					{renderFieldValue(dbName, aiName, "organization_asset_type.name")}
				</Descriptions.Item>,
			);
			continue;
		}

		// 3) location_in_facility => might be an object with .name
		if (key === "location_in_facility" && typeof dbValue === "object") {
			const dbName = dbValue?.name;
			const aiName = (aiValue as any)?.name;
			items.push(
				<Descriptions.Item key={key} label={formatLabel(key)}>
					{renderFieldValue(dbName, aiName, "location_in_facility.name")}
				</Descriptions.Item>,
			);
			continue;
		}

		// 4) model => might have subfields like .manufacturer => .name, and .model_number
		if (key === "model" && dbValue && typeof dbValue === "object") {
			const dbManufacturer = dbValue.manufacturer?.name;
			const aiManufacturer = (aiValue as any)?.manufacturer?.name;

			items.push(
				<Descriptions.Item key="model-manufacturer" label="Manufacturer">
					{renderFieldValue(dbManufacturer, aiManufacturer, "manufacturer")}
				</Descriptions.Item>,
			);

			const dbModelNum = dbValue.model_number;
			const aiModelNum = (aiValue as any)?.model_number;
			items.push(
				<Descriptions.Item key="model-model_number" label="Model Number">
					{renderFieldValue(dbModelNum, aiModelNum, "model.model_number")}
				</Descriptions.Item>,
			);
			continue;
		}

		// 5) known objects: refrigeration_details, electrical_details, warranty
		const complexKeys = [
			"refrigeration_details",
			"electrical_details",
			"warranty",
		];
		if (
			complexKeys.includes(key) &&
			typeof dbValue === "object" &&
			dbValue !== null
		) {
			const subFields = Object.keys(dbValue);
			// Refactor: for-of instead of forEach
			for (const subKey of subFields) {
				if (subKey === "asset" || subKey === "asset_id") continue;

				const subDbVal = dbValue[subKey];
				const subAiVal = aiValue && (aiValue as any)[subKey];
				// If both are empty => skip
				if (
					(subDbVal === null || subDbVal === undefined) &&
					(subAiVal === null || subAiVal === undefined)
				) {
					continue;
				}

				items.push(
					<Descriptions.Item
						key={`${key}-${subKey}`}
						label={`${formatLabel(subKey)}`}
					>
						{renderFieldValue(subDbVal, subAiVal, `${key}.${subKey}`)}
					</Descriptions.Item>,
				);
			}
			continue;
		}

		// 6) unique_fields => render each key-value pair as its own Descriptions.Item
		if (key === "unique_fields") {
			let parsed = dbValue;
			if (typeof dbValue === "string") {
				try {
					parsed = JSON.parse(dbValue);
				} catch {
					// If parse fails, fallback to raw string
				}
			}

			// If parsed is an object, iterate over its keys
			if (typeof parsed === "object" && parsed !== null) {
				for (const subKey of Object.keys(parsed)) {
					const subValue = parsed[subKey];

					// Special handling for nested fields like install_date.formattedDate
					if (subKey === "installDate" && typeof subValue === "object") {
						const formattedDate = subValue.formatted;
						if (formattedDate) {
							items.push(
								<Descriptions.Item
									key={`unique_field-${subKey}`}
									label="Install Date"
								>
									{renderNestedValue(formattedDate)}
								</Descriptions.Item>,
							);
						}
						continue; // Skip rendering other subkeys for install_date
					}

					// Default behavior for other keys
					items.push(
						<Descriptions.Item
							key={`unique_field-${subKey}`}
							label={formatLabel(subKey)}
						>
							{renderNestedValue(subValue)}
						</Descriptions.Item>,
					);
				}
			}

			// Skip adding the "unique_fields" label itself
			continue;
		}

		// 7) fallback => normal field
		items.push(
			<Descriptions.Item key={key} label={formatLabel(key)}>
				{renderFieldValue(dbValue, aiValue, key)}
			</Descriptions.Item>,
		);
	}
	// Add the location item as the first element
	if (location) {
		const { google_address, address1, id } = location;
		items.unshift(
			<Descriptions.Item key="location" label="Location">
				{google_address || address1 || id || "N/A"}
			</Descriptions.Item>,
		);
	}

	return (
		<Descriptions
			bordered
			column={1}
			labelStyle={{ fontWeight: "bold", width: "30%" }}
			className="mb-4"
		>
			{items}
		</Descriptions>
	);
}
