import { DB_ORG } from "@/constants/db";
import { Avatar } from "@/layouts/signed-in/Avatar/Avatar";
import { IUser, UserAccessLevel } from "@/stores/user-store";
import { Collapse } from "antd";
import type { FC } from "react";
import { useReducer, useRef } from "react";
import { AiOutlineDashboard, AiOutlineUser } from "react-icons/ai";
import { BiLogOut } from "react-icons/bi";
import { GiTwoCoins } from "react-icons/gi";
import { IoMdArrowDropup } from "react-icons/io";
import { RiAdminLine } from "react-icons/ri";
import { TbMap2, TbReport } from "react-icons/tb";
import { NavLink } from "react-router-dom";
import { OrgDropdown } from "./OrgDropdown";

//
// Rather than passing separate values, we now pass a complete user object.
// The Sidebar can derive the user's name, role, organization, etc., directly.
//
export interface SidebarProps {
	isSidebarOpen: boolean;
	user: IUser;
	updateUserOrg: (externalFirebaseId: string, fullName: string) => void;
	logout: () => Promise<void>;
}

//
// Using a reducer for the avatar-collapse state with two actions: toggle and close.
//
type SidebarState = {
	openAvatar: boolean;
};

type SidebarAction = { type: "TOGGLE_AVATAR" } | { type: "CLOSE_AVATAR" };

const sidebarReducer = (
	state: SidebarState,
	action: SidebarAction,
): SidebarState => {
	switch (action.type) {
		case "TOGGLE_AVATAR":
			return { ...state, openAvatar: !state.openAvatar };
		case "CLOSE_AVATAR":
			return { ...state, openAvatar: false };
		default:
			return state;
	}
};

//
// The Sidebar component derives all the display data from the passed-in user object.
//
export const Sidebar: FC<SidebarProps> = ({
	isSidebarOpen,
	user,
	updateUserOrg,
	logout,
}) => {
	const [state, dispatch] = useReducer(sidebarReducer, { openAvatar: false });
	const wrapper = useRef<HTMLDivElement>(null);

	// Derive necessary values from the user object.
	const name = user.name;

	return (
		<nav
			className={`sidebar ${isSidebarOpen ? "sidebar-expanded" : ""} d-md-flex flex-md-column position-fixed`}
			ref={wrapper}
		>
			<div className="user">
				<div className="photo">
					<Avatar alt="Avatar" className="avatar" />
				</div>
				<div className="info sidebar-user-info-wrapper">
					<span
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								dispatch({ type: "TOGGLE_AVATAR" });
							}
						}}
						style={{ color: "white", cursor: "pointer" }}
						onClick={() => dispatch({ type: "TOGGLE_AVATAR" })}
					>
						<div className="sidebar-username-wrapper">
							<div
								className={`sidebar-username ${!isSidebarOpen ? "hidden" : ""}`}
							>
								{name}
							</div>
							<div
								className={
									state.openAvatar
										? "arrow-icon-up-wrapper"
										: "arrow-icon-down-wrapper"
								}
							>
								<IoMdArrowDropup className="arrow-icon" />
							</div>
						</div>
					</span>
					<Collapse activeKey={state.openAvatar ? ["avatar"] : []} ghost>
						<Collapse.Panel key="avatar" showArrow={false} header={null}>
							<ul className="nav">
								<li
									className={`sidebar-normal ${!isSidebarOpen ? "sidebar-normal-collapsed" : ""}`}
								>
									<NavLink
										to="/profile"
										className={({ isActive }) =>
											isActive ? "active" : "inactive"
										}
										onClick={() => dispatch({ type: "CLOSE_AVATAR" })}
									>
										<div className="sidebar-item-wrapper">
											<div
												className="sidebar-mini-icon"
												style={{ transform: "translate(1px, 4px)" }}
											>
												<AiOutlineUser />
											</div>
											<div
												className={`sidebar-profile-item ${!isSidebarOpen ? "hidden" : ""}`}
											>
												My Profile
											</div>
										</div>
										<div
											className={`blend-effect ${isSidebarOpen ? "blend-effect-expanded" : ""}`}
										/>
										<div className="blend-effect-top" />
									</NavLink>
								</li>
								<li
									className={`sidebar-normal log-out ${!isSidebarOpen ? "sidebar-normal-collapsed" : ""}`}
								>
									<div
										className="inactive"
										onClick={logout}
										typeof="button"
										// biome-ignore lint/a11y/noNoninteractiveTabindex: <explanation>
										tabIndex={0}
										onKeyDown={(e) => e.key === "Enter" && logout()}
									>
										<div className="sidebar-mini-icon log-out-icon">
											<BiLogOut />
										</div>
										<div
											className={`sidebar-profile-item log-out-text ${!isSidebarOpen ? "hidden" : ""}`}
										>
											Log Out
										</div>
									</div>
								</li>
							</ul>
						</Collapse.Panel>
					</Collapse>
				</div>
			</div>
			<hr className="text-white" />
			<ul className="nav nav-topics">
				<li
					className={`sidebar-normal ${!isSidebarOpen ? "sidebar-normal-collapsed" : ""}`}
				>
					<NavLink
						to="/"
						className={({ isActive }) => (isActive ? "active" : "inactive")}
						onClick={() => dispatch({ type: "CLOSE_AVATAR" })}
					>
						<div className="sidebar-mini-icon">
							<AiOutlineDashboard />
						</div>
						<div
							className={`sidebar-topic-item ${!isSidebarOpen ? "hidden" : ""}`}
						>
							Dashboard
						</div>
						<div
							className={`blend-effect ${isSidebarOpen ? "blend-effect-expanded" : ""}`}
						/>
					</NavLink>
				</li>
				<li
					className={`sidebar-normal ${!isSidebarOpen ? "sidebar-normal-collapsed" : ""}`}
				>
					<NavLink
						to="/locations"
						className={({ isActive }) => (isActive ? "active" : "inactive")}
						onClick={() => dispatch({ type: "CLOSE_AVATAR" })}
					>
						<div className="sidebar-mini-icon">
							<TbMap2 />
						</div>
						<div
							className={`sidebar-topic-item ${!isSidebarOpen ? "hidden" : ""}`}
						>
							Locations
						</div>
						<div
							className={`blend-effect ${isSidebarOpen ? "blend-effect-expanded" : ""}`}
						/>
					</NavLink>
				</li>
				<li
					className={`sidebar-normal ${!isSidebarOpen ? "sidebar-normal-collapsed" : ""}`}
				>
					<NavLink
						to="/forms"
						className={({ isActive }) => (isActive ? "active" : "inactive")}
						onClick={() => {
							dispatch({ type: "CLOSE_AVATAR" });
						}}
					>
						<div className="sidebar-mini-icon">
							<TbReport />
						</div>
						<div
							className={`sidebar-topic-item ${!isSidebarOpen ? "hidden" : ""}`}
						>
							Data Capture
						</div>
						<div
							className={`blend-effect ${isSidebarOpen ? "blend-effect-expanded" : ""}`}
						/>
					</NavLink>
				</li>
				{user?.organization?.external_firebase_id !==
					DB_ORG.CUSHMANWAKEFIELD && (
					<li
						className={`sidebar-normal ${!isSidebarOpen ? "sidebar-normal-collapsed" : ""}`}
					>
						<NavLink
							to="/predictive-spend"
							className={({ isActive }) => (isActive ? "active" : "inactive")}
							onClick={() => dispatch({ type: "CLOSE_AVATAR" })}
						>
							<div className="sidebar-mini-icon">
								<GiTwoCoins />
							</div>
							<div
								className={`sidebar-topic-item ${!isSidebarOpen ? "hidden" : ""}`}
							>
								Predictive Spend
							</div>
							<div
								className={`blend-effect ${isSidebarOpen ? "blend-effect-expanded" : ""}`}
							/>
						</NavLink>
					</li>
				)}
				{user?.role?.access_level >= UserAccessLevel.ADMIN && (
					<li
						className={`sidebar-normal ${!isSidebarOpen ? "sidebar-normal-collapsed" : ""}`}
					>
						<NavLink
							to="/admin"
							className={({ isActive }) => (isActive ? "active" : "inactive")}
							onClick={() => dispatch({ type: "CLOSE_AVATAR" })}
						>
							<div className="sidebar-mini-icon">
								<RiAdminLine />
							</div>
							<div
								className={`sidebar-topic-item ${!isSidebarOpen ? "hidden" : ""}`}
							>
								Administration
							</div>
							<div
								className={`blend-effect ${isSidebarOpen ? "blend-effect-expanded" : ""}`}
							/>
						</NavLink>
					</li>
				)}
			</ul>
			{isSidebarOpen && (
				<div className="w-100 d-flex justify-content-center">
					<OrgDropdown
						user={user}
						updateUserOrg={updateUserOrg}
						options={user.allowed_organizations}
					/>
				</div>
			)}
		</nav>
	);
};
