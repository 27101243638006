import { type IForm } from "@/stores/user-store";
import { Select } from "antd";
import { type FC, useCallback, useEffect, useMemo } from "react";

interface FormSelectProps {
	forms: IForm[];
	selectedFormId: number | undefined;
	isLoading: boolean;
	onFormChange: (formId: number) => void;
}

const FormSelect: FC<FormSelectProps> = ({
	forms,
	selectedFormId,
	isLoading,
	onFormChange,
}) => {
	const options = useMemo(
		() =>
			forms
				.filter((form) => form.enabled)
				?.map((form) => ({
					value: form?.form_id,
					label: form?.config.formName,
				}))
				?.sort((a, b) => a.value - b.value),
		[forms],
	);

	const handleChange = useCallback(
		(value: number) => {
			onFormChange(value);
		},
		[onFormChange],
	);

	// If no form is selected and we have forms, select the first one
	useEffect(() => {
		if (!selectedFormId && forms?.length > 0 && !isLoading) {
			const defaultForm =
				forms.find(
					(form) => form?.config?.formName === "Inventory & Tagging Form",
				)?.form_id || forms[0]?.form_id;
			if (defaultForm) {
				onFormChange(defaultForm);
			}
		}
	}, [selectedFormId, forms, isLoading, onFormChange]);

	return (
		<Select
			style={{ width: 260 }}
			value={selectedFormId}
			onChange={handleChange}
			options={options}
			placeholder={isLoading ? "Loading forms..." : "Select a form"}
			loading={isLoading}
			disabled={isLoading || forms?.length === 0}
		/>
	);
};

export { FormSelect };
