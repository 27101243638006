import { useLogout } from "@/hooks/auth-queries";
import {
	BankOutlined,
	EnvironmentOutlined,
	FormOutlined,
	LogoutOutlined,
	MenuFoldOutlined,
	MenuOutlined,
	MenuUnfoldOutlined,
	SettingOutlined,
	UserOutlined,
	WalletOutlined,
	WifiOutlined,
} from "@ant-design/icons";
import logo from "@assets/img/FacilityAI_Logo.png";
import type { MenuProps } from "antd";
import { Button, Dropdown, Layout, Space, Typography, message } from "antd";
import type { FC, ReactNode } from "react";
import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Alerts } from "./children/Alerts";
import "./Navbar.scss";
import { DB_ORG } from "@/constants/db";
import { useUserStore } from "@/stores";

const { Header } = Layout;
const { Text } = Typography;

interface NavbarProps {
	isSidebarOpen: boolean;
	setIsSidebarOpen: (isOpen: boolean) => void;
}

interface MenuItem {
	key: string;
	icon: ReactNode;
	label: string;
	path: string;
}

type MenuItemType = Required<MenuProps>["items"][number];

const Navbar: FC<NavbarProps> = ({ isSidebarOpen, setIsSidebarOpen }) => {
	const [isOffline, setIsOffline] = useState(false);
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
	const logout = useLogout();
	const location = useLocation();
	const { user } = useUserStore();

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 768);
		};

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	useEffect(() => {
		const handleOnline = () => setIsOffline(false);
		const handleOffline = () => setIsOffline(true);

		window.addEventListener("online", handleOnline);
		window.addEventListener("offline", handleOffline);

		return () => {
			window.removeEventListener("online", handleOnline);
			window.removeEventListener("offline", handleOffline);
		};
	}, []);

	const firebaseConfig = JSON.parse(
		import.meta.env.VITE_REACT_APP_FIREBASE_CONFIG,
	);
	const isStaging =
		firebaseConfig.projectId.includes("staging") ||
		import.meta.env.VITE_ENV === "staging";

	const handleLogout = async () => {
		try {
			const result = await logout();
			if (result.success) {
				message.success("Logged out successfully");
			} else {
				message.error(result.error || "Failed to logout");
			}
		} catch (error) {
			console.error("Error during logout:", error);
			message.error("An error occurred during logout");
		}
	};

	const menuItems: MenuItem[] = [
		{ key: "dashboard", icon: <BankOutlined />, label: "Dashboard", path: "/" },
		{
			key: "profile",
			icon: <UserOutlined />,
			label: "My Profile",
			path: "/profile",
		},
		{
			key: "locations",
			icon: <EnvironmentOutlined />,
			label: "Locations",
			path: "/locations",
		},
		{
			key: "forms",
			icon: <FormOutlined />,
			label: "Data Capture",
			path: "/forms",
		},
		...(user?.organization?.external_firebase_id !== DB_ORG.CUSHMANWAKEFIELD
			? [
					{
						key: "predictive-spend",
						icon: <WalletOutlined />,
						label: "Predictive Spend",
						path: "/predictive-spend",
					},
				]
			: []),
		{
			key: "admin",
			icon: <SettingOutlined />,
			label: "Administration",
			path: "/admin",
		},
	];

	const menuProps: MenuProps = {
		selectedKeys: [location.pathname],
		items: [
			...menuItems.map((item) => ({
				key: item.path,
				icon: item.icon,
				label: <NavLink to={item.path}>{item.label}</NavLink>,
			})),
			{
				type: "divider",
			},
			{
				key: "logout",
				icon: <LogoutOutlined />,
				label: (
					<span
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								handleLogout();
							}
						}}
						onClick={handleLogout}
					>
						Log Out
					</span>
				),
				danger: true,
			},
		] as MenuItemType[],
	};

	return (
		<>
			<Header className="navbar my-4">
				<div className="navbar-content">
					<div className="navbar-left">
						{!isMobile && (
							<Button
								type="text"
								color="primary"
								icon={
									isSidebarOpen ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />
								}
								onClick={() => setIsSidebarOpen(!isSidebarOpen)}
								className="trigger-button"
							/>
						)}
						<NavLink to="/" className="logo-link">
							<img src={logo} alt="FacilityAI Logo" className="logo" />
						</NavLink>
					</div>

					<div className="navbar-right">
						<Space size={isMobile ? 8 : 16}>
							<div className="alerts-container">
								<Alerts />
							</div>
							{isMobile && (
								<Dropdown
									menu={menuProps}
									trigger={["click"]}
									placement="bottomRight"
								>
									<Button
										color="primary"
										type="text"
										icon={<MenuOutlined />}
										className="menu-button"
									/>
								</Dropdown>
							)}
						</Space>
					</div>
				</div>
			</Header>

			{isStaging && (
				<div className="staging-banner mb-2">
					<Text strong>STAGING ENVIRONMENT</Text>
				</div>
			)}

			{isOffline && (
				<div className="offline-banner">
					<Space>
						<WifiOutlined />
						<Text>Connection Lost!</Text>
					</Space>
				</div>
			)}
		</>
	);
};

export { Navbar };
