import { Loader } from "@/layouts/signed-in/Loader/Loader";
import { DynamicSubmissionDetail } from "@/layouts/signed-in/views/SingleLocation/SubmissionsTab/children/DynamicSubmissionDetail";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const SubmissionDetail = () => {
	const { submissionId } = useParams<{ submissionId: string }>();
	const location = useLocation();
	const navigate = useNavigate();

	const toggleSubmission = () => {
		const isAdminView: boolean = location.pathname.includes("admin");
		const pathName = location.pathname;
		const locationId = pathName.split("/")[2];
		navigate(
			isAdminView
				? "/admin/submissions"
				: `/locations/${locationId}/submissions`,
			{
				state: {
					...location?.state,
					submissionsTableParams: {
						...location?.state?.submissionsTableParams,
						selectedForm: location?.state?.formId,
					},
				},
			},
		);
	};

	if (!submissionId) {
		return <Loader />;
	}

	// Render the appropriate submission component based on the organization
	const renderSubmissionComponent = () => {
		return (
			<DynamicSubmissionDetail
				submissionId={submissionId}
				formId={location.state?.formId || 3}
				toggleSubmission={toggleSubmission}
			/>
		);
	};

	return <div>{renderSubmissionComponent()}</div>;
};

export { SubmissionDetail };
