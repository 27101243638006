import { storage } from "@/services/auth-service";
import {
	getDownloadURL,
	ref,
	uploadBytes,
	uploadBytesResumable,
	uploadString,
} from "firebase/storage";

export const uploadFieldCaptureFromDataUrl = async (dataUrl: string) => {
	// Generate a reference to a new location in the Storage with the file name
	const imageRef = ref(
		storage,
		`FieldCaptures/${new Date().toISOString()}.webp`,
	);
	// Upload the file to that reference
	const upload = await uploadString(imageRef, dataUrl, "data_url");

	// Return download URL
	const url = await getDownloadURL(upload.ref);
	return url;
};

export const uploadFieldCaptureFromFileOld = async (
	file: ArrayBuffer | Blob | Uint8Array,
) => {
	// Generate a reference to a new location in the Storage with the file name
	const imageRef = ref(
		storage,
		`FieldCaptures/${new Date().toISOString()}.webp`,
	);

	// Specify the content type as "image/webp"
	const metadata = {
		contentType: "image/webp",
	};

	// Upload the file to that reference with metadata
	const upload = await uploadBytes(imageRef, file, metadata);

	// Return download URL
	const url = await getDownloadURL(upload.ref);
	return url;
};

export const uploadFieldCaptureFromFile = async (
	file: ArrayBuffer | Blob | Uint8Array,
) => {
	// Generate a reference to a new location in the Storage with the file name
	const imageRef = ref(
		storage,
		`FieldCaptures/${new Date().toISOString()}.webp`,
	);

	// Specify the content type as "image/jpg"
	const metadata = {
		contentType: "image/webp",
	};

	// Upload the file to that reference with metadata
	const uploadTask = uploadBytesResumable(imageRef, file, metadata);

	// Create a timeout to cancel the upload if it takes longer than 30 seconds
	let canceled = false;
	const timeoutId = setTimeout(() => {
		canceled = true;
		uploadTask.cancel();
	}, 30000);

	return new Promise((resolve, reject) => {
		uploadTask.on(
			"state_changed",
			(snapshot) => {
				const progress =
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				// onProgress && onProgress(progress); // Call the onProgress callback with the progress
				console.log(progress);
			},
			(error) => {
				console.log("error here");
				clearTimeout(timeoutId); // Clear the timeout when an error occurs
				if (canceled) {
					// onCancelled && onCancelled(); // Call the onCancelled callback if the upload was cancelled
					const err = new Error();
					err.name = "canceled";
					reject(err);
				} else {
					// onError && onError(error); // Call the onError callback for other errors
					console.log(error);
					reject(error);
				}
			},
			async () => {
				clearTimeout(timeoutId); // Clear the timeout when the upload completes successfully
				// Upload completed successfully, now we can get the download URL
				const url = await getDownloadURL(uploadTask.snapshot.ref);
				resolve(url);
			},
		);
	});
};
