import { auth } from "@/services/auth-service";
import { onAuthStateChanged } from "firebase/auth";
import { createContext, useContext, useEffect, useState } from "react";

interface AuthContextType {
	isAuthInitialized: boolean;
}

const AuthContext = createContext<AuthContextType>({
	isAuthInitialized: false,
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
	const [isAuthInitialized, setIsAuthInitialized] = useState(false);

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, () => {
			setIsAuthInitialized(true);
		});

		return () => unsubscribe();
	}, []);

	return (
		<AuthContext.Provider value={{ isAuthInitialized }}>
			{children}
		</AuthContext.Provider>
	);
};
