import { ALFRED_SERVICE_URL } from "@/constants/env";
import { auth } from "@/services/auth-service";
import { Alert, Button, Col, Row, message } from "antd";
import { type FC, useEffect, useReducer } from "react";

import { CostSavingsCard } from "@/layouts/signed-in/views/Dashboard/CostSavingsCard/CostSavingsCard";
import { StatsCard } from "@/layouts/signed-in/views/Dashboard/StatsCard/StatsCard";

// Types from aggregator
// Update the interface to include category data
interface CategoryData {
	count: number;
	savings: number;
}

interface State {
	data: AnalyticsResponse | null;
	isLoading: boolean;
	error: string | null;
	monthOptions: string[];
	activeMonth: string | null;
	startDate: Date;
	endDate: Date;
}

interface AnalyticsResponse {
	submissions: number;
	costSavings: number;
	absorbedTickets: number;
	absorbedSavings: number;
	submissionsTrend: number;
	preventedTrend: number;
	absorbedTicketsTrend: number;
	absorbedSavingsTrend: number;
	avgSubmissions3mo: number;
	avgPrevented3mo: number;
	avgAbsorbedCount3mo: number;
	avgAbsorbedSavings3mo: number;
	categoryData: {
		ceiling: CategoryData;
		door: CategoryData;
		flooring: CategoryData;
		lighting: CategoryData;
		millwork: CategoryData;
		plumbing: CategoryData;
		wallPaint: CategoryData;
	};
}

type Action =
	| { type: "SET_DATA"; payload: AnalyticsResponse | null }
	| { type: "SET_LOADING"; payload: boolean }
	| { type: "SET_ERROR"; payload: string | null }
	| { type: "SET_MONTH_OPTIONS"; payload: string[] }
	| { type: "SET_ACTIVE_MONTH"; payload: string | null }
	| { type: "SET_START_DATE"; payload: Date }
	| { type: "SET_END_DATE"; payload: Date };

function reducer(state: State, action: Action): State {
	switch (action.type) {
		case "SET_DATA":
			return { ...state, data: action.payload };
		case "SET_LOADING":
			return { ...state, isLoading: action.payload };
		case "SET_ERROR":
			return { ...state, error: action.payload };
		case "SET_MONTH_OPTIONS":
			return { ...state, monthOptions: action.payload };
		case "SET_ACTIVE_MONTH":
			return { ...state, activeMonth: action.payload };
		case "SET_START_DATE":
			return { ...state, startDate: action.payload };
		case "SET_END_DATE":
			return { ...state, endDate: action.payload };
		default:
			return state;
	}
}

function getMonthOptions(): string[] {
	// For example, last 12 months
	const options: string[] = [];
	const now = new Date();
	for (let i = 0; i < 12; i++) {
		const d = new Date(now.getFullYear(), now.getMonth() - i, 1);
		const monthName = d.toLocaleString("default", { month: "long" });
		const year = d.getFullYear();
		options.push(`${monthName} ${year}`);
	}
	return options;
}

function parseMonthString(str: string): Date {
	// e.g. "January 2024"
	const [monthStr, yearStr] = str.split(" ");
	const months = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];
	const monthIndex = months.indexOf(monthStr);
	const year = parseInt(yearStr, 10);
	return new Date(year, monthIndex, 1, 0, 0, 0, 0);
}

async function fetchWithAuth(url: string) {
	try {
		const token = await auth?.currentUser?.getIdToken();
		if (!token) {
			throw new Error("Authentication token not available");
		}

		const res = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		if (res.status === 403) {
			throw new Error("You don't have permission to access this data");
		}

		if (!res.ok) {
			const errorData = await res.json().catch(() => ({}));
			throw new Error(
				errorData.detail || `Failed to fetch data: ${res.statusText}`,
			);
		}

		return res.json();
	} catch (error) {
		if (error instanceof Error) {
			throw new Error(`Failed to fetch data: ${error.message}`);
		}
		throw error;
	}
}

export const CWDashboard: FC = () => {
	// 1) Setup default date range for current month
	const now = new Date();
	const defaultStart = new Date(
		now.getFullYear(),
		now.getMonth(),
		1,
		0,
		0,
		0,
		0,
	);
	const defaultEnd = new Date(
		now.getFullYear(),
		now.getMonth() + 1,
		1,
		0,
		0,
		0,
		0,
	);

	// 2) Initialize state
	const initialState: State = {
		data: null,
		isLoading: true,
		error: null,
		monthOptions: [],
		activeMonth: null,
		startDate: defaultStart,
		endDate: defaultEnd,
	};
	const [state, dispatch] = useReducer(reducer, initialState);
	const {
		data,
		isLoading,
		error,
		monthOptions,
		activeMonth,
		startDate,
		endDate,
	} = state;

	// 3) Build month options once
	useEffect(() => {
		const opts = getMonthOptions();
		dispatch({ type: "SET_MONTH_OPTIONS", payload: opts });
	}, []);

	// 4) If we have month options but no activeMonth, set default
	useEffect(() => {
		if (monthOptions.length > 0 && !activeMonth) {
			dispatch({ type: "SET_ACTIVE_MONTH", payload: monthOptions[0] });
		}
	}, [monthOptions, activeMonth]);

	// 5) Fetch aggregator data whenever start/end changes
	useEffect(() => {
		if (!startDate || !endDate) return;

		const fetchData = async () => {
			dispatch({ type: "SET_LOADING", payload: true });
			dispatch({ type: "SET_ERROR", payload: null });

			try {
				const url = `${ALFRED_SERVICE_URL}/cushman/analytics?start=${startDate.toISOString()}&end=${endDate.toISOString()}`;
				const json = (await fetchWithAuth(url)) as AnalyticsResponse;

				// Validate the response data
				if (!json || typeof json.submissions !== "number") {
					throw new Error("Invalid data received from server");
				}

				dispatch({ type: "SET_DATA", payload: json });
			} catch (err: any) {
				console.error("Failed to load aggregator data:", err);
				const errorMessage = err.message || "Error loading data";
				dispatch({ type: "SET_ERROR", payload: errorMessage });

				// Show different error messages based on error type
				if (errorMessage.includes("Authentication")) {
					message.error("Please log in again to access this data.");
				} else if (errorMessage.includes("permission")) {
					message.error("You don't have permission to view this data.");
				} else {
					message.error(
						"Failed to load analytics data. Please try again later.",
					);
				}
			} finally {
				dispatch({ type: "SET_LOADING", payload: false });
			}
		};

		fetchData();
	}, [startDate, endDate]);

	// 6) Handle month pick
	const onMonthChange = (value: string) => {
		// user picks new month -> parse the date range -> fetch
		const start = parseMonthString(value);
		const end = new Date(start.getFullYear(), start.getMonth() + 1, 1);
		dispatch({ type: "SET_START_DATE", payload: start });
		dispatch({ type: "SET_END_DATE", payload: end });
		dispatch({ type: "SET_ACTIVE_MONTH", payload: value });
	};

	// 7) Render everything (the card and stats) at once
	//    Each child gets isLoading => so it can show skeleton
	return (
		<div className="dashboard h-100 w-100">
			{error && (
				<Alert
					className="mb-4"
					message="Error Loading Analytics"
					description={error}
					type="error"
					showIcon
					action={
						<Button
							type="primary"
							onClick={() => {
								dispatch({ type: "SET_ERROR", payload: null });
								// Retry fetch
								const start = parseMonthString(activeMonth || monthOptions[0]);
								const end = new Date(
									start.getFullYear(),
									start.getMonth() + 1,
									1,
								);
								dispatch({ type: "SET_START_DATE", payload: start });
								dispatch({ type: "SET_END_DATE", payload: end });
							}}
						>
							Retry
						</Button>
					}
				/>
			)}

			{/* The Cost Savings card: always rendered, controlling skeleton via isLoading */}
			<Row gutter={[16, 16]}>
				<Col span={24}>
					<CostSavingsCard
						monthOptions={monthOptions}
						costData={
							data
								? {
										ceiling: data.categoryData.ceiling,
										door: data.categoryData.door,
										flooring: data.categoryData.flooring,
										lighting: data.categoryData.lighting,
										millwork: data.categoryData.millwork,
										plumbing: data.categoryData.plumbing,
										wallPaint: data.categoryData.wallPaint,
										total: { savings: data.costSavings },
										cost: data.submissions * 350,
									}
								: null
						}
						activeMonth={activeMonth || ""}
						onMonthChange={onMonthChange}
						loading={isLoading}
						error={error || undefined}
					/>
				</Col>
			</Row>

			{/* Next row for the stats cards */}
			<Row gutter={[16, 16]}>
				<Col xs={24} sm={12} xl={6}>
					<StatsCard
						id="svisits"
						name="Total Visits"
						data={data?.submissions || 0}
						icon={<i className="bi bi-calendar-check primary" />}
						trend={data?.submissionsTrend || 0}
						showTrend
						isLoading={isLoading}
						popoverContent={
							data
								? `There were ${data.submissions.toLocaleString()} total visits this month, which is ${Math.abs(data.submissionsTrend)}% ${
										data.submissionsTrend >= 0 ? "more" : "less"
									} than the previous 3-month average.`
								: ""
						}
					/>
				</Col>
				<Col xs={24} sm={12} xl={6}>
					<StatsCard
						id="avglifespan"
						name="Total Absorbed Savings"
						data={data?.absorbedSavings || 0}
						icon={<i className="bi bi-cash-coin primary" />}
						trend={data?.absorbedSavingsTrend || 0}
						showTrend
						isLoading={isLoading}
						formatData={(val) => `$${(+val).toLocaleString()}`}
						popoverContent={
							data
								? `There was a total of $${data.absorbedSavings.toLocaleString()} absorbed, which is ${Math.abs(data.absorbedSavingsTrend)}% ${
										data.absorbedSavingsTrend >= 0 ? "more" : "less"
									} than the previous 3-month average.`
								: ""
						}
					/>
				</Col>
				<Col xs={24} sm={12} xl={6}>
					<StatsCard
						id="activeloc"
						name="Total Prevented"
						data={data?.costSavings || 0}
						icon={<i className="bi bi-cash-stack primary" />}
						trend={data?.preventedTrend || 0}
						showTrend
						isLoading={isLoading}
						formatData={(val) => `$${(+val).toLocaleString()}`}
						popoverContent={
							data
								? `A total of $${data.costSavings.toLocaleString()} prevented, which is ${Math.abs(data.preventedTrend)}% ${
										data.preventedTrend >= 0 ? "more" : "less"
									} than the previous 3-month average.`
								: ""
						}
					/>
				</Col>
				<Col xs={24} sm={12} xl={6}>
					<StatsCard
						id="alerts"
						name="Total Tickets Absorbed"
						data={data?.absorbedTickets || 0}
						icon={<i className="bi bi-ticket-perforated primary" />}
						trend={data?.absorbedTicketsTrend || 0}
						showTrend
						isLoading={isLoading}
						popoverContent={
							data
								? `There were ${data.absorbedTickets.toLocaleString()} tickets absorbed, which is ${Math.abs(data.absorbedTicketsTrend)}% ${
										data.absorbedTicketsTrend >= 0 ? "more" : "less"
									} than the previous 3-month average.`
								: ""
						}
					/>
				</Col>
			</Row>
		</div>
	);
};
