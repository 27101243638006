import {
	BankOutlined,
	CloudServerOutlined,
	EnvironmentOutlined,
	EyeOutlined,
	FileSearchOutlined,
	FileTextOutlined,
	HistoryOutlined,
	HomeOutlined,
	KeyOutlined,
	LeftOutlined,
	LineChartOutlined,
	MedicineBoxOutlined,
	RightOutlined,
	SafetyCertificateOutlined,
	ScanOutlined,
	SettingOutlined,
	TeamOutlined,
} from "@ant-design/icons";
import { Button, Menu, Space } from "antd";
import type { FC } from "react";
import { useEffect, useMemo, useReducer, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./dfp-scroll-navigation.scss";

interface ScrollableNavigationBarProps {
	tabNames: string[];
	basePath: string;
}

interface TabDimensions {
	widths: number[];
	totalWidth: number;
}

interface ScrollState {
	canScrollLeft: boolean;
	canScrollRight: boolean;
	tabDimensions: TabDimensions;
	selectedTabIndex: number;
}

type ScrollAction =
	| { type: "SET_TAB_DIMENSIONS"; payload: TabDimensions }
	| {
			type: "SET_SCROLL_STATE";
			payload: { canScrollLeft: boolean; canScrollRight: boolean };
	  }
	| { type: "SET_SELECTED_TAB"; payload: number };

const iconMap: Record<string, JSX.Element> = {
	Overview: <HomeOutlined />,
	Virtual: <CloudServerOutlined />,
	Assets: <SafetyCertificateOutlined />,
	"Predictive Spend": <LineChartOutlined />,
	Submissions: <FileTextOutlined />,
	Maintenance: <MedicineBoxOutlined />,
	Settings: <SettingOutlined />,
	"Asset Map": <EnvironmentOutlined />,
	Users: <TeamOutlined />,
	"Vision Viewer": <EyeOutlined />,
	"Data Update": <ScanOutlined />,
	"Monthly Audit": <HistoryOutlined />,
	"Master Asset Types": <KeyOutlined />,
	"Org Asset Types": <BankOutlined />,
	Reports: <FileSearchOutlined />,
	"OCR Template Tool": <ScanOutlined />,
	"Vision Trainer": <EyeOutlined />,
};

const initialState: ScrollState = {
	canScrollLeft: false,
	canScrollRight: true,
	tabDimensions: {
		widths: [],
		totalWidth: 0,
	},
	selectedTabIndex: 0,
};

const scrollReducer = (
	state: ScrollState,
	action: ScrollAction,
): ScrollState => {
	switch (action.type) {
		case "SET_TAB_DIMENSIONS":
			return {
				...state,
				tabDimensions: action.payload,
			};
		case "SET_SCROLL_STATE":
			return {
				...state,
				canScrollLeft: action.payload.canScrollLeft,
				canScrollRight: action.payload.canScrollRight,
			};
		case "SET_SELECTED_TAB":
			return {
				...state,
				selectedTabIndex: action.payload,
			};
		default:
			return state;
	}
};

const calculateTabWidth = (index: number, widths: number[]): number => {
	return widths.slice(0, index).reduce((sum, width) => sum + width, 0);
};

const ScrollableNavigationBar: FC<ScrollableNavigationBarProps> = ({
	tabNames,
	basePath,
}) => {
	const [state, dispatch] = useReducer(scrollReducer, initialState);
	const navRef = useRef<HTMLDivElement>(null);
	const location = useLocation();
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 1200);

	// Get the current path segment and handle empty or root paths
	const currentPath = useMemo(() => {
		const pathSegments = location.pathname.split("/");
		const baseSegments = basePath.split("/");
		const currentSegment = pathSegments[baseSegments.length];

		// If we're at the root of basePath, use the first tab
		if (!currentSegment) {
			return tabNames[0].toLowerCase().replace(/\s+/g, "-");
		}
		return currentSegment;
	}, [location.pathname, basePath, tabNames]);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 1200);
		};

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	// Update selected tab index whenever the route changes
	useEffect(() => {
		const selectedIndex = tabNames.findIndex(
			(tab) => tab.toLowerCase().replace(/\s+/g, "-") === currentPath,
		);

		if (selectedIndex >= 0) {
			dispatch({ type: "SET_SELECTED_TAB", payload: selectedIndex });

			// Scroll the selected tab into view for desktop view
			if (!isMobile && navRef.current) {
				const tabElements = document.querySelectorAll(".nav-tab");
				const selectedTab = tabElements[selectedIndex] as HTMLElement;
				if (selectedTab) {
					const containerWidth = navRef.current.clientWidth;
					const tabLeft = selectedTab.offsetLeft;
					const tabWidth = selectedTab.offsetWidth;

					// Calculate the scroll position to center the tab
					const scrollPosition = tabLeft - containerWidth / 2 + tabWidth / 2;
					navRef.current.scrollTo({ left: scrollPosition, behavior: "smooth" });
				}
			}
		}
	}, [currentPath, tabNames, isMobile]);

	useEffect(() => {
		const updateTabDimensions = (): void => {
			const tabElements = document.querySelectorAll(".nav-tab");
			const widths = Array.from(tabElements).map(
				(tab) => tab.getBoundingClientRect().width,
			);
			const totalWidth = widths.reduce((sum, width) => sum + width, 0);

			dispatch({
				type: "SET_TAB_DIMENSIONS",
				payload: { widths, totalWidth },
			});

			checkScrollPosition();
		};

		updateTabDimensions();
		window.addEventListener("resize", updateTabDimensions);
		return () => window.removeEventListener("resize", updateTabDimensions);
	}, []);

	const checkScrollPosition = (): void => {
		if (!navRef.current) return;

		const { scrollLeft, scrollWidth, clientWidth } = navRef.current;
		const maxScrollLeft = scrollWidth - clientWidth;

		dispatch({
			type: "SET_SCROLL_STATE",
			payload: {
				canScrollLeft: scrollLeft > 0,
				canScrollRight: scrollLeft < maxScrollLeft,
			},
		});
	};

	const handleScroll = (direction: "left" | "right"): void => {
		if (!navRef.current) return;

		const scrollAmount = direction === "left" ? -150 : 150;
		navRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
	};

	const menuItems = tabNames.map((tab) => ({
		key: tab.toLowerCase().replace(/\s+/g, "-"),
		icon: iconMap[tab],
		label: (
			<Link
				to={`${basePath}/${tab.toLowerCase().replace(/\s+/g, "-")}`}
				className={
					tab.toLowerCase().replace(/\s+/g, "-") === currentPath ? "active" : ""
				}
			>
				<span>{tab}</span>
			</Link>
		),
	}));

	return (
		<div className="scroll-navigation-container mx-2">
			{isMobile ? (
				<Menu
					mode="horizontal"
					selectedKeys={[currentPath]}
					items={menuItems}
				/>
			) : (
				<Space className="navigation-wrapper d-flex">
					<Button
						type="text"
						icon={<LeftOutlined />}
						onClick={() => handleScroll("left")}
						disabled={!state.canScrollLeft}
						className="scroll-button"
					/>

					<div
						ref={navRef}
						className="tabs-container"
						onScroll={checkScrollPosition}
					>
						<div className="tabs-wrapper">
							{tabNames.map((tab, index) => (
								<Link
									key={tab}
									to={`${basePath}/${tab.toLowerCase().replace(/\s+/g, "-")}`}
									className={`nav-tab ${tab.toLowerCase().replace(/\s+/g, "-") === currentPath ? "active" : ""}`}
								>
									{iconMap[tab]}
									<span>{tab}</span>
								</Link>
							))}
							<div
								className="tab-indicator"
								style={{
									left: `${calculateTabWidth(state.selectedTabIndex, state.tabDimensions.widths)}px`,
									width: `${state.tabDimensions.widths[state.selectedTabIndex] || 0}px`,
								}}
							/>
						</div>
					</div>

					<Button
						type="text"
						icon={<RightOutlined />}
						onClick={() => handleScroll("right")}
						disabled={!state.canScrollRight}
						className="scroll-button"
					/>
				</Space>
			)}
		</div>
	);
};

export { ScrollableNavigationBar };
