import { useUserStore } from "@/stores";
import {
	addUserInternal,
	adminUpdateUser,
	deleteUser,
	getOrganizationSubmittedUsers,
	getOrganizationUsers,
	getRoles,
	getUserSelf,
	selfUpdateUser,
} from "@services/user-service";

interface UserUpdateParams {
	userId?: string;
	type?: "filter" | "image" | "base";
	data?: any;
	filterTable?: string;
}

interface UserResult {
	success: boolean;
	data?: any;
	error?: string;
}

/**
 * Pure function to get the current user's data
 */
export const getCurrentUser = async (): Promise<UserResult> => {
	try {
		const user = await getUserSelf();
		return { success: true, data: user };
	} catch (error: any) {
		return {
			success: false,
			error: error.message || "Failed to fetch user data",
		};
	}
};

/**
 * Pure function to update another user's data (admin only)
 */
export const updateUserAsAdmin = async (
	params: UserUpdateParams,
): Promise<UserResult> => {
	try {
		const { userId, type, data, filterTable } = params;

		switch (type) {
			case "filter":
				await selfUpdateUser({
					saved_filters: { [filterTable!]: data },
				});
				break;
			case "image":
				await selfUpdateUser({ profile_picture_url: data });
				break;
			case "base":
				if (!userId) throw new Error("User ID is required for base update");
				await adminUpdateUser(userId, {
					name: data.name,
					role_id: data.role.id,
					organization_id: data.organization_id,
				});
				break;
			default:
				throw new Error("Invalid update type");
		}

		return { success: true };
	} catch (error: any) {
		return {
			success: false,
			error: error.message || "Failed to update user",
		};
	}
};

/**
 * Pure function to update current user's data
 */
export const updateCurrentUser = async (data: any): Promise<UserResult> => {
	try {
		const updatedUser = await selfUpdateUser(data);
		return { success: true, data: updatedUser };
	} catch (error: any) {
		return {
			success: false,
			error: error.message || "Failed to update user",
		};
	}
};

/**
 * Pure function to delete a user
 */
export const removeUser = async (userId: string): Promise<UserResult> => {
	try {
		await deleteUser(userId);
		return { success: true };
	} catch (error: any) {
		return {
			success: false,
			error: error.message || "Failed to delete user",
		};
	}
};

/**
 * Pure function to add a new user
 */
export const addNewUser = async (params: {
	name: string;
	email: string;
	password: string;
	role: string;
}): Promise<UserResult> => {
	try {
		await addUserInternal(
			params.name,
			params.email,
			params.password,
			params.role,
		);
		return { success: true };
	} catch (error: any) {
		return {
			success: false,
			error: error.message || "Failed to add user",
		};
	}
};

// React hooks for state management
export const updateUserState = () => {
	const setUserState = useUserStore((state: any) => state.setUser);

	const updateUser = async (data: any) => {
		const result = await updateCurrentUser(data);
		if (result.success && result.data) {
			setUserState(result.data);
		}
		return result;
	};

	return { updateUser };
};

/**
 * Hook to get organization users
 */
export const useOrganizationUsers = () => {
	const getUsers = async () => {
		try {
			const users = await getOrganizationUsers();
			return { success: true, data: users };
		} catch (error: any) {
			return {
				success: false,
				error: error.message || "Failed to fetch organization users",
			};
		}
	};

	return { getUsers };
};

/**
 * Hook to get user roles
 */
export const useRoles = () => {
	const getRolesList = async () => {
		try {
			const roles = await getRoles();
			return { success: true, data: roles };
		} catch (error: any) {
			return {
				success: false,
				error: error.message || "Failed to fetch roles",
			};
		}
	};

	return { getRolesList };
};

/**
 * Hook to get organization submitted users
 */
export const useOrganizationSubmittedUsers = (org: string, formId: string) => {
	const getSubmittedUsers = async () => {
		if (!org || !formId) {
			return {
				success: false,
				error: "Organization and form ID are required",
			};
		}

		try {
			const users = await getOrganizationSubmittedUsers(formId);
			return { success: true, data: users };
		} catch (error: any) {
			return {
				success: false,
				error: error.message || "Failed to fetch submitted users",
			};
		}
	};

	return { getSubmittedUsers };
};
