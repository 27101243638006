import { mountStoreDevtool } from "simple-zustand-devtools";
import { create } from "zustand";

export interface PageStore {
	isSidebarOpen: boolean;
	setIsSidebarOpen: (open: boolean) => void;
	isLoading: boolean;
	setIsLoading: (loading: boolean) => void;
}

export const usePageStore = create<PageStore>((set) => ({
	isSidebarOpen: false,
	setIsSidebarOpen: (open: boolean) => set({ isSidebarOpen: open }),
	isLoading: false,
	setIsLoading: (loading: boolean) => set({ isLoading: loading }),
}));

if (import.meta.env.NODE_ENV === "development") {
	mountStoreDevtool("pageStore", usePageStore);
}
