import { DFPModal } from "@/components/modals/dfp-modal";
import { addUserInternal } from "@/services/user-service";
import { useUserStore } from "@/stores";
import { Form, Input, Select, message } from "antd";
import { useMemo, useState } from "react";

interface AddUserModalProps {
	state: any;
	dispatch: any;
	getUsers?: (dispatch: any) => Promise<void>;
}

export const AddUserModal = (props: AddUserModalProps) => {
	const { state, dispatch, getUsers } = props;
	const [confirmLoading, setConfirmLoading] = useState(false);
	const { user } = useUserStore();
	const [form] = Form.useForm();

	// Base role options defined locally
	const baseRoleOptions = [
		{ label: "USER", value: 1 },
		{ label: "VERIZON", value: 2 },
		{ label: "ADMIN", value: 3 },
	];

	const roleOptions = useMemo(() => {
		if (user?.role?.access_level > 500) {
			return [...baseRoleOptions, { label: "SUPERUSER", value: 4 }];
		}
		return baseRoleOptions;
	}, [user]);

	const onOk = async () => {
		try {
			const values = await form.validateFields();
			setConfirmLoading(true);

			const { name, email, password, role_id } = values;
			await addUserInternal(name, email, password, role_id);

			message.success("User added successfully.");
			dispatch({ type: "SET_SHOW_ADD_USER_MODAL", payload: false });
			form.resetFields();

			if (getUsers) {
				await getUsers(dispatch);
			}
		} catch (error) {
			console.error("Error adding user:", error);
			message.error("An error occurred while adding the user.");
		} finally {
			setConfirmLoading(false);
		}
	};

	const onCancel = () => {
		dispatch({ type: "SET_SHOW_ADD_USER_MODAL", payload: false });
		form.resetFields();
	};

	return (
		<DFPModal
			open={state.showAddUserModal}
			onCancel={onCancel}
			onOk={onOk}
			confirmLoading={confirmLoading}
			title="Add New User"
		>
			<Form
				form={form}
				layout="vertical"
				initialValues={{
					role_id: roleOptions[0].value,
				}}
			>
				<Form.Item
					label="Name"
					name="name"
					rules={[{ required: true, message: "Please input the name" }]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					label="Email"
					name="email"
					rules={[
						{ required: true, message: "Please input the email" },
						{ type: "email", message: "Please enter a valid email address" },
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					label="Password"
					name="password"
					rules={[
						{ required: true, message: "Please input the password" },
						{ min: 6, message: "Password must be at least 6 characters long" },
					]}
				>
					<Input.Password />
				</Form.Item>
				<Form.Item
					label="Role"
					name="role_id"
					rules={[{ required: true, message: "Please select a role" }]}
				>
					<Select options={roleOptions} placeholder="Select a role" />
				</Form.Item>
			</Form>
		</DFPModal>
	);
};
