import type { StringMap } from "@/models/abstract-types";
import { mountStoreDevtool } from "simple-zustand-devtools";
import { type StoreApi, type UseBoundStore, create } from "zustand";

interface AdminStore {
	activeTab: string;
	setActiveTab: (tab: string) => void;
	pageSnapshots: any;
	setPageSnapshot: (pageNum: number, snapshot: any) => void;
	clearPageSnapshots: () => void;
	submissionsColumnFilters: StringMap;
	submissionsTempFilters: StringMap;
	setSubmissionsTempFilters: (filters: StringMap) => void;
	setSubmissionsColumnFilters: (filters: StringMap) => void;
	assetsTempFilters: StringMap;
	assetsColumnFilters: StringMap;
	setAssetsColumnFilters: (filters: StringMap) => void;
	setAssetsTempFilters: (filters: StringMap) => void;
	assetLocationToggle: string;
	setAssetLocationToggle: (toggle: string) => void;
	submissionsLocationToggle: string;
	setSubmissionsLocationToggle: (toggle: string) => void;
	clearFilters: () => void;
}

export const useAdminStore: UseBoundStore<StoreApi<AdminStore>> = create(
	(set) => ({
		activeTab: "",
		setActiveTab: (tab: string) => set({ activeTab: tab }),
		pageSnapshots: {},
		setPageSnapshot: (pageNum: number, snapshot: any) =>
			set((state: { pageSnapshots: any }) => ({
				pageSnapshots: {
					...state.pageSnapshots,
					[pageNum]: snapshot,
				},
			})),
		clearPageSnapshots: () => set({ pageSnapshots: {} }),
		submissionsColumnFilters: {},
		submissionsTempFilters: {},
		setSubmissionsTempFilters: (filters: StringMap) =>
			set({ submissionsTempFilters: filters }),
		setSubmissionsColumnFilters: (filters: StringMap) =>
			set({ submissionsColumnFilters: filters }),
		assetsTempFilters: {},
		assetsColumnFilters: {},
		setAssetsColumnFilters: (filters: StringMap) =>
			set({ assetsColumnFilters: filters }),
		setAssetsTempFilters: (filters: StringMap) =>
			set({ assetsTempFilters: filters }),
		assetLocationToggle: "city",
		setAssetLocationToggle: (toggle: string) =>
			set({ assetLocationToggle: toggle }),
		submissionsLocationToggle: "city",
		setSubmissionsLocationToggle: (toggle: string) =>
			set({ submissionsLocationToggle: toggle }),
		clearFilters: () =>
			set({
				submissionsTempFilters: {},
				assetsTempFilters: {},
				submissionsColumnFilters: {},
				assetsColumnFilters: {},
				assetLocationToggle: "city",
				submissionsLocationToggle: "city",
			}),
	}),
);

if (import.meta.env.NODE_ENV === "development") {
	mountStoreDevtool("adminStore", useAdminStore);
}
