import { SettingOutlined } from "@ant-design/icons";
import { Button, Checkbox, Dropdown } from "antd";
import { type FC } from "react";

interface Column {
	key: string;
	title: string;
}

interface ColumnConfigDropdownProps {
	columns: Column[];
	columnsShown: Record<string, boolean>;
	onColumnVisibilityChange: (key: string, isVisible: boolean) => void;
}

const ColumnConfigDropdown: FC<ColumnConfigDropdownProps> = ({
	columns,
	columnsShown,
	onColumnVisibilityChange,
}) => {
	// Handler for checkbox state changes
	const handleCheckboxChange = (key: string) => {
		onColumnVisibilityChange(key, !columnsShown[key]);
	};

	// Build the dropdown content
	const dropdownContent = (
		<div
			className="ant-dropdown-menu ant-dropdown-menu-root ant-dropdown-menu-vertical"
			onClick={(e) => e.stopPropagation()} // Prevents dropdown from closing on click inside
		>
			{columns?.map((col) => (
				<div key={col.key} className="ant-dropdown-menu-item">
					<Checkbox
						checked={columnsShown[col.key]}
						onChange={() => handleCheckboxChange(col.key)}
					>
						{col.title}
					</Checkbox>
				</div>
			))}
		</div>
	);

	return (
		<Dropdown
			trigger={["click"]}
			placement="bottomRight"
			arrow
			dropdownRender={() => dropdownContent}
		>
			<Button
				icon={<SettingOutlined style={{ fontSize: "20px" }} />}
				type="text"
			/>
		</Dropdown>
	);
};

export { ColumnConfigDropdown };
