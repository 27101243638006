import { message } from "antd";
import type { FC } from "react";
import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ENV } from "./constants/env";
import { AuthProvider } from "./contexts/auth-context";
import { ProtectedRoute } from "./services/protected-route";
import { Landing } from "./views/Landing/Landing";
import { Login } from "./views/Login/Login";

export const App: FC = () => {
	message.config({ maxCount: 1 });
	useScript("https://static.matterport.com/showcase-sdk/latest.js");

	function useScript(src: string | null): string {
		const [status, setStatus] = useState<string>(src ? "loading" : "idle");

		useEffect(() => {
			if (!src) {
				setStatus("idle");
				return;
			}

			let script = document.querySelector<HTMLScriptElement>(
				`script[src="${src}"]`,
			);
			if (!script) {
				script = document.createElement("script");
				script.src = src;
				script.async = true;
				script.setAttribute("data-status", "loading");
				document.body.appendChild(script);

				const setAttributeFromEvent = (event: Event) => {
					script?.setAttribute(
						"data-status",
						event.type === "load" ? "ready" : "error",
					);
				};
				script.addEventListener("load", setAttributeFromEvent);
				script.addEventListener("error", setAttributeFromEvent);
			} else {
				setStatus(script.getAttribute("data-status") || "loading");
			}

			const setStateFromEvent = (event: Event) => {
				setStatus(event.type === "load" ? "ready" : "error");
			};

			script.addEventListener("load", setStateFromEvent);
			script.addEventListener("error", setStateFromEvent);

			return () => {
				script?.removeEventListener("load", setStateFromEvent);
				script?.removeEventListener("error", setStateFromEvent);
			};
		}, [src]);

		return status;
	}

	useEffect(() => {
		try {
			if (ENV === "production" && "serviceWorker" in navigator) {
				navigator.serviceWorker
					.register("/service-worker.js")
					.then((registration) => {
						console.log(
							"Service Worker registered with scope:",
							registration.scope,
						);
						registration.update();
					})
					.catch((error) => {
						console.log("Service Worker registration failed:", error);
					});
			}
		} catch (error) {
			console.log("Service Worker registration failed:", error);
		}
	}, []);

	return (
		<AuthProvider>
			<BrowserRouter>
				<Routes>
					<Route path="landing" element={<Landing />} />
					<Route path="login" element={<Login />} />
					<Route path="/*" element={<ProtectedRoute />} />
				</Routes>
			</BrowserRouter>
		</AuthProvider>
	);
};
