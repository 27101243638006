import { GeneratedPropIcon } from "@/components/tooltips/generated-prop-icon";
import { isUnknownValue } from "@/utils/isUnknownValue";
import { Skeleton, Typography } from "antd";
import type { FC } from "react";

interface CopyableTableCellProps {
	text: string;
	backfillValue?: string;
	copyable?: boolean;
	isLoading?: boolean;
}

export const DFPTypography = Typography;

export const CopyableTableCell: FC<CopyableTableCellProps> = ({
	text,
	backfillValue = undefined,
	copyable = true,
	isLoading,
}) => {
	if (isLoading) {
		return (
			<Skeleton.Input active={true} size="small" style={{ width: "100%" }} />
		);
	}

	const shouldShowIcon = isUnknownValue(text) && !!backfillValue;
	const displayText = isUnknownValue(text) ? backfillValue || "N/A" : text;

	return (
		<div className="copyable-cell d-flex align-items-center">
			<Typography.Text className="d-flex flex-row gap-1" copyable={copyable}>
				{displayText}
			</Typography.Text>
			{shouldShowIcon && <GeneratedPropIcon />}
		</div>
	);
};
