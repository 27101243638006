import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

export enum UserAccessLevel {
	USER = 100,
	VERIZON = 125,
	ADMIN = 500,
	SUPER_ADMIN = 1000,
}

export interface IUser {
	id: number;
	email: string;
	organization_id: number;
	external_firebase_id: string;
	is_account_disabled: boolean;
	role_id: number;
	name: string;
	is_internal_account: boolean;
	role: IRole;
	user_config: IUserConfig;
	organization: IOrganization;
	allowed_organizations: IOrganization[];
}

export interface IForm {
	organization_id: number;
	enabled: boolean;
	form_id: number;
	config: IFormConfig;
}

export interface IFormConfig {
	formName: string;
	description: string;
	sections: IFormSection[];
}

export interface IFormSection {
	sectionName: string;
	fields: IFormField[];
}

export interface IFormField {
	[key: string]: unknown;
}

export interface IRole {
	access_level: number;
	id: number;
	name: string;
}

export interface IUserConfig {
	user_id: number;
	use_camera_on_photo_capture: boolean;
	saved_filters: {
		assetsTable: IAssetsTableFilters;
	};
	profile_picture_url: string | null;
	is_on_email_list: boolean;
}

export interface IAssetsTableFilters {
	address: boolean;
	assetId: boolean;
	make: boolean;
	model: boolean;
	serial: boolean;
	dateOfBirth: boolean;
	subOrg: boolean | null;
	healthscore: boolean;
	submittedDate: boolean;
	warranty: boolean;
	tempAlertId: boolean;
	assetType: boolean;
	assetTypeCategory: boolean;
}

export interface IOrganization {
	full_name: string;
	id: number;
	external_firebase_id: string;
	health: IHealth;
	config: IOrganizationConfig;
	forms: IForm[];
	graphs: unknown[];
	reports: IReport[];
}

export interface IMasterReportKey {
	id: number;
	report_name: string;
	description: string | null;
}

export interface IReport {
	report_id: number;
	enabled: boolean;
	organization_id: number;
	master_report_key: IMasterReportKey;
}

export interface IHealth {
	unhealthy_assets: number;
	organization_id: number;
	assets_near_death: number;
	health_score: number;
}

export interface IOrganizationConfig {
	show_matterport: boolean;
	organization_id: number;
	is_location_name_required: boolean;
	service_channel_export_enabled: boolean;
	org_specific_inputs: Record<string, unknown>;
	sc_export_options: ISCExportOption[];
	unique_fields: IUniqueFields;
	logo_url: string;
	show_iguide: boolean;
	auto_save_drafts: boolean;
	allow_tag_id: boolean;
	export_options: IExportOptions;
	integration_settings: Record<string, unknown>;
}

export interface ISCExportOption {
	subtype: string[];
	headers: ISCExportHeader[];
}

export interface ISCExportHeader {
	key: string;
	header: string;
}

export interface IUniqueFields {
	[key: string]: unknown;
}

export interface IExportOptions {
	address1: unknown[];
}

interface UserState {
	user: IUser | null;
	setUser: (user: IUser) => void;
}

export const useUserStore = create<UserState>()(
	persist(
		(set, get) => ({
			user: get()?.user,
			setUser: (user: IUser) => set({ user }),
		}),
		{
			name: "dfp-user",
			storage: createJSONStorage(() => localStorage),
		},
	),
);
