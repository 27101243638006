import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import {
	Button,
	Card,
	DatePicker,
	Skeleton,
	Space,
	Switch,
	Tag,
	Tooltip,
	message,
} from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import dayjs, { type Dayjs } from "dayjs";
import {
	FC,
	useCallback,
	useEffect,
	useReducer,
	useRef,
	useState,
} from "react";
import { MdDownload } from "react-icons/md";

import { DFPEmpty } from "@/components/empty/dfp-empty";
import { CSVExporter } from "@/components/pure/csv-expoter/csv-exporter";
import { DFPTable } from "@/components/tables/dfp-table";
import { CopyableCellRenderer } from "@/components/tables/renderers/copyable-cell-renderer";
import {
	type BaseTableAction,
	type BaseTableState,
	baseInitialState,
	baseTableReducer,
} from "@/components/tables/shared-table-state";
import { getOrganizationLocations } from "@/services/location-service";
import { searchSubmissions } from "@/services/submission-service";
import {
	type IForm,
	type IOrganization,
	useUserStore,
} from "@/stores/user-store";
import { ASCENDING, DESCENDING } from "../AssetsTable/children/AssetsTable";
import { FormSelect } from "../SubmissionsTable/FormSelect";

const { RangePicker } = DatePicker;

interface Location {
	id: number;
	name?: string;
	sitename?: string;
	address?: string;
	address1?: string;
	address2?: string;
	city?: string;
	state?: string;
	zip?: string;
	isLoading?: boolean; // For skeleton usage
}

interface SubmissionResult {
	id: number;
	location?: {
		id: number;
		address1?: string;
		address2?: string;
		city?: string;
		state?: string;
		zip?: string;
		sitename?: string;
	};
}

interface State extends BaseTableState {
	isFocused: boolean;
	formsArr: IForm[];
	selectedForm: number | null;
	selectedFormMode: string;
	viewingDeleted: boolean;
	viewingDrafts: boolean;
	allLocations: Location[];
	locsWithSubmission: Location[];
	locsWithoutSubmission: Location[];
	isLoadingData: boolean;
	showExportModal: boolean;
	totalCount: number;
	filters: Record<string, unknown>;
}

type Action =
	| BaseTableAction
	| { type: "SET_IS_FOCUSED"; payload: boolean }
	| { type: "SET_FORMS"; payload: IForm[] }
	| { type: "SET_SELECTED_FORM"; payload: number | null }
	| { type: "SET_SELECTED_FORM_MODE"; payload: string }
	| { type: "SET_VIEWING_DELETED"; payload: boolean }
	| { type: "SET_VIEWING_DRAFTS"; payload: boolean }
	| { type: "SET_ALL_LOCATIONS"; payload: Location[] }
	| { type: "SET_LOCS_WITH_SUBMISSION"; payload: Location[] }
	| { type: "SET_LOCS_WITHOUT_SUBMISSION"; payload: Location[] }
	| { type: "SET_IS_LOADING_DATA"; payload: boolean }
	| { type: "SET_SHOW_EXPORT_MODAL"; value: boolean }
	| { type: "SET_TOTAL_COUNT"; payload: number };

const initialState: State = {
	...baseInitialState,
	isFocused: true,
	formsArr: [],
	selectedForm: null,
	selectedFormMode: "0",
	viewingDeleted: false,
	viewingDrafts: false,
	allLocations: [],
	locsWithSubmission: [],
	locsWithoutSubmission: [],
	isLoadingData: true,
	showExportModal: false,
	tableColumns: [],
	columnsShown: {},
	sortBy: "end_time",
	totalCount: 0,
	filters: {},
};

const columnsToRender: ColumnsType = [
	{
		key: "sitename",
		title: "Site Name",
		dataIndex: "name",
		render: (_, record) => {
			if (record.isLoading) {
				return <Skeleton active paragraph={false} title={{ width: "80%" }} />;
			}
			return <CopyableCellRenderer value={record.name || "N/A"} />;
		},
	},
	{
		key: "address",
		title: "Address",
		render: (_, record) => {
			if (record.isLoading) {
				return <Skeleton active paragraph={false} title={{ width: "60%" }} />;
			}
			const addressParts: string[] = [];
			if (record.address1) addressParts.push(record.address1);
			if (record.address2) addressParts.push(record.address2);
			return <CopyableCellRenderer value={addressParts.join(", ") || "N/A"} />;
		},
	},
	{
		key: "city",
		title: "City",
		dataIndex: "city",
		render: (_, record) => {
			if (record.isLoading) {
				return <Skeleton active paragraph={false} title={{ width: "40%" }} />;
			}
			return <CopyableCellRenderer value={record.city || "N/A"} />;
		},
	},
	{
		key: "state",
		title: "State",
		dataIndex: "state",
		render: (_, record) => {
			if (record.isLoading) {
				return <Skeleton active paragraph={false} title={{ width: "30%" }} />;
			}
			return <CopyableCellRenderer value={record.state || "N/A"} />;
		},
	},
	{
		key: "zip",
		title: "Zip",
		dataIndex: "zip",
		render: (_, record) => {
			if (record.isLoading) {
				return <Skeleton active paragraph={false} title={{ width: "20%" }} />;
			}
			return <CopyableCellRenderer value={record.zip || "N/A"} />;
		},
	},
];

function reducer(state: State, action: Action): State {
	switch (action.type) {
		case "SET_IS_FOCUSED":
			return { ...state, isFocused: action.payload };
		case "SET_FORMS":
			return { ...state, formsArr: action.payload };
		case "SET_SELECTED_FORM":
			return { ...state, selectedForm: action.payload };
		case "SET_SELECTED_FORM_MODE":
			return { ...state, selectedFormMode: action.payload };
		case "SET_VIEWING_DELETED":
			return { ...state, viewingDeleted: action.payload };
		case "SET_VIEWING_DRAFTS":
			return { ...state, viewingDrafts: action.payload };
		case "SET_ALL_LOCATIONS":
			return { ...state, allLocations: action.payload };
		case "SET_LOCS_WITH_SUBMISSION":
			return { ...state, locsWithSubmission: action.payload };
		case "SET_LOCS_WITHOUT_SUBMISSION":
			return { ...state, locsWithoutSubmission: action.payload };
		case "SET_IS_LOADING_DATA":
			return { ...state, isLoadingData: action.payload };
		case "SET_SHOW_EXPORT_MODAL":
			return { ...state, showExportModal: action.value };
		case "SET_TOTAL_COUNT":
			return { ...state, totalCount: action.payload };
		default:
			return baseTableReducer(state, action);
	}
}

interface PrevMonthTabProps {
	organization: IOrganization;
}

const defaultLastMonthRange = (): [Dayjs, Dayjs] => {
	const now = dayjs();
	const start = now.subtract(1, "month").startOf("month");
	const end = now.subtract(1, "month").endOf("month");
	return [start, end];
};

export const MonthlyAuditTab: FC<PrevMonthTabProps> = ({ organization }) => {
	const { user } = useUserStore();
	const [state, dispatch] = useReducer(reducer, {
		...initialState,
	});

	// Default date range to last month
	const [dateRange, setDateRange] = useState<
		[Dayjs | undefined, Dayjs | undefined]
	>(defaultLastMonthRange());
	// Toggle for with/without submission
	const [showWithSubmission, setShowWithSubmission] = useState(true);
	// State to trigger CSV export
	const [exportTriggered, setExportTriggered] = useState(false);
	// Track if we've fetched submissions at least once
	const [hasFetchedSubmissions, setHasFetchedSubmissions] = useState(false);

	// Add a ref to track initialization
	const isInitializedRef = useRef(false);
	// Add a new ref to track the first submissions fetch
	const firstSubmissionsFetchRef = useRef(false);

	const {
		selectedForm,
		selectedFormMode,
		viewingDeleted,
		viewingDrafts,
		locsWithSubmission,
		locsWithoutSubmission,
		isLoadingData,
		allLocations,
		pageNum,
		pageSize,
		sortBy,
		sortOrder,
		filters,
		formsArr,
	} = state;

	// Add the form change handler
	const handleFormChange = useCallback((formId: number) => {
		dispatch({
			type: "SET_SELECTED_FORM",
			payload: formId,
		});
	}, []);

	// First useEffect - Initial data loading
	useEffect(() => {
		const fetchInitialData = async () => {
			// Skip if already initialized
			if (isInitializedRef.current) {
				return;
			}

			dispatch({ type: "SET_IS_LOADING_DATA", payload: true });
			try {
				const forms = user?.organization?.forms ?? [];
				const locationsResponse = await getOrganizationLocations();

				dispatch({ type: "SET_FORMS", payload: forms });
				dispatch({ type: "SET_ALL_LOCATIONS", payload: locationsResponse });

				if (forms.length > 0) {
					dispatch({
						type: "SET_SELECTED_FORM",
						payload: forms[0].form_id,
					});
				}

				isInitializedRef.current = true;
			} catch (error) {
				console.error("Failed to fetch initial data:", error);
				message.error("Failed to load forms or locations.");
			} finally {
				dispatch({ type: "SET_IS_LOADING_DATA", payload: false });
			}
		};

		// Only fetch if we have a valid organization
		if (user?.organization?.external_firebase_id) {
			fetchInitialData();
		}
	}, [user?.organization?.external_firebase_id]);

	// Second useEffect - Fetch submissions when dependencies change
	useEffect(() => {
		// Skip if not initialized yet
		if (!isInitializedRef.current) {
			return;
		}

		const fetchPrevMonthSubmissions = async () => {
			if (!selectedForm || allLocations.length === 0 || !dateRange) {
				return;
			}

			dispatch({ type: "SET_IS_LOADING_DATA", payload: true });

			try {
				const [start, end] = dateRange;
				const startStr = start?.format("YYYY-MM-DD");
				const endStr = end?.format("YYYY-MM-DD");
				const dateRangeStr = `${startStr},${endStr}`;
				const includeDeletedParam = viewingDeleted ? "true" : "false";

				const { results, total } = await searchSubmissions(
					selectedForm,
					selectedFormMode === "1" ? 1 : 0,
					(pageNum - 1) * pageSize,
					pageSize,
					sortBy,
					sortOrder === ASCENDING ? ASCENDING : DESCENDING,
					filters || {},
					"",
					{},
					[],
					includeDeletedParam,
					dateRangeStr,
					false,
				);

				dispatch({ type: "SET_TOTAL_COUNT", payload: total || 0 });

				const submissionLocationIds = new Set(
					results
						.map((res: SubmissionResult) => res.location?.id)
						.filter(Boolean),
				);

				const withSubmission: Location[] = [];
				const withoutSubmission: Location[] = [];

				for (const loc of allLocations) {
					if (submissionLocationIds.has(loc.id)) {
						withSubmission.push(loc);
					} else {
						withoutSubmission.push(loc);
					}
				}

				dispatch({ type: "SET_LOCS_WITH_SUBMISSION", payload: withSubmission });
				dispatch({
					type: "SET_LOCS_WITHOUT_SUBMISSION",
					payload: withoutSubmission,
				});
			} catch (error) {
				console.error("Failed to fetch submissions:", error);
				message.error("Failed to load submissions for selected date range.");
			} finally {
				firstSubmissionsFetchRef.current = true;
				dispatch({ type: "SET_IS_LOADING_DATA", payload: false });
				setHasFetchedSubmissions(true);
			}
		};

		fetchPrevMonthSubmissions();
	}, [
		selectedForm,
		selectedFormMode,
		viewingDeleted,
		viewingDrafts,
		allLocations,
		pageNum,
		pageSize,
		sortBy,
		sortOrder,
		filters,
		dateRange,
	]);

	// Modify the loading state calculation
	const loading =
		isLoadingData ||
		!isInitializedRef.current ||
		!firstSubmissionsFetchRef.current;

	const handleTableChange = (pagination: TablePaginationConfig) => {
		const { current, pageSize } = pagination;
		if (current) {
			dispatch({ type: "SET_PAGE_NUM", payload: current });
		}
		if (pageSize) {
			dispatch({ type: "SET_PAGE_SIZE", payload: pageSize });
		}
	};

	const dataToShow = showWithSubmission
		? locsWithSubmission
		: locsWithoutSubmission;
	const formName =
		formsArr.find((f) => f.form_id === selectedForm)?.config.formName ||
		"PM Report";
	const exportFileName = showWithSubmission
		? `Locations With ${formName}`
		: `Locations Without ${formName}`;

	// Update the adjustedData calculation to use the new loading state
	const adjustedData = loading
		? Array.from({ length: state.pageSize }, () => ({ isLoading: true }))
		: dataToShow;

	const triggerExport = () => {
		setExportTriggered(true);
	};

	// Update the showEmptyState calculation
	const showEmptyState =
		!loading && // Now includes both initialization and first fetch
		hasFetchedSubmissions &&
		dataToShow.length === 0;

	return (
		<div
			style={{
				width: "100%",
				height: "100%",
				display: "flex",
				flexDirection: "column",
				gap: "20px",
			}}
		>
			<Card
				style={{
					width: "100%",
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}
				title={
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							width: "100%",
						}}
					>
						<Space align="center">
							<Tag color={showWithSubmission ? "purple-inverse" : "purple"}>
								{showWithSubmission ? "Locations With" : "Locations Without"}
							</Tag>
							<FormSelect
								forms={formsArr}
								selectedFormId={selectedForm ?? undefined}
								isLoading={isLoadingData}
								onFormChange={handleFormChange}
							/>
							<Tooltip
								title={`Select date range to check ${formName} submissions for location`}
							>
								<RangePicker
									value={dateRange}
									format={"MM/DD/YYYY"}
									onChange={(dates) => {
										if (dates && dates.length === 2) {
											setDateRange([
												dates?.[0] || undefined,
												dates?.[1] || undefined,
											]);
										} else {
											// If cleared, reset to default last month
											setDateRange(defaultLastMonthRange());
										}
									}}
									disabled={loading}
								/>
							</Tooltip>
							<Tooltip
								title={
									showWithSubmission
										? `Currently showing locations WITH ${formName}. Click to switch.`
										: `Currently showing locations WITHOUT ${formName}. Click to switch.`
								}
							>
								<Switch
									checked={showWithSubmission}
									onChange={(checked) => setShowWithSubmission(checked)}
									checkedChildren={
										<CheckCircleOutlined style={{ color: "green" }} />
									}
									unCheckedChildren={
										<CloseCircleOutlined style={{ color: "red" }} />
									}
									disabled={loading}
								/>
							</Tooltip>
						</Space>
					</div>
				}
				extra={
					<Button
						type="default"
						disabled={loading || dataToShow.length === 0}
						onClick={triggerExport}
						icon={<MdDownload className="larger-font-icon larger-font" />}
					/>
				}
				bodyStyle={{
					flex: 1,
					display: "flex",
					flexDirection: "column",
					overflow: "hidden",
				}}
			>
				{showEmptyState ? (
					<DFPEmpty
						className="w-100 h-100"
						description={
							showWithSubmission
								? `No locations with ${formName}.`
								: `No locations without ${formName}.`
						}
					/>
				) : (
					<div style={{ flex: 1, overflow: "auto" }}>
						<DFPTable
							columns={columnsToRender}
							dataSource={adjustedData}
							rowKey="id"
							locale={{
								emptyText: loading ? null : (
									<DFPEmpty
										className="w-100 h-100"
										description="No submissions returned."
									/>
								),
							}}
							loading={false} // relying on skeleton rows instead
							pagination={{
								current: state.pageNum,
								pageSize: state.pageSize,
								total: adjustedData.length,
								showSizeChanger: true,
							}}
							onChange={handleTableChange}
							style={{ width: "100%", height: "100%" }}
						/>
					</div>
				)}
			</Card>

			{exportTriggered && (
				<CSVExporter
					data={dataToShow}
					filename={exportFileName}
					fields={["name", "address1", "city", "state", "zip"]}
					delimiter=","
					nullReplacement="N/A"
					organization={organization.external_firebase_id}
					formId={selectedForm?.toString()}
					onComplete={() => {
						message.success("Export complete.");
						setExportTriggered(false); // Reset trigger after download
					}}
				/>
			)}
		</div>
	);
};
