import { DFPModal } from "@/components/modals/dfp-modal";
import { deleteSubmission } from "@/services/submission-service";
import { message } from "antd";
import { useState } from "react";

interface DeleteModalProps {
	state: any;
	dispatch: any;
	browserLocationId?: string;
	tableType?: string;
	fetchData?: (
		state: any,
		dispatch: any,
		browserLocationId: string,
	) => Promise<void>;
	onOk?: () => void;
}

export const DeleteModal = (props: DeleteModalProps) => {
	const {
		state,
		dispatch,
		browserLocationId,
		fetchData,
		onOk: customOnOk,
	} = props;
	const [confirmLoading, setConfirmLoading] = useState(false);

	const onOk = async () => {
		setConfirmLoading(true);
		try {
			if (customOnOk) {
				await customOnOk({
					state,
					dispatch,
					setConfirmLoading,
					browserLocationId,
					fetchData,
				});
			} else {
				// Default onOk implementation
				if (state?.checkedItems?.length) {
					const deletePromises = state.checkedItems.map((item: any) =>
						deleteSubmission(item)
							.then(() => ({ item, success: true }))
							.catch((error) => ({ item, success: false, error })),
					);

					const results = await Promise.all(deletePromises);
					const errors = results.filter((result) => !result.success);
					const successes = results.filter((result) => result.success);

					if (successes.length) {
						message.success(`${successes.length} items deleted successfully.`);

						// Trigger a clean refetch
						dispatch({ type: "SET_REFETCH_TRIGGER", payload: Date.now() });
					}

					if (errors.length) {
						errors.forEach((error) =>
							console.error(`Error deleting item ${error.item}:`, error.error),
						);
						message.error(`${errors.length} items failed to delete.`);
					}
				} else {
					message.warning("No items selected for deletion.");
				}
			}
		} catch (error) {
			console.error("Error during deletion process:", error);
			message.error("An error occurred while deleting submissions.");
		} finally {
			// Close modal first
			dispatch({ type: "SET_SHOW_DELETE_MODAL", value: false });

			// Then reset other states
			setConfirmLoading(false);
			dispatch({ type: "SET_CHECKED_ITEMS", payload: [] });
		}
	};

	const onCancel = () =>
		dispatch({ type: "SET_SHOW_DELETE_MODAL", value: false });
	return (
		<DFPModal
			open={state.showDeleteModal}
			onCancel={onCancel}
			onOk={onOk}
			confirmLoading={confirmLoading}
		>
			<span>
				Are you sure you want to delete these {props?.tableType || "records"}?
			</span>
		</DFPModal>
	);
};
