/** Quick utility to check if a URL is a valid image link. */
export function isValidUrl(url: string): boolean {
	try {
		const u = new URL(url);
		return !!u.protocol && !!u.host;
	} catch {
		return false;
	}
}

export function getChangedProps(
	oldObj: Record<string, any>,
	newObj: Record<string, any>,
): Record<string, any> {
	const changes: Record<string, any> = {};

	if (!oldObj || !newObj) return newObj || {};
	if (typeof oldObj !== typeof newObj) return newObj;
	if (typeof oldObj !== "object") return oldObj !== newObj ? newObj : {};

	Object.keys(newObj).forEach((key) => {
		if (typeof newObj[key] === "object" && !Array.isArray(newObj[key])) {
			const nestedChanges = getChangedProps(oldObj[key], newObj[key]);
			if (Object.keys(nestedChanges).length > 0) {
				changes[key] = nestedChanges;
			}
		} else if (JSON.stringify(oldObj[key]) !== JSON.stringify(newObj[key])) {
			changes[key] = newObj[key];
		}
	});

	return changes;
}
