import { CopyableTableCell } from "@/components/typography/dfp-typography";
import { DB_ORG } from "@/constants/db";
import { type IOrganization } from "@/stores/user-store";
import { parseDateToLocalString } from "@/utils/transforms";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Skeleton } from "antd";
import type { ColumnsType } from "antd/es/table";
import { type ReactNode } from "react";
import { type SubmissionTableState } from "../SubmissionsTable";
interface AssetType {
	name: string;
	id: string | number;
}

/**
 * Helper to unify skeleton logic:
 * Returns a <Skeleton.Input> if isLoading=true, else returns whatever "real" content you pass in.
 */
function renderSkeletonOr(
	isLoading: boolean,
	realContent: ReactNode,
	skeletonWidth = 120, // default width for Skeleton.Input
) {
	if (isLoading) {
		return <Skeleton.Input style={{ width: skeletonWidth }} active />;
	}
	return realContent;
}

export const getOrgColumns = (
	organization: IOrganization,
	state: SubmissionTableState | undefined,
): ColumnsType<any> => {
	// ----------------------------------
	//  Default columns for "all" orgs
	// ----------------------------------
	const columns: ColumnsType<any> = [
		{
			title: "Asset ID",
			dataIndex: "asset_tag",
			key: "asset_tag",
			sorter: true,
			sortDirections: ["descend", "ascend", "descend"],
			width: "15%",
			render: (text, record) =>
				renderSkeletonOr(
					record.isLoading,
					<CopyableTableCell text={text} />,
					80, // optional skeleton width
				),
		},
		{
			title: "Asset Type",
			dataIndex: ["asset_type", "name"],
			key: "asset_type_name",
			sorter: true,
			sortDirections: ["descend", "ascend", "descend"],
			width: "20%",
			filters: state?.filterOptions?.assetTypes
				? state.filterOptions.assetTypes.map((type: AssetType) => ({
						text: type.name,
						value: type.name,
					}))
				: undefined,
			filteredValue: state?.filters?.asset_type_name
				? (state.filters.asset_type_name as string[])
				: undefined,
			filterSearch: true,
			render: (text, record) =>
				renderSkeletonOr(record.isLoading, <CopyableTableCell text={text} />),
		},
		{
			title: "Address",
			dataIndex: "address",
			key: "address1",
			sorter: true,
			sortDirections: ["descend", "ascend", "descend"],
			width: "30%",
			render: (text, record) =>
				renderSkeletonOr(
					record.isLoading,
					<CopyableTableCell text={text || ""} />,
				),
		},
		{
			title: "Submitting User",
			dataIndex: "submitting_user",
			key: "submitting_user",
			sorter: true,
			sortDirections: ["descend", "ascend", "descend"],
			render: (text, record) =>
				renderSkeletonOr(
					record.isLoading,
					<CopyableTableCell text={text || ""} />,
				),
		},
		{
			title: "Submission Date",
			dataIndex: "submitted_date",
			key: "submitted_date",
			sorter: true,
			sortDirections: ["descend", "ascend", "descend"],
			render: (text, record) =>
				renderSkeletonOr(
					record.isLoading,
					<CopyableTableCell text={text ? parseDateToLocalString(text) : ""} />,
					100,
				),
		},
	];

	// ----------------------------------
	//  PCA-specific columns
	// ----------------------------------
	const PCAColumns: ColumnsType<any> = [
		{
			title: "Address",
			dataIndex: "address",
			key: "address1",
			sorter: true,
			sortDirections: ["descend", "ascend", "descend"],
			width: "30%",
			render: (text, record) =>
				renderSkeletonOr(
					record.isLoading,
					<CopyableTableCell text={text || ""} />,
				),
		},
		{
			title: "Submitting User",
			dataIndex: "submitting_user",
			key: "submitting_user",
			sorter: true,
			sortDirections: ["descend", "ascend", "descend"],
			render: (text, record) =>
				renderSkeletonOr(
					record.isLoading,
					<CopyableTableCell text={text || ""} />,
				),
		},
		{
			title: "Submission Date",
			dataIndex: "submitted_date",
			key: "submitted_date",
			sorter: true,
			sortDirections: ["descend", "ascend", "descend"],
			render: (text: string | undefined, record) =>
				renderSkeletonOr(
					record.isLoading,
					text ? (
						<CopyableTableCell text={parseDateToLocalString(text)} />
					) : (
						<CopyableTableCell text="No Date" />
					),
					100,
				),
		},
	];

	// ----------------------------------
	//  Cushman columns
	// ----------------------------------
	const cushmanColumns: ColumnsType<any> = [
		{
			title: "Site Name",
			dataIndex: "address",
			key: "address",
			sorter: true,
			sortDirections: ["descend", "ascend", "descend"],
			width: "30%",
			render: (text, record) =>
				renderSkeletonOr(
					record.isLoading,
					<CopyableTableCell text={text || ""} />,
				),
		},
		{
			title: "Submitting User",
			dataIndex: "submitting_user",
			key: "submitting_user",
			sorter: true,
			sortDirections: ["descend", "ascend", "descend"],
			render: (text, record) =>
				renderSkeletonOr(
					record.isLoading,
					<CopyableTableCell text={text || ""} />,
				),
		},
		{
			title: "Submission Date",
			dataIndex: "submitted_date",
			key: "submitted_date",
			sorter: true,
			sortDirections: ["descend", "ascend", "descend"],
			render: (text: string | undefined, record) =>
				renderSkeletonOr(
					record.isLoading,
					text ? (
						<CopyableTableCell text={parseDateToLocalString(text)} />
					) : (
						<CopyableTableCell text="No Date" />
					),
					100,
				),
			width: "20%",
		},
		{
			title: "Approved",
			dataIndex: "is_approved",
			key: "is_approved",
			align: "center",
			width: "12%",
			render: (text, record) => {
				if (record.isLoading) {
					return <Skeleton.Input style={{ width: 60 }} active />;
				}
				if (text) {
					return <CheckOutlined className="green" />;
				}
				return <CloseOutlined className="red" />;
			},
		},
	];

	// ----------------------------------
	//  Decide which columns to return
	// ----------------------------------
	if (state?.selectedForm === 7) {
		// For form #7 => return PCA columns
		return PCAColumns;
	}

	switch (organization?.external_firebase_id) {
		case DB_ORG.CUSHMANWAKEFIELD:
			return cushmanColumns;
		default:
			return columns;
	}
};
