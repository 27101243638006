import logo from "@/assets/img/FacilityAI_Logo.png";
import Ameresco from "@/assets/img/ameresco.png";
import Boyscout from "@/assets/img/boyscout.png";
import Camcode from "@/assets/img/camcode.png";
import caret from "@/assets/img/caret-down.png";
import Cushman from "@/assets/img/cushman.png";
import HeroImgLeft from "@/assets/img/heroimg1.svg";
import HeroImgRight from "@/assets/img/heroimg2.svg";
import Pbs from "@/assets/img/pbs.png";
import Service from "@/assets/img/service.png";
import Wsj from "@/assets/img/wsj.png";
import { CarouselItem } from "@/layouts/landing/CarouselItem";
import { PartnerWrapper } from "@/layouts/landing/Partner";
import { Slideshow } from "@/layouts/landing/Slideshow/Slideshow";
import { useUserStore } from "@/stores/user-store";
import { type SetStateAction, useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { FaArrowRight } from "react-icons/fa";
import { FcMoneyTransfer } from "react-icons/fc";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdClose } from "react-icons/md";
import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
	Col,
	Navbar,
	Row,
} from "reactstrap";

const Landing = () => {
	const { themeMode } = useUserStore() as any;
	const [menuOpen, setMenuOpen] = useState(false);
	const toggle = () => setMenuOpen(!menuOpen);

	// accordions
	const [open, setOpen] = useState<any>("1");
	const toggleAccordion = (id: SetStateAction<string>) => {
		if (open === id) {
			setOpen(undefined);
		} else {
			setOpen(id);
		}
	};

	return (
		<div className="landing">
			<Navbar className="landing-navbar m-0 p-0">
				<div className="landing-nav-row d-flex mx-auto">
					<div className="d-flex justify-content-center justify-content-md-between w-100">
						<img className="landing-logo" src={logo} alt="" />
						<ul className="landing-nav-list d-flex align-items-center m-0 p-0">
							<li className="landing-nav-item mx-3">
								<span className="landing-nav-link">Products</span>
								<img className="landing-caret" src={caret} alt="" />
								<ul className="landing-sub-menu">
									<li className="sub-menu-item py-2">
										<span>Sub Item link</span>
									</li>
									<li className="sub-menu-item py-2">
										<span>Sub Item link</span>
									</li>
									<li className="sub-menu-item py-2">
										<span>Sub Item link</span>
									</li>
									<li className="sub-menu-item py-2">
										<span>Sub Item link</span>
									</li>
								</ul>
							</li>
							<li className="landing-nav-item mx-3">
								<span className="landing-nav-link">About Us</span>
								<img className="landing-caret" src={caret} alt="" />
								<ul className="landing-sub-menu">
									<li className="sub-menu-item py-2">
										<span>Sub Item link</span>
									</li>
									<li className="sub-menu-item py-2">
										<span>Sub Item link</span>
									</li>
									<li className="sub-menu-item py-2">
										<span>Sub Item link</span>
									</li>
									<li className="sub-menu-item py-2">
										<span>Sub Item link</span>
									</li>
								</ul>
							</li>
							<li className="landing-nav-item mx-3">
								<span className="landing-nav-link">Resources</span>
								<img className="landing-caret" src={caret} alt="" />
							</li>
						</ul>
						<div className="landing-nav-right d-flex align-items-center">
							<button className="landing-btn nav-btn">Request Demo</button>
							<a href="/login" className="landing-login">
								Login
							</a>
						</div>

						<button className="landing-modal-btn text-purple" onClick={toggle}>
							{menuOpen ? (
								<MdClose className="fs-1" />
							) : (
								<GiHamburgerMenu className="fs-2 mx-1" />
							)}
						</button>
						<div
							className={`${
								menuOpen ? "landing-modal-active" : "landing-modal-inactive"
							} `}
						>
							<Accordion
								className="mb-4"
								flush
								open={open}
								toggle={toggleAccordion}
							>
								<AccordionItem>
									<AccordionHeader targetId="1">Products</AccordionHeader>
									<AccordionBody accordionId="1">
										<div className="d-flex flex-column">
											<span className="text-purple p-2">Modal Item</span>
											<span className="text-purple p-2">Modal Item</span>
											<span className="text-purple p-2">Modal Item</span>
										</div>
									</AccordionBody>
								</AccordionItem>
								<AccordionItem>
									<AccordionHeader targetId="2">About Us</AccordionHeader>
									<AccordionBody accordionId="2">
										<div className="d-flex flex-column">
											<span className="text-purple p-2">Modal Item</span>
											<span className="text-purple p-2">Modal Item</span>
											<span className="text-purple p-2">Modal Item</span>
										</div>
									</AccordionBody>
								</AccordionItem>
								<AccordionItem>
									<AccordionHeader targetId="3">Resources</AccordionHeader>
									<AccordionBody accordionId="3">
										<div className="d-flex flex-column">
											<span className="text-purple p-2">Modal Item</span>
											<span className="text-purple p-2">Modal Item</span>
											<span className="text-purple p-2">Modal Item</span>
										</div>
									</AccordionBody>
								</AccordionItem>
							</Accordion>

							<a className="text-pe-green" href="/login">
								<button className="w-100 py-3 mb-4 outline-btn d-flex align-items-center justify-content-center text-pe-green">
									Log in
								</button>
							</a>
							<button className="w-100 py-3 landing-btn">Request Demo</button>
						</div>
					</div>
				</div>
			</Navbar>

			<section className="hero-area">
				<div className="d-flex flex-column-reverse flex-lg-row justify-content-between align-items-center mx-4">
					<img className="hero-img" src={HeroImgLeft} alt="hero" />
					<div className="hero-text-wrapper">
						<h1 className="hero-title text-center fw-bold">
							The Future of Facilities Management.
						</h1>
						<h4 className="hero-subtitle text-center mx-auto my-5">
							There&apos;s no easier way to manage the lifecycle of your assets,{" "}
							<i>intelligently</i>.
						</h4>
						<div className="d-flex flex-column flex-lg-row justify-content-center align-items-center hero-buttons">
							<button className="landing-btn py-3 landing-btn-shadow">
								Request Demo
							</button>
							<button className="outline-btn py-3">Get API Keys</button>
						</div>
					</div>
					<img className="hero-img mobile-none" src={HeroImgRight} alt="hero" />
				</div>
				<div className="brands-wrapper d-flex justify-content-center align-items-center">
					<p className="brands-para fw-bold fs-4 w-20">
						Trusted by Industry Leaders and Innovators
					</p>
				</div>
			</section>

			<section className="landing-card-section">
				<div className="landing-container">
					<div className="landing-row">
						<h1 className="fw-bold text-center mb-5">
							A Fully Integrated{" "}
							<span className="text-pe-green fw-bold">
								Asset Management System
							</span>{" "}
						</h1>
						<Row className="mb-1 mb-lg-4">
							<Col className="col-12 col-md-6">
								<div className="landing-large-card bg-purple bg-white">
									<h3 className="landing-card-title mb-2 fw-bold">
										Income & Employment Verification
									</h3>
									<p className="landing-card-subtitle py-2 mb-2">
										Lorem ipsum dolor sit amet consectetur adipisicing elit.
										Officia, facilis!
									</p>
									<div className="d-flex align-items-center">
										<span className="text-pe-green fw-bold fs-6">
											Learn more
										</span>
										<div className="landing-card-arrow-wrapper text-white d-flex justify-content-center align-items-center mx-2">
											<FaArrowRight />
										</div>
										<FcMoneyTransfer className="landing-card-icon" />
									</div>
								</div>
							</Col>

							<Col className="col-12 col-md-6">
								<div className="landing-large-card bg-green">
									<h3 className="landing-card-title mb-2 fw-bold text-white">
										Income & Employment Verification
									</h3>
									<p className="landing-card-subtitle py-2 mb-2 text-white">
										Lorem ipsum dolor sit amet consectetur adipisicing elit.
										Officia, facilis!
									</p>
									<div className="d-flex align-items-center">
										<span className="text-pe-green fw-bold fs-6 text-white">
											Learn more
										</span>
										<div className="landing-card-arrow-wrapper bg-white d-flex justify-content-center align-items-center mx-2">
											<FaArrowRight className="text-pe-green" />
										</div>
										<FcMoneyTransfer className="landing-card-icon" />
									</div>
								</div>
							</Col>
						</Row>

						<Row>
							<Col className="col-12 col-md-6 col-lg-4">
								<div className="landing-large-card bg-purple">
									<h3 className="landing-card-title mb-2 fw-bold text-white">
										Earned Wage Access
									</h3>
									<p className="landing-card-subtitle py-2 mb-2 text-white">
										Lorem ipsum dolor sit amet consectetur adipisicing elit.
										Officia, facilis!
									</p>
									<div className="d-flex align-items-center">
										<span className="text-pe-green fw-bold fs-6 text-white">
											Learn more
										</span>
										<div className="landing-card-arrow-wrapper bg-white d-flex justify-content-center align-items-center mx-2">
											<FaArrowRight className="text-purple" />
										</div>
										<FcMoneyTransfer className="landing-card-icon" />
									</div>
								</div>
							</Col>
							<Col className="col-12 col-md-6 col-lg-4">
								<div className="landing-large-card bg-white">
									<h3 className="landing-card-title mb-2 fw-bold">
										Earned Wage Access
									</h3>
									<p className="landing-card-subtitle py-2 mb-2">
										Lorem ipsum dolor sit amet consectetur adipisicing elit.
										Officia, facilis!
									</p>
									<div className="d-flex align-items-center">
										<span className="text-pe-green fw-bold fs-6">
											Learn more
										</span>
										<div className="landing-card-arrow-wrapper d-flex justify-content-center align-items-center mx-2">
											<FaArrowRight className="text-white" />
										</div>
										<FcMoneyTransfer className="landing-card-icon" />
									</div>
								</div>
							</Col>
							<Col className="col-12 col-md-12 col-lg-4">
								<div className="landing-large-card bg-teal">
									<h3 className="landing-card-title mb-2 fw-bold text-white">
										Earned Wage Access
									</h3>
									<p className="landing-card-subtitle py-2 mb-2 text-white">
										Lorem ipsum dolor sit amet consectetur adipisicing elit.
										Officia, facilis!
									</p>
									<div className="d-flex align-items-center">
										<span className="text-pe-green fw-bold fs-6 text-white">
											Learn more
										</span>
										<div className="landing-card-arrow-wrapper bg-white d-flex justify-content-center align-items-center mx-2">
											<FaArrowRight className="text-teal" />
										</div>
										<FcMoneyTransfer className="landing-card-icon" />
									</div>
								</div>
							</Col>
						</Row>
					</div>
				</div>
			</section>

			<section className="d-flex flex-column justify-content-center bg-gray">
				<div className="landing-container">
					<div className="landing-row d-flex flex-column align-items-center">
						<h1 className="text-center fw-bold mb-5">
							Best-in Class{" "}
							<span className="text-pe-green fw-bold">Management</span>{" "}
						</h1>
						<h5 className="text-center mb-5">
							Lorem ipsum, dolor sit amet consectetur adipisicing elit.
							Aspernatur, consequuntur.
						</h5>
						<Row className="mb-5">
							<Col className="col-12 col-lg-4">
								<div className="carousel-card bg-white">
									<h1 className="landing-card-title mb-2 fw-bold mx-4 mt-4">
										50+
									</h1>
									<p className="landing-card-subtitle py-2 mb-5 mx-4">
										Lorem ipsum dolor sit amet consectetur adipisicing elit.
										Officia, facilis!
									</p>
									<div className="carousel-side-fade">
										<div className="carousel-wrapper d-flex">
											<CarouselItem />
											<CarouselItem />
											<CarouselItem />
											<CarouselItem />
											<CarouselItem />
											<CarouselItem />
											<CarouselItem />
											<CarouselItem />
											<CarouselItem />
											<CarouselItem />
										</div>
									</div>
								</div>
							</Col>
							<Col className="col-12 col-lg-4">
								<div className="carousel-card bg-white">
									<h1 className="landing-card-title mb-2 fw-bold mx-4 mt-4">
										99%
									</h1>
									<p className="landing-card-subtitle py-2 mx-4">
										Lorem ipsum dolor sit amet consectetur adipisicing elit.
										Officia, facilis!
									</p>
									<ProgressBar
										className="landing-bar text-pe-green mx-4"
										animated
										now={95}
									/>
								</div>
							</Col>
							<Col className="col-12 col-lg-4">
								<div className="carousel-card bg-white">
									<h1 className="landing-card-title mb-2 fw-bold  mx-4 mt-4">
										13,000+
									</h1>
									<p className="landing-card-subtitle py-2 mb-5 mx-4">
										Lorem ipsum dolor sit amet consectetur adipisicing elit.
										Officia, facilis!
									</p>
									<div className="carousel-side-fade">
										<div className="carousel-wrapper d-flex">
											<CarouselItem />
											<CarouselItem />
											<CarouselItem />
											<CarouselItem />
											<CarouselItem />
											<CarouselItem />
											<CarouselItem />
											<CarouselItem />
											<CarouselItem />
											<CarouselItem />
										</div>
									</div>
								</div>
							</Col>
						</Row>
						<button className="outline-btn px-5 py-3">
							Learn More About This
						</button>
					</div>
				</div>
			</section>

			<section>
				<div className="landing-container">
					<div id="demo-row">
						<div className="d-flex justify-content-between mb-5">
							<h1 className="fw-bold fs-1">Bring all your data together.</h1>
							<div>
								<p className="fs-5">
									Lorem ipsum dolor sit amet consectetur
									<br />
									adipisicing elit. Exercitationem, sequi!
								</p>
								<button className="landing-btn py-3">Learn about</button>
							</div>
						</div>
						<div className="d-flex justify-content-between align-items-center">
							<div>
								<div className="move-right">
									<PartnerWrapper>
										<img
											className="w-100 rounded-circle"
											src={Cushman}
											alt=""
										/>
									</PartnerWrapper>
								</div>

								<div className="d-flex">
									<PartnerWrapper bigger border="border-green" shadow="green">
										<img className="w-100 rounded-circle" src={Wsj} alt="" />
									</PartnerWrapper>
								</div>

								<div className="move-right">
									<PartnerWrapper>
										<img
											className="w-100 rounded-circle"
											src={Service}
											alt=""
										/>
									</PartnerWrapper>
								</div>

								<div>
									<PartnerWrapper>
										<img
											className="w-100 rounded-circle"
											src={Ameresco}
											alt=""
										/>
									</PartnerWrapper>
								</div>
							</div>

							<Slideshow />

							<div>
								<div className="move-left">
									<PartnerWrapper>
										<img
											className="w-100 rounded-circle"
											src={Cushman}
											alt=""
										/>
									</PartnerWrapper>
								</div>
								<div>
									<PartnerWrapper bigger border="border-green" shadow="green">
										<img className="w-100 rounded-circle" src={Pbs} alt="" />
									</PartnerWrapper>
								</div>
								<div className="move-left">
									<PartnerWrapper>
										<img
											className="w-100 rounded-circle"
											src={Boyscout}
											alt=""
										/>
									</PartnerWrapper>
								</div>
								<PartnerWrapper>
									<img className="w-100 rounded-circle" src={Camcode} alt="" />
								</PartnerWrapper>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export { Landing };
