import { ALFRED_SERVICE_URL } from "@/constants/env";
import type { StringMap } from "@/models/abstract-types";
import type {
	SubmissionTableParams,
	SubmissionTableSearchResponse,
	SubmissionTableSearchResult,
} from "@/models/tables/submission-table/submission-table.model";
import { auth } from "@/services/auth-service";

// Converts json to b64, used for sending small amounts of json
const jsonToBase64 = (json: string | StringMap | Record<string, unknown>) => {
	return btoa(encodeURIComponent(JSON.stringify(json)));
};

const searchResponses = async (
	tableParams: SubmissionTableParams,
): Promise<SubmissionTableSearchResult> => {
	try {
		const {
			searchVal,
			selectedForm,
			selectedFormMode,
			pageNum,
			pageSize,
			sortBy,
			sortOrder,
			browserLocationId,
			filters,
			viewingDeleted,
		} = tableParams;

		const searchParams: Record<string, string> = {
			limit: pageSize.toString(),
			sort: sortBy,
			sort_direction: sortOrder,
			offset: ((pageNum - 1) * pageSize).toString(),
			form_mode: selectedFormMode,
			include_deleted: String(viewingDeleted),
		};

		// Only add search_value if it's a non-empty string
		if (searchVal?.trim()) {
			searchParams.search_value = searchVal.trim();
		}

		const params = new URLSearchParams(searchParams);

		if (selectedForm) {
			params.append("form_id", String(selectedForm));
		}
		if (browserLocationId) {
			params.append("location_id", browserLocationId);
		}
		if (filters && Object.keys(filters).length > 0) {
			params.append("column_filter", jsonToBase64(filters));
		}

		const url = `${ALFRED_SERVICE_URL}/submission/search?${params.toString()}`;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
			},
		});

		if (response.status !== 200) {
			const errorDetails = await response.json();
			throw new Error(
				`Failed to search for submissions: ${errorDetails.detail}`,
			);
		}

		const responseJson =
			(await response.json()) as SubmissionTableSearchResponse;
		return {
			results: responseJson.hits,
			count: responseJson.total_hits,
		};
	} catch (exception: unknown) {
		console.error(`Failed to search for submissions: ${exception}`);
		throw new Error("Failed to search for submissions");
	}
};

export { searchResponses };
