import { auth } from "@/services/auth-service";
import { csvParse } from "d3";
import { Button } from "reactstrap";

const CushmanDataUpdateTab = (props) => {
	const { setToast, organization } = props;

	const readCsvFile = async (e, callback) => {
		const file = e.target.files[0];
		const reader = new FileReader();

		reader.onload = async (e) => {
			const text = e.target.result;
			const parsedFile = csvParse(text);
			callback(parsedFile);
		};

		reader.readAsText(file);
	};

	const getCsvHeaders = async (field) => {
		const headers = organization.config.unique_fields.csvHeaders[field];
		return headers.sort().join("");
	};

	const uploadTickets = async (e) => {
		readCsvFile(e, async (tickets) => {
			const headersFromFireBase = await getCsvHeaders("tickets");
			let uploadedHeaders;

			if (tickets[0]) {
				uploadedHeaders = Object.keys(tickets[0]).sort().join("");
			}

			// when headers do not match correct format for absorbed ticket, abort
			const validHeaders = headersFromFireBase === uploadedHeaders;
			if (!validHeaders) {
				setToast({
					showing: true,
					title: "Error",
					message:
						"Invalid ticket columns. Please reformat to match appropriate columns.",
					type: "danger",
					position: "bottom",
				});
				setTimeout(() => {
					setToast({
						showing: false,
					});
				}, 5000);
				return;
			}

			// Call http request
			const res = await fetch(
				"https://us-central1-property-echo-4d747.cloudfunctions.net/updateCushmanAbsTickets",
				// 'http://localhost:5001/property-echo-4d747/us-central1/updateCushmanAbsTickets',
				{
					method: "POST",
					headers: {
						"Content-Type": "text/plain",
					},
					body: JSON.stringify({
						data: tickets,
						email: auth?.currentUser?.email,
					}),
				},
			);
			if (res.status === 200) {
				setToast({
					showing: true,
					title: "Success",
					message: "The absorbed tickets have been successfully uploaded.",
					type: "success",
					position: "bottom",
				});
			} else {
				setToast({
					showing: true,
					title: "Error",
					message:
						"The absorbed tickets upload failed. Please try again later.",
					type: "danger",
					position: "bottom",
				});
			}
			setTimeout(() => {
				setToast({
					showing: false,
				});
			}, 5000);
			return;
		});
	};

	const capitalize = (string) => {
		return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
	};

	const camelize = (oldString) => {
		const string = oldString
			.replace(/[\n\r]/g, " ")
			.replace(".", "")
			.replace("/", "");
		const arr = string.split(" ");
		let newString = "";
		for (let i = 0; i < arr.length; i++) {
			if (arr[i].toLowerCase() === "site") {
				arr.splice(i, 1);
				i--;
			} else if (i === 0) newString += arr[i].toLowerCase();
			else newString += capitalize(arr[i]);
		}
		return newString;
	};

	// Checks if the items in arr1 exist in arr2, but not vice versa
	const isOneWayArrMatch = (arr1, arr2) => {
		arr1.every((item) => arr2.includes(item));
	};

	const uploadLocations = async (e) => {
		readCsvFile(e, async (locations) => {
			// Camelize headers
			const newLocations = locations.map((item) => {
				const newItem = {};
				Object.keys(item).forEach((header) => {
					newItem[camelize(header)] = item[header];
				});
				return newItem;
			});
			// Check headers
			const headersFromFireBase = await getCsvHeaders("locations");
			let uploadedHeaders;

			if (newLocations[0]) {
				uploadedHeaders = Object.keys(newLocations[0]).sort().join("");
			}

			// when headers do not match correct format for absorbed ticket, abort
			let validHeaders = isOneWayArrMatch(uploadedHeaders, headersFromFireBase);
			const locIdField = uploadedHeaders[0].tririgaBuildingSystemId;
			if (locIdField === "" || locIdField === undefined) {
				validHeaders = false;
			}
			if (!validHeaders) {
				setToast({
					showing: true,
					title: "Error",
					message: "Invalid location columns. Please use valid column headers.",
					type: "danger",
					position: "bottom",
				});
				setTimeout(() => {
					setToast({
						showing: false,
					});
				}, 5000);
				return;
			}
			// Call http request
			const res = await fetch(
				"https://us-central1-property-echo-4d747.cloudfunctions.net/updateCushmanLocations",
				{
					method: "POST",
					headers: {
						"Content-Type": "text/plain",
					},
					body: JSON.stringify({
						data: newLocations,
						email: auth?.currentUser?.email,
					}),
				},
			);
			if (res.status === 200) {
				setToast({
					showing: true,
					title: "Success",
					message: "Your locations have been successfully updated.",
					type: "success",
					position: "bottom",
				});
				setTimeout(() => {
					setToast({
						showing: false,
					});
				}, 5000);
				return;
			}
			setToast({
				showing: true,
				title: "Error",
				message: "The locations update failed. Please try again later.",
				type: "danger",
				position: "bottom",
			});
			setTimeout(() => {
				setToast({
					showing: false,
				});
			}, 5000);
			return;
		});
	};

	return (
		<div className="d-flex flex-column align-items-start justify-content-center mt-3 gap-3">
			<Button color="success" size="sm">
				<label htmlFor="upload-tickets" className="upload-pm-label">
					Upload Absorbed Tickets
				</label>
				<input
					onChange={(e) => uploadTickets(e)}
					id="upload-tickets"
					type="file"
					accept=".csv"
				/>
			</Button>
			<Button color="success" size="sm">
				<label htmlFor="upload-locations" className="upload-pm-label">
					Update Locations
				</label>
				<input
					onChange={(e) => uploadLocations(e)}
					id="upload-locations"
					type="file"
					accept=".csv"
				/>
			</Button>
		</div>
	);
};

export { CushmanDataUpdateTab };
