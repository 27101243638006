/**********************************************************************
 * DYNAMIC SUBMISSION FORM (Section-by-Section Validation + Skip Wizard)
 **********************************************************************/

import {
	InboxOutlined,
	InfoCircleOutlined,
	LoadingOutlined,
} from "@ant-design/icons";
import {
	Button,
	Checkbox,
	Col,
	DatePicker,
	Form,
	type FormInstance,
	Image,
	Input,
	InputNumber,
	Modal,
	Progress,
	Radio,
	Row,
	Select,
	Spin,
	Steps,
	Tag,
	Tooltip,
	Typography,
	Upload,
	message,
	theme,
} from "antd";
import axios from "axios";
import dayjs from "dayjs";
import {
	FC,
	Fragment,
	type ReactNode,
	useCallback,
	useEffect,
	useMemo,
	useReducer,
	useRef,
	useState,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { DynamicLocationPicker } from "@/components/inputs/dynamic-location-input";
import { RatingInput } from "@/components/inputs/rating-input";
import { Loader } from "@/layouts/signed-in/Loader/Loader";
import { auth } from "@/services/auth-service";
import { uploadFieldCaptureFromDataUrl } from "@/services/storage-service";
import {
	createDraft,
	submitSubmission,
	updateDraft,
	updateSubmission,
} from "@/services/submission-service";
import { useFormStore, useUserStore } from "@/stores";
import { debounce } from "@/utils/debounce";
import type { UploadFile } from "antd/es/upload/interface";
import { LuXCircle } from "react-icons/lu";

import { ALFRED_SERVICE_URL } from "@/constants/env";
/**
 * If you have plugin logic, import from your plugin-service here.
 */
import {
	type DFSPluginFn,
	dfsPluginRegistry,
} from "@/services/dynamic-form-system-plugin.service";
import { getFormData } from "@/services/form-service";

const { Title } = Typography;
const { Dragger } = Upload;
const { Step } = Steps;

/*-----------------------------------------------------------------------
 * Types & Interfaces
 *-----------------------------------------------------------------------*/
export interface PluginEnhancement {
	validateStatus?: "success" | "warning" | "error" | "validating";
	help?: string;
	suffix?: ReactNode;
	loading?: boolean;
}
export interface PluginEnhancementsState {
	[fieldName: string]: PluginEnhancement;
}

export interface DFSFieldOption {
	value: string | number;
	label: string;
}

export interface DFSFieldValidation {
	required?: boolean;
	pattern?: RegExp;
	minLength?: number;
	maxLength?: number;
	min?: number;
	max?: number;
	message?: string;
}

export interface DFSFieldDataSource {
	type: "api" | "static" | "function";
	endpoint?: string;
	options?: DFSFieldOption[];
	dependencies?: string[];
	metadata?: Record<string, unknown>;
	fetchFunction?: (
		values: Record<string, unknown>,
	) => Promise<DFSFieldOption[]>;
}

export type DFSConditionalOperator =
	| "equals"
	| "notEquals"
	| "in"
	| "notIn"
	| "greaterThan"
	| "lessThan"
	| "contains";

export interface DFSFieldConditional {
	dependsOn: string;
	operator?: DFSConditionalOperator;
	value?: unknown;
	values?: unknown[];
}

export interface DFSFieldFormat {
	formatFn?: string;
	inputSuffix?: string;
}

export interface DFSFieldComputed {
	dependsOn: string[];
	calculate: (() => number | string) | Function;
}

export interface DFSTooltipLink {
	url: string;
	label: string;
}

export interface DFSFieldTooltip {
	text?: string;
	html?: string;
	links?: DFSTooltipLink[];
}

export interface DFSFormField {
	type: string;
	name?: string; // needed for antd form
	key?: string;
	responseTag?: string;
	label?: string;
	tooltip?: DFSFieldTooltip;
	placeholder?: string;
	required?: boolean;
	disabled?: boolean; // used for skipping
	hide?: boolean;
	options?: DFSFieldOption[];
	dataSource?: DFSFieldDataSource;
	conditional?: DFSFieldConditional;
	multiple?: boolean;
	draggerProps?: {
		multiple?: boolean;
		maxCount?: number;
		accept?: string;
	};
	fields?: DFSFormField[]; // for group or dynamicList
	countField?: string; // for dynamicList
	validation?: DFSFieldValidation | DFSFieldValidation[];
	note?: string;
	format?: DFSFieldFormat;
	computed?: DFSFieldComputed;
	value?: unknown;
	metadata?: Record<string, unknown>;
}

export interface DFSFormSection {
	title: string;
	description?: string;
	fields?: DFSFormField[];
	questions?: DFSFormField[];
	metadata?: Record<string, unknown>;
}

export interface DFSFormConfig {
	config: {
		sections: DFSFormSection[];
		metadata?: Record<string, unknown>;
		formVersion?: string;
		formName?: string;
		logo?: string;
	};
}

export interface DFSFormState {
	currentStep: number;
	loadingFields: Record<string, boolean>;
	fieldOptions: Record<string, DFSFieldOption[]>;
	dynamicListState: Record<string, number>;
	submitState: Record<string, any>;
}

export interface DFSAction {
	type: string;
	payload?: any;
}

/*-----------------------------------------------------------------------
 * Reducer & Initial State
 *-----------------------------------------------------------------------*/
export const initialState: DFSFormState = {
	currentStep: 0,
	loadingFields: {},
	fieldOptions: {},
	dynamicListState: {},
	submitState: {},
};

export function formReducer(
	state: DFSFormState,
	action: DFSAction,
): DFSFormState {
	switch (action.type) {
		case "SET_CURRENT_STEP":
			return { ...state, currentStep: action.payload };
		case "SET_LOADING_FIELD":
			return {
				...state,
				loadingFields: { ...state.loadingFields, ...action.payload },
			};
		case "SET_FIELD_OPTIONS":
			return {
				...state,
				fieldOptions: { ...state.fieldOptions, ...action.payload },
			};
		case "SET_DYNAMIC_LIST_STATE":
			return {
				...state,
				dynamicListState: { ...state.dynamicListState, ...action.payload },
			};
		case "SET_SUBMIT_STATE":
			return {
				...state,
				submitState: { ...state.submitState, ...action.payload },
			};
		case "RESET_FORM_STATE":
			return {
				...initialState,
				fieldOptions: state.fieldOptions,
			};

		default:
			return state;
	}
}

/*-----------------------------------------------------------------------
 * Utility Functions
 *-----------------------------------------------------------------------*/

/** For dynamicList: get the current count from .countField */
function getDynamicListCount(
	allValues: Record<string, any>,
	field: DFSFormField,
): number {
	if (!field.countField || !field.name) return 0;

	let countFieldPath = field.countField;
	// If parent's name has a dot, we might need to prefix countField
	if (!field.countField.includes(".") && field.name.includes(".")) {
		const pathSegments = field.name.split(".");
		pathSegments.pop();
		const parentPath = pathSegments.join(".");
		countFieldPath = parentPath
			? `${parentPath}.${field.countField}`
			: field.countField;
	}

	const val = allValues[countFieldPath];
	if (val === undefined || val === null) return 0;
	const num = Number.parseInt(val, 10);
	return Number.isNaN(num) ? 0 : num;
}

/** Return a tooltip if present */
function renderTooltipContent(tooltip: DFSFieldTooltip): ReactNode {
	const { text, html, links } = tooltip;
	return (
		<div style={{ maxWidth: "300px" }}>
			{html && (
				<div
					dangerouslySetInnerHTML={{ __html: html }}
					style={{ marginBottom: text || links ? "8px" : 0 }}
				/>
			)}
			{!html && text && (
				<div style={{ marginBottom: links ? "8px" : 0 }}>{text}</div>
			)}
			{links && links.length > 0 && (
				<ul style={{ paddingLeft: "18px", margin: 0 }}>
					{links.map((link, idx) => (
						<li key={`${link.url}-${idx}`}>
							<a
								style={{ color: "white" }}
								href={link.url}
								target="_blank"
								rel="noopener noreferrer"
							>
								{link.label}
							</a>
						</li>
					))}
				</ul>
			)}
		</div>
	);
}

/** Build label + tooltip if needed */
function getFieldLabel(field: DFSFormField): ReactNode {
	if (!field.label && !field.tooltip) return field.label || null;
	if (!field.tooltip) return field.label;
	return (
		<span>
			{field.label}
			<Tooltip title={renderTooltipContent(field.tooltip)} placement="top">
				<InfoCircleOutlined style={{ marginLeft: 8, cursor: "pointer" }} />
			</Tooltip>
		</span>
	);
}

/** Recursively gather all field names for a set of fields */
function getFieldNames(fields?: DFSFormField[]): string[] {
	if (!fields) return [];
	let names: string[] = [];
	for (const f of fields) {
		if (f.type === "group" || f.type === "dynamicList") {
			if (f.fields) {
				names = names.concat(getFieldNames(f.fields));
			}
		} else {
			const fieldName = f.name || f.responseTag || f.key;
			if (fieldName) names.push(fieldName);
		}
	}
	return names;
}

/** Recursively gather all fields in all sections */
function getAllFields(sections: DFSFormSection[]): DFSFormField[] {
	const all: DFSFormField[] = [];
	sections.forEach((sec) => {
		const fs = sec.fields || sec.questions || [];
		all.push(...getAllFieldsFromArray(fs));
	});
	return all;
}
function getAllFieldsFromArray(fields: DFSFormField[]): DFSFormField[] {
	let all: DFSFormField[] = [];
	for (const f of fields) {
		all.push(f);
		if (f.fields && f.fields.length > 0) {
			all.push(...getAllFieldsFromArray(f.fields));
		}
	}
	return all;
}

/** If the user wrote a computed field as string, convert to a real JS function. */
function prepareComputedFields(formConfig: DFSFormConfig) {
	const allFields = getAllFields(formConfig.config.sections);
	allFields.forEach((field) => {
		if (field.computed && typeof field.computed.calculate === "string") {
			try {
				const rawExpr = field.computed.calculate.trim();
				const safeExpr = sanitizeComputeExpression(
					rawExpr,
					field.computed.dependsOn,
				);
				const varDeclarations = (field.computed.dependsOn || [])
					.map((dep) => {
						return `
							const ${dep} = (function() {
								const val = values?.${dep};
								if (typeof val === 'number') return val;
								if (val === null || val === undefined) return 0;
								const num = Number(val);
								return isNaN(num) ? 0 : num;
							})();
						`;
					})
					.join("\n");

				const fnBody = `
					try {
						${varDeclarations}
						const result = ${safeExpr};
						return result;
					} catch (error) {
						console.error('Error in computed calc:', error);
						return 0;
					}
				`;

				const computeFn = new Function("values", fnBody);
				// Test invocation
				computeFn({});
				field.computed.calculate = computeFn;
			} catch (error) {
				console.error(`Error preparing computed field ${field.name}:`, error);
				field.computed.calculate = () => 0;
			}
		}
	});
}

/** Compute all fields that have a .computed => update form values accordingly */
function computeAllFields(
	allValues: Record<string, any>,
	formConfig: DFSFormConfig | null,
): Record<string, any> {
	if (!formConfig) return allValues;

	const allFields = getAllFields(formConfig.config.sections);
	const computedFields = allFields.filter((f) => f.computed);
	if (computedFields.length === 0) return allValues;

	let hasChanges = false;
	const newValues = { ...allValues };
	for (const field of computedFields) {
		if (!field.name || !field.computed?.calculate) continue;
		try {
			const calc = field.computed.calculate;
			if (typeof calc !== "function") continue;
			const newValue = calc(newValues);
			const numericValue = typeof newValue === "number" ? newValue : 0;
			if (numericValue !== newValues[field.name]) {
				newValues[field.name] = numericValue;
				hasChanges = true;
			}
		} catch (err) {
			console.error(`Error computing ${field.name}:`, err);
			if (newValues[field.name] !== 0) {
				newValues[field.name] = 0;
				hasChanges = true;
			}
		}
	}
	return hasChanges ? newValues : allValues;
}

/** Strip out "values." references and validate dependencies. */
function sanitizeComputeExpression(
	expr: string,
	dependsOn: string[] = [],
): string {
	let cleaned = expr.replace(/values\?\./g, "").replace(/values\./g, "");
	const usedVars = cleaned.match(/[a-zA-Z_][a-zA-Z0-9_]*/g) || [];
	const unknownVars = usedVars.filter(
		(v) =>
			!dependsOn.includes(v) &&
			!["true", "false", "null", "undefined"].includes(v),
	);
	if (unknownVars.length > 0) {
		throw new Error(`Expression uses unknown vars: ${unknownVars.join(", ")}`);
	}
	return cleaned;
}

/** Build initial values from a fresh, empty form config (just default field values). */
function buildInitialValuesFromFormConfig(config: DFSFormConfig) {
	const initialValues: Record<string, any> = {};

	function processFields(fields: DFSFormField[], prefix = "") {
		fields.forEach((f) => {
			const fieldName = f.name || f.responseTag || f.key;
			if (!fieldName) return;
			const fullName = prefix ? `${prefix}.${fieldName}` : fieldName;

			if (f.value !== undefined) {
				if (f.type === "date" && f.value) {
					initialValues[fullName] = dayjs(new Date(f.value));
				} else if (f.type === "image" || f.type === "file") {
					initialValues[fullName] = Array.isArray(f.value)
						? f.value.map((url: string, idx: number) => ({
								uid: `-${idx}`,
								name: `file-${idx}`,
								status: "done",
								url,
								response: { url },
								thumbUrl: url,
							}))
						: [];
				} else {
					initialValues[fullName] = f.value;
				}
			}

			if (f.type === "group" && f.fields) {
				processFields(f.fields, fullName);
			}
			if (f.type === "dynamicList" && f.fields && f.countField) {
				const defaultCount = f.value?.length || 0;
				initialValues[f.countField] = defaultCount;
			}
		});
	}

	config.config.sections.forEach((sec) => {
		const arr = sec.fields || sec.questions || [];
		processFields(arr);
	});

	return initialValues;
}

/** Build initial values from a parseInputs object. */
function buildInitialValuesFromParsedInputs(parsedInputs: any) {
	const initialValues: Record<string, any> = {};
	const dynamicCounts: Record<string, number> = {};

	function processFields(fields: DFSFormField[], prefix = "") {
		fields.forEach((f) => {
			const fieldName = f.responseTag || f.name || f.key;
			if (!fieldName) return;
			const fullName = prefix ? `${prefix}.${fieldName}` : fieldName;

			if (f.type === "image" || f.type === "file") {
				if (Array.isArray(f.value)) {
					initialValues[fullName] = f.value.map((url: string, idx: number) => ({
						uid: `-${idx}`,
						name: `file-${idx}`,
						status: "done",
						url,
						response: { url },
						thumbUrl: url,
					}));
				} else {
					initialValues[fullName] = [];
				}
				return;
			}

			if (f.type === "date" && f.value) {
				initialValues[fullName] = dayjs(new Date(f.value));
				return;
			}

			if (f.type === "group" && f.fields && Array.isArray(f.value)) {
				// process group
				f.fields.forEach((subField) => {
					const sfName = subField.name || subField.key || subField.responseTag;
					if (!sfName) return;
					const subFull = `${fullName}.${sfName}`;
					const subVal = extractGroupValue(f.value, sfName, subField.type);
					initialValues[subFull] = subVal;
				});
				return;
			}

			if (f.type === "dynamicList" && Array.isArray(f.value)) {
				if (f.countField) {
					const cntPath = prefix ? `${prefix}.${f.countField}` : f.countField;
					initialValues[cntPath] = f.value.length;
					dynamicCounts[fullName] = f.value.length;
				}
				f.value.forEach((item: any, i: number) => {
					if (!f.fields) return;
					f.fields.forEach((sf) => {
						const sfName = sf.name || sf.key || sf.responseTag;
						if (!sfName) return;
						const sfFull = `${fullName}.${i}.${sfName}`;
						let val = item[sfName];
						if (sf.type === "image" || sf.type === "file") {
							if (Array.isArray(val)) {
								val = val.map((u: string, idx: number) => ({
									uid: `-${idx}`,
									name: `file-${idx}`,
									status: "done",
									url: u,
									response: { url: u },
									thumbUrl: u,
								}));
							} else {
								val = [];
							}
						} else if (sf.type === "date" && val) {
							val = dayjs(new Date(val));
						}
						initialValues[sfFull] = val ?? "";
					});
				});
				return;
			}

			if (f.fields && f.fields.length > 0) {
				processFields(f.fields, fullName);
				return;
			}

			initialValues[fullName] = f.value;
		});
	}

	const sections = parsedInputs.sections || [];
	sections.forEach((sec: any) => {
		const arr = sec.fields || sec.questions || [];
		processFields(arr);
	});

	return { initialValues, dynamicCounts };
}

function extractGroupValue(
	groupValueArr: any[],
	subFieldName: string,
	subFieldType: string,
) {
	// Some older structures come as arrays of name/value pairs
	const oldStyle = groupValueArr[0] && Array.isArray(groupValueArr[0]);
	if (oldStyle) {
		const firstArr = groupValueArr[0];
		const found = firstArr.find((el: any) => el?.name === subFieldName);
		if (found) {
			let val = found.value;
			if (subFieldType === "image" || subFieldType === "file") {
				if (Array.isArray(val)) {
					val = val.map((url: string, idx: number) => ({
						uid: `-${idx}`,
						name: `file-${idx}`,
						status: "done",
						url,
						response: { url },
						thumbUrl: url,
					}));
				} else {
					val = [];
				}
			} else if (subFieldType === "date" && val) {
				val = dayjs(new Date(val));
			}
			return val;
		}
		return null;
	}
	// Possibly new style => array of objects {name, value}
	const found = groupValueArr.find((v: any) => v.name === subFieldName);
	if (found) {
		let val = found.value;
		if (subFieldType === "image" || subFieldType === "file") {
			if (Array.isArray(val)) {
				val = val.map((url: string, idx: number) => ({
					uid: `-${idx}`,
					name: `file-${idx}`,
					status: "done",
					url,
					response: { url },
					thumbUrl: url,
				}));
			} else {
				val = [];
			}
		} else if (subFieldType === "date" && val) {
			val = dayjs(new Date(val));
		}
		return val;
	}
	return null;
}

/** If a field has parentField logic, filter the data accordingly. */
function getFilteredOptions(
	field: DFSFormField,
	data: Record<string, any>,
	values: Record<string, any>,
) {
	if (!field.dataSource?.metadata?.parentField) return data;

	const {
		parentField,
		filterKey = "id",
		optionsPath = "value",
	} = field.dataSource.metadata;

	const parentValue = values[parentField];
	if (!parentValue) return [];

	if (!Array.isArray(data)) {
		console.warn("data is not an array:", data);
		return [];
	}

	const matchingRecords = data.filter(
		(record) => record.label[filterKey] === parentValue,
	);
	const allOptions = matchingRecords.reduce(
		(acc, record) => {
			if (Array.isArray(record.label[optionsPath])) {
				acc.push(...record.label[optionsPath]);
			}
			return acc;
		},
		[] as Array<{ name: string; id: number }>,
	);

	return allOptions.map((opt) => ({
		label: opt.name,
		value: opt.id,
	}));
}

/** Convert the flattened antd formData => original JSON shape (like your DB format). */
function buildSubmissionData(
	formConfig: DFSFormConfig | null,
	formData: Record<string, any>,
): { title: string; sections: any[] } | null {
	if (!formConfig) return null;

	function processValue(field: DFSFormField, val: any): any {
		if (field.type === "image" || field.type === "file") {
			if (!val || !Array.isArray(val) || val.length === 0) return [];
			return val.map((f: any) => f.url || f.response?.url);
		}
		if (field.type === "date" && val && dayjs.isDayjs(val)) {
			return val.toISOString();
		}
		if (field.type === "location" && val && typeof val === "object") {
			return val.id || val;
		}
		return val;
	}

	function reconstructDynamicList(
		field: DFSFormField,
		fd: Record<string, any>,
		prefix: string,
	): any[] {
		if (!field.fields || !field.countField) return [];
		const cntPath = field.name || field.responseTag || field.key || "";
		const fullCountPath = prefix ? `${prefix}.${cntPath}` : cntPath;
		const cnt = getDynamicListCount(fd, { ...field, name: fullCountPath });
		const items: any[] = [];
		for (let i = 0; i < cnt; i++) {
			const itemObj: Record<string, any> = {};
			field.fields.forEach((subField) => {
				const sfName = subField.name || subField.responseTag || subField.key;
				if (!sfName) return;
				const fullPath = prefix
					? `${prefix}.${cntPath}.${i}.${sfName}`
					: `${cntPath}.${i}.${sfName}`;
				let val = fd[fullPath];
				if (subField.type === "dynamicList") {
					val = reconstructDynamicList(
						subField,
						fd,
						`${prefix ? prefix + "." : ""}${cntPath}.${i}`,
					);
				} else if (subField.fields && subField.fields.length > 0) {
					val = processSectionFields(
						subField.fields,
						fd,
						`${prefix ? prefix + "." : ""}${cntPath}.${i}`,
					);
				} else {
					val = processValue(subField, val);
				}
				itemObj[sfName] = val;
			});
			items.push(itemObj);
		}
		return items;
	}

	function processSectionFields(
		fields: DFSFormField[],
		fd: Record<string, any>,
		prefix = "",
	) {
		const results = [];
		fields.forEach((field) => {
			const fieldName = field.name || field.responseTag || field.key;
			if (!fieldName) return;
			const fullName = prefix ? `${prefix}.${fieldName}` : fieldName;

			let val = fd[fullName];
			if (field.type === "dynamicList") {
				val = reconstructDynamicList(field, fd, prefix);
			} else if (field.type === "group" && field.fields) {
				const groupArr: any[] = [];
				field.fields.forEach((sf) => {
					const sfName = sf.name || sf.responseTag || sf.key;
					if (!sfName) return;
					const subFull = `${fullName}.${sfName}`;
					let subVal = fd[subFull];
					subVal = processValue(sf, subVal);
					groupArr.push({ ...sf, name: sfName, value: subVal });
				});
				val = groupArr;
			} else if (field.fields && field.fields.length > 0) {
				val = processSectionFields(field.fields, fd, fullName);
			} else {
				val = processValue(field, val);
			}
			results.push({ ...field, value: val });
		});
		return results;
	}

	const sectionsOut: any[] = [];
	formConfig.config.sections.forEach((sec) => {
		const fArr = sec.fields || sec.questions || [];
		const secData = {
			title: sec.title,
			questions: processSectionFields(fArr, formData),
		};
		sectionsOut.push(secData);
	});

	return {
		title: formConfig.config.sections?.[0]?.title || "",
		sections: sectionsOut,
	};
}

/** Ensure each section's fields is an array and do minor prep. */
function preprocessSections(formConfig: DFSFormConfig) {
	formConfig.config.sections.forEach((section) => {
		if (section.fields) {
			section.fields = preprocessFields(section.fields);
		} else if (section.questions) {
			section.questions = preprocessFields(section.questions);
		}
	});
}
function preprocessFields(fields: DFSFormField[]): DFSFormField[] {
	return fields.map((f) => {
		const copy = { ...f };
		if (copy.fields && copy.fields.length > 0) {
			copy.fields = preprocessFields(copy.fields);
		}
		return copy;
	});
}

/** Helper: is the value effectively empty for a required field? */
function isValueEmpty(value: any, fieldType?: string): boolean {
	if (value === null || value === undefined) return true;
	if (value === "") return true;
	if (Array.isArray(value)) {
		if ((fieldType === "image" || fieldType === "file") && value.length === 0) {
			return true;
		}
	}
	if (fieldType === "date" && !value) return true;
	return false;
}

/*-----------------------------------------------------------------------
 * Main Component
 *-----------------------------------------------------------------------*/
export const DynamicSubmissionForm: FC = () => {
	const [form] = Form.useForm();
	const [formConfig, setFormConfig] = useState<DFSFormConfig | null>(null);
	const [formName, setFormName] = useState("");
	const [formLogo, setFormLogo] = useState("");
	const { token } = theme.useToken();

	const { user } = useUserStore();
	const { draftId, setDraftId, formData, setFormData } = useFormStore();
	const { id } = useParams();
	const location = useLocation();
	const navigate = useNavigate();
	const [isCompleted, setIsCompleted] = useState<boolean>(
		location.state?.isSubmissionCompleted,
	);

	// Endpoint response caching
	const [endpointCache, setEndpointCache] = useState<Record<string, unknown>>(
		{},
	);
	const requestCache = useRef<Record<string, Promise<any>>>({});

	// Preview logic
	const [previewVisible, setPreviewVisible] = useState(false);
	const [previewImage, setPreviewImage] = useState("");
	const [previewTitle, setPreviewTitle] = useState("");
	const [draftDisabled, setDraftDisabled] = useState(false);

	// Reducer
	const [state, dispatch] = useReducer(formReducer, initialState);
	const { fieldOptions } = state;

	// Internal data load
	const [internalDataLoaded, setInternalDataLoaded] = useState(false);
	const [shouldAutoSave, setShouldAutoSave] = useState(false);

	// Plugin UI
	const [pluginEnhancements, setPluginEnhancements] =
		useState<PluginEnhancementsState>({});

	// The wizard for skipping fields:
	const [skipWizardOpen, setSkipWizardOpen] = useState(false);
	const [skipWizardFields, setSkipWizardFields] = useState<
		{ name: string; label: string }[]
	>([]);
	const [skipWizardSelections, setSkipWizardSelections] = useState<
		Record<string, boolean>
	>({});
	// Add "finishAndNew" to the type:
	const [skipWizardOrigin, setSkipWizardOrigin] = useState<
		"finish" | "finishAndNew" | "manage" | null
	>(null);

	// We'll store a callback to run after the user closes the wizard (if "manage")
	const skipWizardCallback = useRef<() => void>();

	const formKey = useMemo(() => location.pathname, [location.pathname]);

	/** Clears all local state. */
	const clearState = useCallback(() => {
		saveProgressDebounced.cancel();
		setDraftId(undefined);
		setFormData({});
		setShouldAutoSave(false);
		setFormConfig(null);
		setFormName("");
		setFormLogo("");
		setPreviewVisible(false);
		setPreviewImage("");
		setPreviewTitle("");
		dispatch({ type: "SET_CURRENT_STEP", payload: 0 });
		dispatch({ type: "SET_FIELD_OPTIONS", payload: {} });
		dispatch({ type: "SET_DYNAMIC_LIST_STATE", payload: {} });
		form.resetFields();
		setInternalDataLoaded(false);
		setPluginEnhancements({});
		setSkipWizardOpen(false);
		setSkipWizardFields([]);
		setSkipWizardSelections({});
		setSkipWizardOrigin(null);
		skipWizardCallback.current = undefined;
	}, [form, setDraftId, setFormData]);

	useEffect(() => {
		// If the route changes, reset form
		clearState();
	}, [location.pathname, clearState]);

	/**
	 * 1. Pre-load any forcibly disabled fields from data
	 * 2. Compute if needed
	 */
	useEffect(() => {
		if (!formConfig || !internalDataLoaded) return;

		// Force-compute on mount
		const initialVals = form.getFieldsValue(true);
		const newVals = computeAllFields(initialVals, formConfig);
		if (newVals !== initialVals) {
			form.setFieldsValue(newVals);
			setFormData(newVals);
		}
	}, [formConfig, internalDataLoaded, form, setFormData]);

	useEffect(() => {
		const formIdFromState = (location.state?.formId as string) || id || null;

		const fetchForm = async () => {
			try {
				// Potential partial submission data from location.state
				let parsed: any;
				if (location.state?.inputs) {
					parsed = location.state.inputs;
				} else if (location.state?.responseData) {
					parsed = location.state.responseData;
				}

				// Always fetch the DB form config if we have a formId
				if (formIdFromState) {
					await getFormData(
						user?.organization?.external_firebase_id!,
						Number.parseInt(formIdFromState, 10),
						setFormName,
						setFormLogo,
						async (dbConfig) => {
							if (!dbConfig) {
								// If somehow no config, fallback to blank
								setFormConfig({ config: { sections: [] } });
								setInternalDataLoaded(true);
								return;
							}

							// If we got partial data => merge it into the DB config
							if (parsed?.sections) {
								mergeSubmissionIntoConfig(
									dbConfig.config.sections,
									parsed.sections,
								);
							}

							// Now preprocess, prepare computed, and build defaults
							preprocessSections(dbConfig);
							prepareComputedFields(dbConfig);

							// You can build initialValues from the merged config:
							const { initialValues, dynamicCounts } = parsed?.sections
								? buildInitialValuesFromParsedInputs({
										...parsed,
										sections: dbConfig.config.sections,
									})
								: {
										initialValues: buildInitialValuesFromFormConfig(dbConfig),
										dynamicCounts: {},
									};

							setFormData(initialValues);
							form.setFieldsValue(initialValues);
							setFormConfig(dbConfig);

							// (Optional) fetch dataSources for the fields:
							const allFs = getAllFields(dbConfig.config.sections);
							const promises = allFs
								.filter((f) => f.dataSource?.type === "api")
								.map((f) => fetchFieldData(f, initialValues));
							await Promise.all(promises);

							// If any dynamic list counts
							if (Object.keys(dynamicCounts).length > 0) {
								dispatch({
									type: "SET_DYNAMIC_LIST_STATE",
									payload: dynamicCounts,
								});
							}
						},
					);
					setShouldAutoSave(
						user?.organization.config?.auto_save_drafts === true,
					);
					setInternalDataLoaded(true);
				} else {
					// If no formId but we do have parsed data => build direct config
					if (parsed?.sections) {
						setFormLogo(
							parsed.logo || user?.organization?.config?.logo_url || "",
						);
						setFormName(parsed.title || "Form");

						const directConfig: DFSFormConfig = {
							config: { sections: parsed.sections },
						};
						preprocessSections(directConfig);
						prepareComputedFields(directConfig);

						const { initialValues, dynamicCounts } =
							buildInitialValuesFromParsedInputs(parsed);
						setFormData(initialValues);
						form.setFieldsValue(initialValues);
						setFormConfig(directConfig);

						// fetch dataSources if needed
						const allFs = getAllFields(directConfig.config.sections);
						const promises = allFs
							.filter((f) => f.dataSource?.type === "api")
							.map((f) => fetchFieldData(f, initialValues));
						await Promise.all(promises);

						if (Object.keys(dynamicCounts).length > 0) {
							dispatch({
								type: "SET_DYNAMIC_LIST_STATE",
								payload: dynamicCounts,
							});
						}
						setShouldAutoSave(
							user?.organization?.config?.auto_save_drafts === true,
						);
						setInternalDataLoaded(true);
					} else {
						// Fallback: no formId, no parsed data => blank
						setFormConfig({ config: { sections: [] } });
						setInternalDataLoaded(true);
					}
				}
			} catch (error) {
				console.error("Error initializing form:", error);
				message.error("Error initializing form.");
			}
		};

		fetchForm();
	}, [id, location.state, user?.organization, form, setFormData]);

	/**
	 * Fetch field data if needed (API, etc.)
	 */
	const fetchFieldData = useCallback(
		async (field: DFSFormField, values: Record<string, any> = {}) => {
			if (!field.name) return;
			dispatch({ type: "SET_LOADING_FIELD", payload: { [field.name]: true } });

			try {
				let data: DFSFieldOption[] = [];

				if (field.dataSource?.type === "api" && field.dataSource.endpoint) {
					const endpoint = field.dataSource.endpoint;

					let responseData: unknown;
					if (endpointCache[endpoint]) {
						responseData = endpointCache[endpoint];
					} else {
						if (!requestCache.current[endpoint]) {
							requestCache.current[endpoint] = (async () => {
								try {
									const params: Record<string, any> = {};
									if (field?.dataSource?.dependencies) {
										field.dataSource.dependencies.forEach((dep) => {
											params[dep] = values[dep];
										});
									}
									let fullEndpoint = endpoint;
									if (!endpoint.includes("facilityai.com")) {
										const baseUrl = ALFRED_SERVICE_URL || "";
										const endpointPath = endpoint.replace(
											/^https?:\/\/[^/]+/,
											"",
										);
										fullEndpoint = `${baseUrl}${endpointPath}`;
									}

									const tkn = await auth?.currentUser?.getIdToken();
									const resp = await axios.get(fullEndpoint, {
										params,
										headers: { Authorization: `Bearer ${tkn || ""}` },
									});

									setEndpointCache((prev) => ({
										...prev,
										[endpoint]: resp.data,
									}));
									return resp.data;
								} finally {
									delete requestCache.current[endpoint];
								}
							})();
						}
						responseData = await requestCache.current[endpoint];
					}

					if (responseData) {
						let fieldData = responseData;
						if (field.dataSource?.metadata?.responsePath) {
							const paths = (
								field.dataSource.metadata.responsePath as string
							).split(".");
							for (const path of paths) {
								fieldData = fieldData?.[path];
								if (!fieldData) break;
							}
						}
						if (fieldData && field.dataSource.metadata) {
							const labelKey = field.dataSource.metadata.label || "";
							const valueKey = field.dataSource.metadata.value || "";
							const arr = Array.isArray(fieldData) ? fieldData : [fieldData];
							data = arr.map((it: any) => ({
								label: labelKey ? it[labelKey] : it,
								value: valueKey ? it[valueKey] : it,
							}));
						} else {
							data = Array.isArray(fieldData) ? fieldData : [fieldData];
						}
					}
				} else if (field.dataSource?.type === "static") {
					data = field.dataSource.options || [];
				} else if (
					field.dataSource?.type === "function" &&
					field.dataSource.fetchFunction
				) {
					data = await field.dataSource.fetchFunction(values);
				}

				const filteredData = getFilteredOptions(field, data, values);
				dispatch({
					type: "SET_FIELD_OPTIONS",
					payload: { [field.name]: filteredData || data || [] },
				});
			} catch (err) {
				console.error("Failed fetching field data:", err);
			} finally {
				dispatch({
					type: "SET_LOADING_FIELD",
					payload: { [field.name]: false },
				});
			}
		},
		[endpointCache],
	);

	/** Debounced auto-save to drafts. */
	const saveProgressDebounced = useCallback(
		debounce(
			async (props: {
				formData: Record<string, any>;
				formId: string | null;
				existingDraftId: number | string | null;
			}) => {
				const { formData, formId, existingDraftId } = props;
				if (!formId || !formData || !formConfig) return;
				const locId =
					formData.location_id ||
					formData.location ||
					location.state?.locationId;
				if (!shouldAutoSave || !locId || draftDisabled) return;

				const dataToSave = buildSubmissionData(formConfig, formData);
				if (!dataToSave) return;

				try {
					if (!existingDraftId) {
						const newDraftId = await createDraft({
							location_id: locId,
							form_id: Number.parseInt(formId, 10),
							data: { ...dataToSave },
						});
						setDraftId(newDraftId);
						message.success("Draft saved successfully.");
					} else {
						await updateDraft({
							draft_id: existingDraftId,
							data: { ...dataToSave },
							form_id: Number.parseInt(formId, 10),
						});
						message.success("Draft updated successfully.");
					}
				} catch {
					message.error("Failed to save draft.");
				}
			},
			1500,
		),
		[formConfig, location, shouldAutoSave, draftDisabled, setDraftId],
	);

	/** Called whenever form values change => auto-save if enabled. */
	function attemptAutoSave(mergedData: Record<string, any>) {
		if (!formConfig || !shouldAutoSave || draftDisabled || isCompleted) return;
		const formIdFromLocation = (location.state?.formId as string) || id || null;
		saveProgressDebounced({
			formData: mergedData,
			formId: formIdFromLocation,
			existingDraftId: draftId || null,
		});
	}

	/** For image preview (antd Upload) */
	async function handlePreview(file: UploadFile) {
		let url = file.url || file.response?.url;
		if (!url && !file.preview && file.originFileObj) {
			url = (await getBase64(file.originFileObj)) as string;
			file.preview = url;
		}
		if (url) {
			setPreviewImage(url);
			setPreviewVisible(true);
			setPreviewTitle(file.name || url.substring(url.lastIndexOf("/") + 1));
		}
	}
	function getBase64(file: Blob): Promise<string | ArrayBuffer | null> {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (err) => reject(err);
		});
	}
	function normFile(e: any): UploadFile[] {
		if (!e) return [];
		return Array.isArray(e) ? e : e.fileList;
	}

	/** Evaluate conditionals => should we show the field? */
	function evaluateCondition(field: DFSFormField, frm: FormInstance): boolean {
		if (!field.conditional) return true;
		const { dependsOn, operator = "equals", value, values } = field.conditional;
		const allVals = frm.getFieldsValue(true);

		let actualPath = dependsOn;
		if (!dependsOn.includes(".") && field.name && field.name.includes(".")) {
			const parts = field.name.split(".");
			parts.pop();
			const prefix = parts.join(".");
			actualPath = `${prefix}.${dependsOn}`;
		}

		let dv = allVals[actualPath];
		if (
			operator === "notEquals" &&
			value === "" &&
			(dv === undefined || dv === null)
		) {
			dv = "";
		}

		switch (operator) {
			case "equals":
				return dv === value;
			case "notEquals":
				return dv !== value;
			case "in":
				return Array.isArray(values) && values.includes(dv);
			case "notIn":
				return Array.isArray(values) && !values.includes(dv);
			case "greaterThan":
				return typeof dv === "number" && dv > value;
			case "lessThan":
				return typeof dv === "number" && dv < value;
			case "contains":
				return Array.isArray(dv) && dv.includes(value);
			default:
				return true;
		}
	}

	/** Build validation rules => skip 'required' if field.disabled. */
	function buildValidationRules(field: DFSFormField) {
		const rules: any[] = [];
		if (!field.validation) return rules;

		const arr = Array.isArray(field.validation)
			? field.validation
			: [field.validation];
		for (const vObj of arr) {
			const { pattern, minLength, maxLength, min, max, message, required } =
				vObj;
			if (required && !field.disabled) {
				rules.push({
					required: true,
					message: message || `${field.label || "Field"} is required`,
				});
			}
			if (pattern) {
				rules.push({
					pattern: new RegExp(pattern),
					message: message || "Invalid format",
				});
			}
			if (typeof minLength === "number") {
				rules.push({
					min: minLength,
					message:
						message ||
						`${field.label || "Field"} must be at least ${minLength} characters`,
				});
			}
			if (typeof maxLength === "number") {
				rules.push({
					max: maxLength,
					message:
						message ||
						`${field.label || "Field"} must be at most ${maxLength} characters`,
				});
			}
			if (typeof min === "number") {
				rules.push({
					type: "number",
					min,
					message: message || `${field.label || "Field"} must be ≥ ${min}`,
				});
			}
			if (typeof max === "number") {
				rules.push({
					type: "number",
					max,
					message: message || `${field.label || "Field"} must be ≤ ${max}`,
				});
			}
		}
		return rules;
	}

	/** Renders a field (group/dynamicList => recursion) */
	function renderField(field: DFSFormField, frm: FormInstance): ReactNode {
		const fieldName = field.name || field.responseTag || field.key;
		if (!fieldName) return null;
		if (!evaluateCondition(field, frm)) return null;

		if (field.type === "group" && field.fields) {
			return (
				<Fragment key={fieldName}>
					{field.fields.map((sf, idx) => {
						const subName = sf.name || sf.responseTag || sf.key;
						if (!subName) return null;
						const nested: DFSFormField = {
							...sf,
							name: `${fieldName}.${subName}`,
						};
						return (
							<Fragment key={`${fieldName}-${idx}`}>
								{renderField(nested, frm)}
							</Fragment>
						);
					})}
				</Fragment>
			);
		}

		if (field.type === "dynamicList" && field.fields) {
			const allVals = frm.getFieldsValue(true);
			const cnt = getDynamicListCount(allVals, field);

			return (
				<Fragment key={fieldName}>
					{Array.from({ length: cnt }).map((_, i) => (
						<div key={`${fieldName}-${i}`} className="mb-4">
							{field.label && (
								<Tag className="my-2" color="purple">
									{field.label}
									{field.tooltip && (
										<Tooltip
											title={renderTooltipContent(field.tooltip)}
											placement="top"
										>
											<InfoCircleOutlined
												style={{ marginLeft: 8, cursor: "pointer" }}
											/>
										</Tooltip>
									)}{" "}
									{i + 1}
								</Tag>
							)}
							{field?.fields?.map((subFld) => {
								const subBaseName =
									(subFld.name || subFld.responseTag || subFld.key || "")
										.split(".")
										.pop() || "";
								const indexedSub: DFSFormField = {
									...subFld,
									name: `${field.name}.${i}.${subBaseName}`,
								};
								return (
									<Fragment key={`${fieldName}-${i}-${indexedSub.name}`}>
										{renderField(indexedSub, frm)}
									</Fragment>
								);
							})}
						</div>
					))}
				</Fragment>
			);
		}

		return renderAtomicField(field, frm);
	}

	/** Shared icon for "loading" states. */
	const LoadingSuffix = () => (
		<LoadingOutlined style={{ fontSize: 14, color: "#1890ff" }} spin />
	);

	/** Render actual Input/Select/etc. */
	function renderAtomicField(
		field: DFSFormField,
		frm: FormInstance,
	): ReactNode {
		const fieldName = field.name || field.responseTag || field.key;
		const fieldPlaceholder = field.placeholder || "";
		if (!fieldName) return null;

		const rules = buildValidationRules(field);
		let formatFn: ((val: string) => string) | undefined;
		if (field.format?.formatFn) {
			try {
				formatFn = eval(`(${field.format.formatFn})`);
			} catch {
				/* ignore */
			}
		}

		const pluginUi = pluginEnhancements[fieldName] || {};
		const isLoading = state.loadingFields[fieldName];
		const fieldSuffix = isLoading ? (
			<LoadingSuffix />
		) : pluginUi.loading ? (
			<LoadingSuffix />
		) : (
			(pluginUi.suffix ?? field.format?.inputSuffix)
		);

		const commonProps = {
			name: fieldName,
			label: getFieldLabel(field),
			rules,
			hidden: field.hide,
			validateStatus: pluginUi.validateStatus,
			help: pluginUi.help,
		} as const;

		switch (field.type) {
			case "text":
			case "textarea":
			case "number": {
				if (field.type === "number") {
					return (
						<Form.Item {...commonProps} key={fieldName}>
							<InputNumber
								style={{ width: "100%" }}
								disabled={field.disabled}
								placeholder={fieldPlaceholder}
								suffix={fieldSuffix}
							/>
						</Form.Item>
					);
				}
				const Component = field.type === "text" ? Input : Input.TextArea;
				const handleChange = (e: any) => {
					if (formatFn) {
						const val = e?.target?.value;
						if (typeof val === "string") {
							const formatted = formatFn(val);
							const newData = { ...formData, [fieldName]: formatted };
							setFormData(newData);
							frm.setFieldsValue(newData);
						}
					}
				};
				const className =
					fieldName === "serialNumber" || fieldName === "modelNumber"
						? "submission-detail-enhanced"
						: "";
				return (
					<Form.Item {...commonProps} key={fieldName}>
						<Component
							disabled={field.disabled || isLoading}
							placeholder={fieldPlaceholder}
							className={className}
							suffix={fieldSuffix}
							onChange={formatFn ? handleChange : undefined}
							style={{ width: "100%" }}
						/>
					</Form.Item>
				);
			}

			case "rating":
				return (
					<Form.Item {...commonProps} key={fieldName}>
						<RatingInput
							disabled={field.disabled || isLoading}
							count={field.options?.length || 5}
						/>
					</Form.Item>
				);

			case "select": {
				const opts = fieldOptions[fieldName] || field.options || [];
				return (
					<Form.Item {...commonProps} key={fieldName}>
						<Select
							suffixIcon={fieldSuffix}
							disabled={field.disabled || isLoading}
							placeholder={fieldPlaceholder}
							showSearch
							autoClearSearchValue
							optionFilterProp="children"
						>
							{opts.map((op) => (
								<Select.Option key={op.value} value={op.value}>
									{op.label}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
				);
			}

			case "date":
				return (
					<Form.Item {...commonProps} key={fieldName}>
						<DatePicker
							suffixIcon={fieldSuffix}
							format="MM/DD/YYYY"
							disabled={field.disabled || isLoading}
							placeholder={fieldPlaceholder}
							style={{ width: "100%" }}
						/>
					</Form.Item>
				);

			case "radio": {
				const radioOpts = fieldOptions[fieldName] || field.options || [];
				return (
					<Form.Item {...commonProps} key={fieldName}>
						<Radio.Group disabled={field.disabled || isLoading}>
							{radioOpts.map((op) => (
								<Radio key={op.value} value={op.value}>
									{op.label}
								</Radio>
							))}
						</Radio.Group>
					</Form.Item>
				);
			}

			case "checkbox":
				return (
					<Form.Item {...commonProps} key={fieldName} valuePropName="checked">
						<Input
							type="checkbox"
							disabled={field.disabled || isLoading}
							suffix={fieldSuffix}
						/>
					</Form.Item>
				);

			case "location":
				return (
					<Form.Item {...commonProps} key={fieldName}>
						<DynamicLocationPicker
							value={frm.getFieldValue(fieldName)}
							onChange={(val) => {
								const newData = { ...formData, [fieldName]: val };
								setFormData(newData);
								frm.setFieldsValue(newData);
							}}
							placeholder={fieldPlaceholder}
							disabled={field.disabled || isLoading}
						/>
					</Form.Item>
				);

			case "image":
			case "file": {
				const maxCount = field?.draggerProps?.maxCount || 1;
				const isDisabled = field.disabled || isLoading;

				return (
					<Form.Item
						{...commonProps}
						key={fieldName}
						valuePropName="fileList"
						getValueFromEvent={normFile}
					>
						<Dragger
							name={field.name}
							listType="picture-card"
							multiple={field?.draggerProps?.multiple || false}
							accept={field?.draggerProps?.accept || "*"}
							showUploadList={{
								showRemoveIcon: true,
								showPreviewIcon: true,
							}}
							disabled={isDisabled}
							maxCount={maxCount}
							beforeUpload={() => {
								const fileList = form.getFieldValue(fieldName) || [];
								if (fileList.length >= maxCount) {
									message.warning(`Max file count of ${maxCount} reached`);
									return Upload.LIST_IGNORE;
								}
								return true;
							}}
							customRequest={async (opts) => {
								const { file: rawFile, onSuccess, onError } = opts;
								try {
									const dataUrl = (await getBase64(rawFile as Blob)) as string;
									const resp = await uploadFieldCaptureFromDataUrl(dataUrl);
									const finalUrl = typeof resp === "string" ? resp : resp.url;
									onSuccess?.({ url: finalUrl });
								} catch (error) {
									onError?.(error as Error);
								}
							}}
							onChange={async (info) => {
								const { fileList, file } = info;
								frm.setFieldsValue({ [fieldName]: fileList });

								if (file.status === "done") {
									message.success(`${file.name} uploaded successfully`);
									const fileUrl = file.response?.url || file.url || "";

									const pluginName = field.metadata?.pluginName as string;
									if (pluginName && dfsPluginRegistry[pluginName]) {
										// Possibly process via plugin
										const propMap =
											(field.metadata?.propMap as Record<string, string>) || {};
										const targetFields = Object.values(propMap);
										// Mark them loading
										setPluginEnhancements((prev) => {
											const next = { ...prev };
											targetFields.forEach((tf) => {
												next[tf] = { ...prev[tf], loading: true };
											});
											return next;
										});

										try {
											const pluginFn: DFSPluginFn =
												dfsPluginRegistry[pluginName];
											const pluginDependencies =
												field.metadata?.pluginDependencies || {};
											const optionsFieldName = pluginDependencies.options;
											const theFieldOptions = optionsFieldName
												? state.fieldOptions[optionsFieldName]
												: undefined;

											const snapshot = useFormStore.getState().formData;
											const newFormDataWithFiles = {
												...snapshot,
												[fieldName]: fileList,
											};

											const { updatedFormData, uiPatches } = await pluginFn(
												field,
												newFormDataWithFiles,
												{
													fileUrl,
													formSections: formConfig?.config.sections,
													dependencies: pluginDependencies,
													fieldOptions: {
														[optionsFieldName]: theFieldOptions,
													},
												},
											);

											const latest = useFormStore.getState().formData;
											const changedKeys = Object.keys(updatedFormData).filter(
												(k) => updatedFormData[k] !== latest[k],
											);
											const merged = { ...latest };
											changedKeys.forEach((ck) => {
												merged[ck] = updatedFormData[ck];
											});
											merged[fieldName] = fileList;

											setFormData(merged);
											frm.setFieldsValue(merged);

											setPluginEnhancements((prev) => {
												const nextState = { ...prev };
												targetFields.forEach((tf) => {
													nextState[tf] = {
														...prev[tf],
														...uiPatches[tf],
														loading: false,
													};
												});
												return nextState;
											});
										} catch (error) {
											setPluginEnhancements((prev) => {
												const next = { ...prev };
												targetFields.forEach((tf) => {
													next[tf] = {
														...prev[tf],
														loading: false,
														validateStatus: "error",
														help: "Failed to process image",
													};
												});
												return next;
											});
											console.error("Plugin error:", error);
										}
									}
								} else if (file.status === "error") {
									message.error(`${file.name} upload failed.`);
								}
							}}
							onPreview={handlePreview}
						>
							<p className="ant-upload-drag-icon">
								<InboxOutlined />
							</p>
							<p className="ant-upload-text">Click or drag file to upload</p>
							{field?.draggerProps?.multiple && (
								<span className="ant-upload-hint">
									Multiple files supported ({maxCount} max)
								</span>
							)}
						</Dragger>
					</Form.Item>
				);
			}

			default:
				return null;
		}
	}

	/** Steps definition */
	const stepsConfig = useMemo(() => {
		if (!formConfig) return [];
		return formConfig.config.sections.map((sec) => {
			const fieldNames = getFieldNames(sec.fields || sec.questions);
			const content = (
				<Row gutter={[16, 16]}>
					{(sec.fields || sec.questions)?.map((fld, idx) => {
						const nm = fld.name || fld.responseTag || fld.key || `field-${idx}`;
						return (
							<Col xs={24} sm={24} md={24} lg={24} xl={24} key={`field-${nm}`}>
								{renderField(fld, form)}
							</Col>
						);
					})}
				</Row>
			);
			return { title: sec.title, fieldNames, content };
		});
	}, [formConfig, formData, form, pluginEnhancements, fieldOptions]);

	/** Move to specific step (index) */
	async function handleStepChange(idx: number) {
		if (idx < 0 || idx >= stepsConfig.length) return;
		// If user is going forward, we validate current step
		if (idx > state.currentStep) {
			await attemptStepNavigation(idx);
		} else {
			dispatch({ type: "SET_CURRENT_STEP", payload: idx });
		}
	}
	async function validateCurrentStep() {
		try {
			const fields = stepsConfig[state.currentStep]?.fieldNames || [];
			await form.validateFields(fields);
			return true;
		} catch {
			return false;
		}
	}

	async function next() {
		await attemptStepNavigation(state.currentStep + 1);
	}
	function prev() {
		if (state.currentStep > 0) {
			dispatch({ type: "SET_CURRENT_STEP", payload: state.currentStep - 1 });
		}
	}

	/**
	 * Merges the "value" (and optionally "disabled") from submission data
	 * into the config fields. Matches fields by `name`.
	 */
	function mergeSubmissionIntoConfig(
		configSections: DFSFormSection[],
		dataSections: any[], // e.g. dataSections[i] = { fields: [...] }
	) {
		for (let i = 0; i < configSections.length; i++) {
			const cfgSection = configSections[i];
			const dataSection = dataSections[i] ?? {};

			// The config might store fields in `section.fields`; data might be in `section.fields` or `section.questions`
			const cfgFields = cfgSection.fields || cfgSection.questions || [];
			const dataFields = dataSection.fields || dataSection.questions || [];

			// Merge each config field
			for (const cfgField of cfgFields) {
				const matchingDataField = dataFields.find(
					(df: any) => df.name === cfgField.name,
				);
				if (!matchingDataField) {
					// No matching field => skip
					continue;
				}

				// Overwrite value:
				if (matchingDataField.value !== undefined) {
					cfgField.value = matchingDataField.value;
				}

				// (Optional) Overwrite 'disabled':
				if (typeof matchingDataField.disabled === "boolean") {
					cfgField.disabled = matchingDataField.disabled;
				}

				// If nested fields (group / dynamicList), recurse:
				if (cfgField.fields && matchingDataField.fields) {
					mergeSubmissionIntoConfig(
						// Config "sub-section"
						[{ title: cfgField.name || "", fields: cfgField.fields }],
						// Data "sub-section"
						[{ fields: matchingDataField.fields }],
					);
				}
			}
		}
	}

	/** Check which required fields are missing (not disabled) on the given step. */
	function gatherMissingRequiredFieldsForSection(stepIndex: number): string[] {
		if (!formConfig || !stepsConfig[stepIndex]) return [];
		const missing: string[] = [];
		const section = formConfig.config.sections[stepIndex];
		const fields = section.fields || section.questions || [];

		function checkField(field: DFSFormField, prefix = "") {
			const fieldName = field.name || field.responseTag || field.key;
			if (!fieldName) return;
			const fullName = prefix ? `${prefix}.${fieldName}` : fieldName;

			// ignore disabled
			if (field.disabled) return;

			// if group => recurse
			if (field.type === "group" && field.fields) {
				field.fields.forEach((sf) => checkField(sf, fullName));
				return;
			}

			// if dynamicList => iterate each item
			if (field.type === "dynamicList" && field.fields) {
				const count = getDynamicListCount(form.getFieldsValue(true), field);
				for (let i = 0; i < count; i++) {
					field.fields.forEach((sf) => checkField(sf, `${fullName}.${i}`));
				}
				return;
			}

			// if hidden by conditional => skip
			if (!evaluateCondition(field, form)) return;

			// check if required
			const validations = Array.isArray(field.validation)
				? field.validation
				: field.validation
					? [field.validation]
					: [];
			const isRequired = validations.some((v) => v.required);
			if (!isRequired) return;

			const val = form.getFieldValue(fullName);
			if (isValueEmpty(val, field.type)) missing.push(fullName);
		}

		fields.forEach((f) => checkField(f));
		return missing;
	}

	/**
	 * If there's missing fields, open skip wizard with origin="manage" if it's a "Next" scenario,
	 * or origin="finish" if final submit.
	 */
	async function attemptStepNavigation(targetStep: number) {
		const missingRequired = gatherMissingRequiredFieldsForSection(
			state.currentStep,
		);

		if (missingRequired.length > 0) {
			// Open skip wizard in "manage" mode
			openSkipWizard(missingRequired, "manage", () =>
				dispatch({ type: "SET_CURRENT_STEP", payload: targetStep }),
			);
			return;
		}

		// If no missing required, do a normal antd validation
		try {
			await validateCurrentStep(); // form.validateFields(...) for the current step
			dispatch({ type: "SET_CURRENT_STEP", payload: targetStep });
		} catch {
			// If any other validation errors, remain on this step
			message.error("Please fix validation errors before proceeding.");
		}
	}

	/** Let user re-enable skipped fields on current step. */
	function handleManageSkippedClick() {
		const skippedInSection = getSkippedFieldsInSection(state.currentStep);
		const missingInSection = gatherMissingRequiredFieldsForSection(
			state.currentStep,
		);
		const unionSet = new Set([...skippedInSection, ...missingInSection]);
		openSkipWizard([...unionSet], "manage");
	}

	/** Which fields in this section are currently disabled (skipped)? */
	function getSkippedFieldsInSection(stepIndex: number): string[] {
		if (!formConfig) return [];
		const section = formConfig.config.sections[stepIndex];
		const fields = section.fields || section.questions || [];
		const skipped: string[] = [];

		function checkField(field: DFSFormField, prefix = "") {
			const fieldName = field.name || field.responseTag || field.key;
			if (!fieldName) return;
			const fullName = prefix ? `${prefix}.${fieldName}` : fieldName;

			// If the config says this field is disabled => it's "skipped."
			if (field.disabled) {
				skipped.push(fullName);
			}

			// Recurse if group or dynamicList
			if (field.type === "group" && field.fields) {
				field.fields.forEach((sf) => checkField(sf, fullName));
			} else if (field.type === "dynamicList" && field.fields) {
				const count = getDynamicListCount(form.getFieldsValue(true), field);
				for (let i = 0; i < count; i++) {
					field.fields.forEach((sf) => checkField(sf, `${fullName}.${i}`));
				}
			}
		}

		fields.forEach((f) => checkField(f));
		return skipped;
	}

	/** Open skip wizard, passing a callback for "manage" or final submit for "finish". */
	function openSkipWizard(
		fieldNames: string[],
		origin: "finish" | "finishAndNew" | "manage",
		cb?: () => void,
	) {
		skipWizardCallback.current = cb || undefined;
		setSkipWizardOrigin(origin);

		const allFs = getAllFields(formConfig?.config.sections || []);
		const labelMap = new Map<string, string>(
			allFs.map((f) => [f.name!, f.label || f.name!]),
		);

		const wizardFields = fieldNames
			.filter((nm) => labelMap.has(nm))
			.map((nm) => ({ name: nm, label: labelMap.get(nm)! }))
			.sort((a, b) => a.label.localeCompare(b.label));

		// Build selection map (checked => field is disabled or is "missing")
		const selMap: Record<string, boolean> = {};
		wizardFields.forEach((wf) => {
			const found = allFs.find((f) => f.name === wf.name);
			const isDisabled = found?.disabled === true;
			selMap[wf.name] = isDisabled || fieldNames.includes(wf.name);
		});

		setSkipWizardFields(wizardFields);
		setSkipWizardSelections(selMap);
		setSkipWizardOpen(true);
	}

	/** Apply the skip/unskip choices. */
	function handleSkipWizardOk() {
		if (!formConfig) {
			setSkipWizardOpen(false);
			return;
		}

		// 1) Copy formConfig
		const updatedConfig = { ...formConfig };
		updatedConfig.config = { ...formConfig.config };
		updatedConfig.config.sections = [...formConfig.config.sections];

		// 2) Apply skip/unskip choices
		Object.entries(skipWizardSelections).forEach(([fieldName, doSkip]) => {
			updateFieldStateInConfig(
				updatedConfig.config.sections,
				fieldName,
				doSkip,
			);
		});

		// 3) Update config & close
		setFormConfig(updatedConfig);
		setSkipWizardOpen(false);

		// 4) If skip wizard triggered by "Next", call its callback
		if (skipWizardOrigin === "manage") {
			form
				.validateFields(stepsConfig[state.currentStep].fieldNames)
				.then(() => {
					skipWizardCallback.current?.();
				})
				.catch(() => {
					message.error(
						"Please fill or skip all required fields before proceeding.",
					);
				});
		}
		// 5) If skip wizard triggered by final "Submit", check if it's normal or "submitAndNew"
		else if (skipWizardOrigin === "finish") {
			finalSubmit();
		} else if (skipWizardOrigin === "finishAndNew") {
			finalSubmitAndStartNew();
		}
	}

	function handleSkipWizardCancel() {
		setSkipWizardOpen(false);
	}

	/** Recursively set disabled state for the given fieldName in the config. */
	function updateFieldStateInConfig(
		sections: DFSFormSection[],
		fieldName: string,
		disabled: boolean,
	): boolean {
		for (const sec of sections) {
			const arr = sec.fields || sec.questions || [];
			if (processFields(arr, fieldName, disabled)) return true;
		}
		return false;

		function processFields(
			fields: DFSFormField[],
			fName: string,
			doDisable: boolean,
		): boolean {
			for (const fld of fields) {
				const nm = fld.name || fld.responseTag || fld.key;
				if (nm === fName) {
					fld.disabled = doDisable;
					return true;
				}
				if (fld.fields && fld.fields.length > 0) {
					if (processFields(fld.fields, fName, doDisable)) {
						return true;
					}
				}
			}
			return false;
		}
	}

	function getManageSkippedCount(): number {
		if (!formConfig) return 0;
		const stepIdx = state.currentStep;
		// If step index is out of range, no reason to show a button
		if (stepIdx < 0 || stepIdx >= (stepsConfig?.length ?? 0)) return 0;

		const section = formConfig.config.sections[stepIdx];
		if (!section) return 0;

		const fields = section.fields || section.questions || [];
		let skippedCount = 0;

		function checkField(field: DFSFormField) {
			if (field.disabled) skippedCount++;
			if (field.fields) {
				field.fields.forEach(checkField);
			}
		}
		fields.forEach(checkField);

		return skippedCount;
	}

	/** Recompute dynamicList counts whenever form changes */
	function updateAllDynamicListCounts(allVals: Record<string, any>) {
		if (!formConfig) return;
		const newState: Record<string, number> = {};
		const allFs = getAllFields(formConfig.config.sections);
		allFs.forEach((f) => {
			if (f.type === "dynamicList" && f.name) {
				const c = getDynamicListCount(allVals, f);
				newState[f.name] = c;
			}
		});
		if (Object.keys(newState).length > 0) {
			dispatch({
				type: "SET_DYNAMIC_LIST_STATE",
				payload: newState,
			});
		}
	}

	/** On form value change => compute fields + auto-save + dataSource deps */
	const onFormValuesChange = useCallback(
		(
			changedValues: Record<string, unknown>,
			allValues: Record<string, unknown>,
		) => {
			if (!formConfig) return;
			const mergedData = { ...formData, ...allValues };
			const computedData = computeAllFields(mergedData, formConfig);

			if (computedData !== mergedData) {
				form.setFieldsValue(computedData);
				setFormData(computedData);
				attemptAutoSave(computedData);
			} else {
				setFormData(mergedData);
				attemptAutoSave(mergedData);
			}

			updateAllDynamicListCounts(computedData);

			const changedKeys: string[] = Object.keys(changedValues);
			const allFs = getAllFields(formConfig.config.sections);
			allFs.forEach((f) => {
				// If a parentField changed, refetch
				if (
					f.dataSource?.metadata?.parentField &&
					changedKeys.includes(f?.dataSource?.metadata?.parentField)
				) {
					fetchFieldData(f, allValues);
				}
				// If dependencies changed, refetch
				if (
					f.dataSource?.dependencies?.some((dep) => changedKeys.includes(dep))
				) {
					fetchFieldData(f, computedData);
				}
			});
		},
		[formConfig, formData, form, fetchFieldData],
	);

	async function onFinish(submitType: "submit" | "submitAndNew" = "submit") {
		try {
			// Cancel any pending auto-saves and disable drafts
			saveProgressDebounced.cancel();
			setDraftDisabled(true);
			// Check for missing required fields on the last step
			const lastStepIndex = stepsConfig.length - 1;
			const missingRequired =
				gatherMissingRequiredFieldsForSection(lastStepIndex);

			// If fields are missing, show the skip wizard and exit early
			if (missingRequired.length > 0) {
				// IMPORTANT: Distinguish between normal finish vs. finishAndNew
				openSkipWizard(
					missingRequired,
					submitType === "submitAndNew" ? "finishAndNew" : "finish",
				);
				return;
			}

			// If no missing required fields, proceed:
			if (submitType === "submitAndNew") {
				await finalSubmitAndStartNew();
			} else {
				await finalSubmit();
			}
		} catch (err: any) {
			// Re-enable drafts if something went wrong
			setDraftDisabled(false);
			message.error("Please fix all validation errors.");
		}
	}

	async function submitFormData() {
		const locId =
			formData.location_id || formData.location || location.state?.locationId;
		const formIdFromState = (location.state?.formId as string) || id || null;

		if (!formIdFromState || !locId) {
			message.error(
				!formIdFromState ? "No formId provided." : "No location_id provided.",
			);
			return null;
		}

		const submissionData = buildSubmissionData(formConfig, formData);
		if (!submissionData) {
			message.error("Could not build submission data.");
			return null;
		}

		let submissionId =
			draftId || location.state?.draftId || location.state?.submissionId;

		if (isCompleted && submissionId) {
			await updateSubmission(submissionId!, submissionData, locId);
			message.success("Form updated successfully.");
		} else {
			if (!submissionId) {
				submissionId = await createDraft({
					location_id: locId,
					form_id: Number.parseInt(formIdFromState, 10),
					data: submissionData,
				});
			} else {
				await updateDraft({
					draft_id: submissionId,
					data: submissionData,
					form_id: Number.parseInt(formIdFromState, 10),
				});
			}
			await submitSubmission(submissionId, submissionData);
			message.success("Form submitted successfully.");
		}

		return { submissionId, formIdFromState, locId };
	}

	async function finalSubmit(): Promise<boolean> {
		dispatch({ type: "SET_SUBMIT_STATE", payload: { isSubmitting: true } });

		try {
			const result = await submitFormData();
			if (!result) return false;

			const { submissionId, formIdFromState, locId } = result;

			// Cancel any pending auto-saves and clear state
			saveProgressDebounced.cancel();
			clearState();

			// Navigate to submission detail page
			const navPath = `/locations/${locId}/submissions/${submissionId}`;
			navigate(navPath, {
				state: {
					submissionId,
					formId: formIdFromState,
					...location.state,
				},
			});
			return true;
		} catch (err) {
			console.error("Submission error:", err);
			message.error("Submission failed. Please fix errors and try again.");
			return false;
		} finally {
			dispatch({ type: "SET_SUBMIT_STATE", payload: { isSubmitting: false } });
		}
	}

	async function finalSubmitAndStartNew() {
		dispatch({ type: "SET_SUBMIT_STATE", payload: { isSubmittingNew: true } });

		try {
			const result = await submitFormData();
			if (!result) return;

			// Preserve current location data
			const currentLocationData = {
				location_id: form.getFieldValue("location_id"),
				location: form.getFieldValue("location"),
			};

			// Preserve form metadata
			const preservedMetadata = {
				config: formConfig,
				formName,
				formLogo,
				locationState: location.state,
			};

			// Cancel auto-saves
			saveProgressDebounced.cancel();

			// Reset state management
			dispatch({ type: "RESET_FORM_STATE" });

			// Reset form but preserve location
			form.resetFields();

			// Restore form structure with location
			setFormConfig(preservedMetadata.config);
			setFormName(preservedMetadata.formName);
			setFormLogo(preservedMetadata.formLogo);

			// Set up new form with preserved location
			const newInitialValues = {
				location_id: currentLocationData.location_id,
				location: currentLocationData.location,
			};

			// Update form state
			setFormData(newInitialValues);
			form.setFieldsValue(newInitialValues);

			// Re-enable features
			setInternalDataLoaded(true);
			setShouldAutoSave(user?.organization?.config?.auto_save_drafts ?? false);
			setIsCompleted(false);
			setPluginEnhancements({});
			setDraftId(undefined);
			setDraftDisabled(false);
			setSkipWizardFields([]);
			setSkipWizardSelections({});
			setSkipWizardOpen(false);
			setSkipWizardOrigin(null);

			message.success("Started new form with preserved location.");
		} catch (err) {
			console.error("Submit & Start New error:", err);
			message.error("Failed to submit and start a new form.");
		} finally {
			dispatch({
				type: "SET_SUBMIT_STATE",
				payload: { isSubmittingNew: false },
			});
		}
	}

	/** Bottom navigation buttons */
	function renderNavigationButtons() {
		const { isSubmitting, isSubmittingNew } = state.submitState;
		return (
			<div style={{ marginTop: "24px", textAlign: "center" }}>
				{state.currentStep > 0 && (
					<Button
						style={{ margin: "0 8px" }}
						onClick={prev}
						disabled={isSubmitting || isSubmittingNew}
					>
						Previous
					</Button>
				)}
				{state.currentStep < stepsConfig.length - 1 && (
					<Button
						type="primary"
						onClick={next}
						disabled={isSubmitting || isSubmittingNew}
					>
						Next
					</Button>
				)}
				{state.currentStep === stepsConfig.length - 1 && (
					<>
						<Button
							type="primary"
							style={{ margin: "0 8px" }}
							loading={isSubmitting}
							disabled={isSubmittingNew}
							onClick={(e) => {
								e.preventDefault();
								onFinish("submit");
							}}
						>
							Submit
						</Button>
						<Button
							type="primary"
							loading={isSubmittingNew}
							disabled={isSubmitting}
							onClick={(e) => {
								e.preventDefault();
								onFinish("submitAndNew");
							}}
						>
							Submit and Start New
						</Button>
					</>
				)}
			</div>
		);
	}

	const isMultipleSteps = stepsConfig.length > 10;

	/*-------------------------------------------------------------------
	 * Render
	 *-------------------------------------------------------------------*/
	return formConfig?.config?.sections ? (
		<Row className="px-2 w-100">
			<Form
				key={formKey}
				className="w-100"
				form={form}
				layout="vertical"
				onValuesChange={onFormValuesChange}
			>
				<div
					style={{ textAlign: "center", marginBottom: "12px" }}
					className="d-flex flex-column justify-content-center align-items-center gap-4"
				>
					{formLogo && (
						<img src={formLogo} alt="Form Logo" style={{ maxWidth: "200px" }} />
					)}
					<Title level={2}>{formName}</Title>
				</div>

				{!isMultipleSteps ? (
					<div className="steps-container d-flex flex-column align-items-center my-4 px-3">
						<Steps
							current={state.currentStep}
							size="default"
							className="steps-wrapper w-100"
							responsive
							direction="horizontal"
						>
							{stepsConfig.map((item, index) => (
								<Step
									key={index}
									className="step-item"
									onClick={async () => {
										if (
											index === state.currentStep ||
											(index > state.currentStep &&
												!(await validateCurrentStep()))
										) {
											return;
										}
										handleStepChange(index);
									}}
									icon={
										<Tooltip title={item.title}>
											<span
												className={`step-icon ${
													index === state.currentStep ? "active" : ""
												}`}
											>
												{index + 1}
											</span>
										</Tooltip>
									}
								/>
							))}
						</Steps>
						<div className="step-titles d-flex justify-content-between w-100 mt-2">
							{stepsConfig.map((_, idx) => (
								<div
									key={`${idx}-${stepsConfig[idx].title}`}
									className={`step-title text-center ${
										idx === state.currentStep ? "active" : ""
									}`}
									style={{
										width: `${100 / stepsConfig.length}%`,
										whiteSpace: "nowrap",
										overflow: "hidden",
										textOverflow: "ellipsis",
									}}
								>
									<span>&nbsp;</span>
								</div>
							))}
						</div>
					</div>
				) : (
					<div className="progress-container d-flex flex-column align-items-center my-4 px-3">
						<Progress
							percent={Number.parseInt(
								(((state.currentStep + 1) / stepsConfig.length) * 100).toFixed(
									0,
								),
							)}
							status="active"
							showInfo
							className="progress-bar w-100"
							format={(percent) => `Step ${percent}% Completed`}
						/>
						<div className="step-select mt-3 d-flex align-items-center">
							<span>Navigate to: </span>
							<Select
								value={state.currentStep + 1}
								onChange={(val) => handleStepChange(val - 1)}
								style={{ marginLeft: "8px", minWidth: "120px" }}
								size="small"
							>
								{stepsConfig.map((st, idx) => (
									<Select.Option key={idx} value={idx + 1}>
										{`Step ${idx + 1}: ${st.title}`}
									</Select.Option>
								))}
							</Select>
						</div>
					</div>
				)}

				<div className="step-title text-center mt-4">
					<Title level={5}>{stepsConfig[state.currentStep]?.title}</Title>
				</div>

				{/* Manage Skipped button, if any fields are disabled */}
				{getManageSkippedCount() > 0 && (
					<div style={{ textAlign: "center", margin: "8px 0" }}>
						<Button onClick={handleManageSkippedClick} type="default">
							Manage Skipped Fields
						</Button>
					</div>
				)}

				{/* Render the step's content */}
				{stepsConfig[state.currentStep]?.content ? (
					<div style={{ marginTop: "24px" }}>
						{stepsConfig[state.currentStep].content}
					</div>
				) : (
					<Row className="px-2 w-100 d-flex flex-col justify-content-center align-content-center">
						<Loader />
					</Row>
				)}

				{/* Image preview modal */}
				<Modal
					open={previewVisible}
					title={previewTitle}
					footer={null}
					onCancel={() => setPreviewVisible(false)}
				>
					<Image
						src={previewImage}
						alt="Preview"
						wrapperClassName="w-100 cursor-pointer d-flex justify-content-center align-items-center"
						preview={{
							onVisibleChange: (vis) => {
								if (vis) return setPreviewVisible(false);
								return setPreviewVisible(true);
							},
							destroyOnClose: true,
							closeIcon: (
								<span
									className="p-2 bg-white"
									style={{ borderRadius: token.borderRadius }}
								>
									<LuXCircle size="1.5rem" color={token.colorPrimary} />
								</span>
							),
							maskStyle: { backgroundColor: token.colorBgMask },
							toolbarRender(originalNode) {
								return (
									<div
										style={{
											color: token.colorPrimary,
											backgroundColor: token.colorWhite,
											borderRadius: token.borderRadius,
										}}
									>
										{originalNode}
									</div>
								);
							},
						}}
					/>
				</Modal>

				{/* Skip Wizard */}
				<Modal
					open={skipWizardOpen}
					title={
						skipWizardOrigin === "manage"
							? "Skip or Unskip Fields (Section)"
							: "Skip Required Fields?"
					}
					onOk={handleSkipWizardOk}
					onCancel={handleSkipWizardCancel}
				>
					<p>
						Any field you <strong>check</strong> here is "skipped" (disabled).
						Unchecked fields remain required.
					</p>
					<Checkbox
						style={{ marginBottom: "1rem" }}
						indeterminate={
							Object.values(skipWizardSelections).some((v) => v) &&
							Object.values(skipWizardSelections).some((v) => !v)
						}
						checked={
							Object.values(skipWizardSelections).length > 0 &&
							Object.values(skipWizardSelections).every((v) => v)
						}
						onChange={(e) => {
							const checkAll = e.target.checked;
							const updated = { ...skipWizardSelections };
							Object.keys(updated).forEach((k) => {
								updated[k] = checkAll;
							});
							setSkipWizardSelections(updated);
						}}
					>
						Select All
					</Checkbox>
					<ul style={{ paddingLeft: 16 }}>
						{skipWizardFields.map((wf) => (
							<li key={wf.name} style={{ listStyle: "none", marginBottom: 6 }}>
								<Checkbox
									checked={skipWizardSelections[wf.name]}
									onChange={(e) => {
										setSkipWizardSelections((prev) => ({
											...prev,
											[wf.name]: e.target.checked,
										}));
									}}
								>
									{wf.label}
								</Checkbox>
							</li>
						))}
					</ul>
					<p>
						When you click OK, checked fields become disabled; unchecked fields
						become enabled.
					</p>
				</Modal>

				{/* Bottom nav buttons */}
				{renderNavigationButtons()}
			</Form>
		</Row>
	) : (
		<Row className="px-2 w-100 d-flex flex-col justify-content-center align-content-center">
			<Spin indicator={<Loader />} tip="Loading form..." />
		</Row>
	);
};
