import { AvatarUpload } from "@/layouts/signed-in/Avatar/AvatarUpload";
import { auth } from "@/services/auth-service";
import { selfUpdateUserComprehensive } from "@/services/user-service";
import { useUserStore } from "@/stores";
import { IUser } from "@/stores/user-store";
import {
	CheckOutlined,
	CloseOutlined,
	EditOutlined,
	LockOutlined,
} from "@ant-design/icons";
import { Button, Card, Input, Select, Spin, Typography, message } from "antd";
import { updateProfile } from "firebase/auth";
import type { FC } from "react";
import { useReducer } from "react";

const { Title, Text } = Typography;
const { Option } = Select;

interface ProfileState {
	nameInput: {
		value: string;
		isEditing: boolean;
	};
	isLoading: boolean;
}

type ProfileAction =
	| { type: "SET_NAME_INPUT"; payload: string }
	| { type: "TOGGLE_NAME_EDIT" }
	| { type: "SET_LOADING"; payload: boolean };

const initialState: ProfileState = {
	nameInput: {
		value: "",
		isEditing: false,
	},
	isLoading: false,
};

const profileReducer = (
	state: ProfileState,
	action: ProfileAction,
): ProfileState => {
	switch (action.type) {
		case "SET_NAME_INPUT":
			return {
				...state,
				nameInput: {
					...state.nameInput,
					value: action.payload,
				},
			};
		case "TOGGLE_NAME_EDIT":
			return {
				...state,
				nameInput: {
					...state.nameInput,
					isEditing: !state.nameInput.isEditing,
				},
			};
		case "SET_LOADING":
			return {
				...state,
				isLoading: action.payload,
			};
		default:
			return state;
	}
};

const Profile: FC = () => {
	const { user, setUser } = useUserStore();

	const [state, dispatch] = useReducer(profileReducer, {
		...initialState,
		nameInput: {
			value: auth?.currentUser?.displayName || "",
			isEditing: false,
		},
	});

	const handleUpdateUsername = async () => {
		if (
			!auth.currentUser ||
			!state.nameInput.value ||
			state.nameInput.value === auth.currentUser.displayName ||
			!user
		) {
			return;
		}

		const previousUser = user;
		const updatedUser: IUser = {
			...user,
			name: state.nameInput.value,
		};

		try {
			dispatch({ type: "SET_LOADING", payload: true });

			// Update Firebase profile first
			await updateProfile(auth.currentUser, {
				displayName: state.nameInput.value,
			});

			// Sync with backend and get complete user data
			const response = await selfUpdateUserComprehensive(updatedUser);
			setUser(response);

			message.success("Username successfully updated");
			dispatch({ type: "TOGGLE_NAME_EDIT" });
		} catch (error: any) {
			// Rollback on failure
			setUser(previousUser);
			message.error(error.message || "Failed to update username");
		} finally {
			dispatch({ type: "SET_LOADING", payload: false });
		}
	};

	const handleOrganizationChange = async (value: string) => {
		if (!user) return;

		const previousUser = user;
		const selectedOrg = user.allowed_organizations.find(
			(org) => org.external_firebase_id === value,
		);

		if (!selectedOrg) return;

		const updatedUser: IUser = {
			...user,
			organization_id: selectedOrg.id,
		};

		try {
			dispatch({ type: "SET_LOADING", payload: true });

			// Sync with backend and get complete user data with new org details
			const response = await selfUpdateUserComprehensive(updatedUser);
			setUser(response);

			message.success("Organization has been changed");
		} catch (error: any) {
			// Rollback on failure
			setUser(previousUser);
			message.error(error.message || "Failed to update organization");
		} finally {
			dispatch({ type: "SET_LOADING", payload: false });
		}
	};

	const renderNameInput = () => (
		<div className="flex items-center gap-2">
			{state.nameInput.isEditing ? (
				<>
					<Input
						value={state.nameInput.value}
						onChange={(e) =>
							dispatch({ type: "SET_NAME_INPUT", payload: e.target.value })
						}
						className="max-w-xs"
					/>
					<Button
						type="text"
						icon={<CheckOutlined />}
						onClick={handleUpdateUsername}
					/>
					<Button
						type="text"
						icon={<CloseOutlined />}
						onClick={() => dispatch({ type: "TOGGLE_NAME_EDIT" })}
					/>
				</>
			) : (
				<>
					<Text>{user?.name}</Text>
					<Button
						type="text"
						icon={<EditOutlined />}
						onClick={() => dispatch({ type: "TOGGLE_NAME_EDIT" })}
					/>
				</>
			)}
		</div>
	);

	if (!user) {
		return (
			<div className="flex justify-center items-center h-full">
				<Spin size="large" />
			</div>
		);
	}

	return (
		<div className="max-w-2xl mx-auto p-6 bg-white rounded-lgjustify-center items-center">
			<Title level={4} className="mb-2">
				Profile
			</Title>
			<Text className="text-gray-500 block mb-6">
				Manage your personal information and photo here.
			</Text>

			<Card className="shadow-sm">
				<div className="flex flex-col items-center mb-6">
					<div className="w-24 h-24 mb-4">
						<AvatarUpload />
					</div>
					<Title level={5} className="m-0">
						{user.name}
					</Title>
				</div>

				<div className="space-y-6">
					<div>
						<Text className="text-gray-500 block mb-2">Preferred Name</Text>
						{renderNameInput()}
					</div>

					<div>
						<Text className="text-gray-500 block mb-2">Email</Text>
						<Input disabled value={user.email} className="max-w-xs" />
					</div>

					<div>
						<Text className="text-gray-500 block mb-2">Password</Text>
						<Input.Password
							disabled
							value="********"
							prefix={<LockOutlined />}
							className="max-w-xs"
						/>
					</div>

					<div>
						<Text className="text-gray-500 block mb-2">Organization</Text>
						<Select
							className="w-full max-w-xs"
							value={user.organization.external_firebase_id}
							onChange={handleOrganizationChange}
							disabled={!user.allowed_organizations?.length || state.isLoading}
						>
							{user.allowed_organizations?.map((org) => (
								<Option key={org.id} value={org.external_firebase_id}>
									{org.full_name}
								</Option>
							))}
						</Select>
					</div>

					<div>
						<Text className="text-gray-500 block mb-2">Role</Text>
						<Input disabled value={user.role.name} className="max-w-xs" />
					</div>
				</div>
			</Card>
		</div>
	);
};

export { Profile };
