import { AppInitLoader } from "@/components/loader/app-init-loader";
import { useAuth } from "@/contexts/auth-context";
import { useLogout } from "@/hooks/auth-queries";
import { SignedIn } from "@/layouts/signed-in/SignedIn";
import { usePageStore } from "@/stores/page-store";
import { useUserStore } from "@/stores/user-store";
import { Login } from "@/views/Login/Login";
import { message } from "antd";
import { useEffect, useRef } from "react";
import { useIdleTimer } from "react-idle-timer";

const IDLE_TIMEOUT = 60 * 60 * 1000; // 1 hour in milliseconds
const REFRESH_INTERVAL = 5 * 60 * 1000; // 5 minutes in milliseconds
const USER_STORAGE_KEY = "dfp-user";

export const ProtectedRoute = () => {
	const user = useUserStore((state) => state.user);
	const { setUser } = useUserStore();
	const { isAuthInitialized } = useAuth();
	const logout = useLogout();
	const refreshIntervalRef = useRef<ReturnType<typeof setInterval>>();
	const { isLoading } = usePageStore();

	// Listen for storage changes across tabs
	// biome-ignore lint/correctness/useExhaustiveDependencies: We just want to listen for storage changes across tabs
	useEffect(() => {
		const handleStorageChange = (event: StorageEvent) => {
			if (event.key === USER_STORAGE_KEY && event.newValue) {
				try {
					const newState = JSON.parse(event.newValue);
					setUser(newState.state?.user || null);
				} catch (error) {
					console.error("Failed to parse storage update:", error);
				}
			}
		};

		window.addEventListener("storage", handleStorageChange);
		return () => window.removeEventListener("storage", handleStorageChange);
	}, []);

	// Periodic user state refresh
	// biome-ignore lint/correctness/useExhaustiveDependencies: We just want to refresh the user state periodically
	// useEffect(() => {
	// 	const refreshUserState = async () => {
	// 		if (!user || !isAuthInitialized) return;
	// 		try {
	// 			const response = await selfUpdateUserComprehensive(user);
	// 			if (response) {
	// 				const changedProps = getChangedProps(user, response);
	// 				if (Object.keys(changedProps).length > 0) {
	// 					setUser({ ...user, ...changedProps });
	// 				}
	// 			}
	// 		} catch (error) {
	// 			console.error("Failed to refresh user state:", error);
	// 		}
	// 	};

	// 	// Start the interval when user is logged in
	// 	if (user) {
	// 		refreshUserState(); // Initial refresh
	// 		refreshIntervalRef.current = setInterval(
	// 			refreshUserState,
	// 			REFRESH_INTERVAL,
	// 		);
	// 	}

	// 	// Cleanup interval on unmount or when user logs out
	// 	return () => {
	// 		if (refreshIntervalRef.current) {
	// 			clearInterval(refreshIntervalRef.current);
	// 		}
	// 	};
	// }, [isAuthInitialized]);

	const handleOnIdle = async () => {
		if (!user) return;

		try {
			const result = await logout();
			if (result.success) {
				message.info("You have been logged out due to inactivity");
			} else {
				message.error(result.error || "Failed to logout due to inactivity");
			}
		} catch (error) {
			console.error("An error occurred during automatic logout", error);
			message.error("An error occurred during automatic logout");
		}
	};

	useIdleTimer({
		timeout: IDLE_TIMEOUT,
		onIdle: handleOnIdle,
		debounce: 500,
	});

	if (!isAuthInitialized || isLoading) {
		return (
			<div className="d-flex justify-content-center align-items-center h-100 w-100 align-self-center">
				<AppInitLoader />
			</div>
		);
	}

	return user ? <SignedIn /> : <Login />;
};

export default ProtectedRoute;
