import { DFPEmpty } from "@/components/empty/dfp-empty";
import { ALFRED_SERVICE_URL } from "@/constants/env";
// DynamicLocationPicker.tsx
import { auth } from "@/services/auth-service";
import { CloseCircleFilled } from "@ant-design/icons";
import {
	Button,
	Input,
	InputProps,
	List,
	Modal,
	Spin,
	message,
	theme,
} from "antd";
import {
	ChangeEvent,
	type MouseEvent,
	useCallback,
	useEffect,
	useReducer,
	useRef,
} from "react";
import { FiSearch } from "react-icons/fi";
import { HiOutlineLocationMarker } from "react-icons/hi";

interface Location {
	id: string;
	name: string;
	address1?: string;
	address2?: string;
	city?: string;
	state?: string;
	distance?: number;
	latitude?: number;
	longitude?: number;
}

export interface DynamicLocationPickerProps
	extends Omit<InputProps, "value" | "onChange" | "disabled" | "placeholder"> {
	value?: string | null;
	onChange: (value: string | null) => void;
	disabled: boolean;
	placeholder: string;
}

const { Search } = Input;

interface LocationState {
	isOpen: boolean;
	isLoading: boolean;
	searchVal: string;
	searchedLocations: Location[];
	geoLocation: Location[];
	latLon: {
		latitude?: number;
		longitude?: number;
	};
	locationText: string;
	locationValue: string | null | undefined;
}

type LocationAction =
	| { type: "TOGGLE_MODAL"; payload: boolean }
	| { type: "SET_LOADING"; payload: boolean }
	| { type: "SET_SEARCH_VALUE"; payload: string }
	| { type: "SET_SEARCH_RESULTS"; payload: Location[] }
	| { type: "SET_GEO_LOCATION"; payload: Location[] }
	| { type: "SET_LAT_LON"; payload: { latitude?: number; longitude?: number } }
	| { type: "SET_LOCATION_TEXT"; payload: string }
	| { type: "SET_LOCATION_VALUE"; payload: string | null | undefined };

const initialState: LocationState = {
	isOpen: false,
	isLoading: false,
	searchVal: "",
	searchedLocations: [],
	geoLocation: [],
	latLon: {},
	locationText: "Select a Location",
	locationValue: null,
};

const locationReducer = (
	state: LocationState,
	action: LocationAction,
): LocationState => {
	switch (action.type) {
		case "TOGGLE_MODAL":
			return { ...state, isOpen: action.payload };
		case "SET_LOADING":
			return { ...state, isLoading: action.payload };
		case "SET_SEARCH_VALUE":
			return { ...state, searchVal: action.payload };
		case "SET_SEARCH_RESULTS":
			return { ...state, searchedLocations: action.payload };
		case "SET_GEO_LOCATION":
			return { ...state, geoLocation: action.payload };
		case "SET_LAT_LON":
			return { ...state, latLon: action.payload };
		case "SET_LOCATION_TEXT":
			return { ...state, locationText: action.payload };
		case "SET_LOCATION_VALUE":
			return { ...state, locationValue: action.payload };
		default:
			return state;
	}
};

export const DynamicLocationPicker = ({
	value,
	onChange,
	disabled,
	placeholder,
	allowClear = false,
}: DynamicLocationPickerProps) => {
	const [state, dispatch] = useReducer(locationReducer, initialState);
	const { token } = theme.useToken();

	// This ref is used to cancel auto-selection.
	const cancelAutoSelect = useRef(false);

	const locationDetailsCache = useRef<Record<string, Location>>({});
	const locationDetailsPromises = useRef<Record<string, Promise<Location>>>({});
	const geolocationsPromise = useRef<Promise<Location[]> | null>(null);
	const searchPromises = useRef<Record<string, Promise<Location[]>>>({});
	const geolocationProcessPromise = useRef<Promise<void> | null>(null);
	const searchDebounceTimer = useRef<NodeJS.Timeout>();

	const {
		isOpen,
		isLoading,
		searchVal,
		searchedLocations,
		geoLocation,
		locationText,
	} = state;

	const formatLocationText = useCallback((location: Location): string => {
		const name = location?.name || "";
		const address1 = location?.address1 || "";
		return name || address1
			? `${name}${name && address1 ? ": " : ""}${address1}`
			: "Select a Location";
	}, []);

	const handleClear = useCallback(
		(e: MouseEvent<HTMLElement>) => {
			e.stopPropagation(); // Prevent modal from opening
			dispatch({ type: "SET_LOCATION_TEXT", payload: "Select a Location" });
			dispatch({ type: "SET_LOCATION_VALUE", payload: undefined });
			onChange?.(null);
		},
		[onChange],
	);

	const selectLocation = useCallback(
		(location: Location) => {
			if (!location) return;
			const text = formatLocationText(location);
			dispatch({ type: "SET_LOCATION_TEXT", payload: text });
			onChange?.(location.id);
			dispatch({ type: "TOGGLE_MODAL", payload: false });
		},
		[onChange, formatLocationText],
	);

	const getGeoLocations = useCallback(
		async (latitude: number, longitude: number) => {
			try {
				if (geolocationsPromise.current) {
					return geolocationsPromise.current;
				}

				geolocationsPromise.current = (async () => {
					const url = `${ALFRED_SERVICE_URL}/location/geo/search?latitude=${latitude}&longitude=${longitude}`;
					const response = await fetch(url, {
						method: "GET",
						headers: {
							Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
						},
					});

					if (response.status !== 200) {
						const errorDetails = await response.json();
						throw new Error(
							errorDetails.detail || "Failed to fetch geo-locations",
						);
					}

					const results = await response.json();
					return [...results].sort(
						(a, b) => (a.distance || 0) - (b.distance || 0),
					);
				})();

				const sortedResults = await geolocationsPromise.current;
				dispatch({ type: "SET_GEO_LOCATION", payload: sortedResults });

				// Only auto-select if:
				// - we have results,
				// - no location has been chosen,
				// - AND the auto-select hasn’t been cancelled.
				if (sortedResults.length > 0 && !value && !cancelAutoSelect.current) {
					selectLocation(sortedResults[0]);
				}

				return sortedResults;
			} catch (error) {
				console.error("Geo-location fetch error:", error);
				message.error("Failed to fetch closest locations.");
				return [];
			} finally {
				dispatch({ type: "SET_LOADING", payload: false });
				geolocationsPromise.current = null;
			}
		},
		[value, selectLocation],
	);

	const requestGeolocation = useCallback(() => {
		if (!("geolocation" in navigator)) return;

		// If we already have a geolocation process running, return it
		if (geolocationProcessPromise.current) {
			return geolocationProcessPromise.current;
		}

		dispatch({ type: "SET_LOADING", payload: true });

		geolocationProcessPromise.current = new Promise<void>((resolve) => {
			navigator.geolocation.getCurrentPosition(
				async (position) => {
					try {
						const { latitude, longitude } = position.coords;
						dispatch({ type: "SET_LAT_LON", payload: { latitude, longitude } });
						await getGeoLocations(latitude, longitude);
					} finally {
						resolve();
					}
				},
				(error) => {
					console.error("Geolocation error:", error);
					message.warning(
						"Location services disabled. Please search for a location.",
					);
					dispatch({ type: "SET_LOADING", payload: false });
					resolve();
				},
				{ enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 },
			);
		}).finally(() => {
			geolocationProcessPromise.current = null;
		});

		return geolocationProcessPromise.current;
	}, [getGeoLocations]);

	const fetchLocationById = useCallback(async (id: string) => {
		try {
			if (locationDetailsCache.current[id]) {
				return locationDetailsCache.current[id];
			}

			if (!locationDetailsPromises.current[id]) {
				locationDetailsPromises.current[id] = (async () => {
					try {
						const url = `${ALFRED_SERVICE_URL}/location/${id}`;
						const response = await fetch(url, {
							method: "GET",
							headers: {
								Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
							},
						});

						if (response.status !== 200) {
							throw new Error("Failed to fetch location data");
						}

						const locationData = await response.json();
						locationDetailsCache.current[id] = locationData;
						return locationData;
					} finally {
						delete locationDetailsPromises.current[id];
					}
				})();
			}

			return await locationDetailsPromises.current[id];
		} catch (error) {
			console.error("Error fetching location by ID:", error);
			message.error("Failed to fetch location data.");
			throw error;
		}
	}, []);

	const searchForLocation = useCallback(async (searchValue: string) => {
		try {
			// Check if there's an existing pending promise and return its result
			const existingPromise = searchPromises.current[searchValue];
			if (existingPromise) {
				const results = await existingPromise;
				dispatch({ type: "SET_SEARCH_RESULTS", payload: results });
				return results;
			}

			searchPromises.current[searchValue] = (async () => {
				const url = `${ALFRED_SERVICE_URL}/location/search/location_picker?search_value=${encodeURIComponent(
					searchValue,
				)}`;
				const response = await fetch(url, {
					method: "GET",
					headers: {
						Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
					},
				});

				if (response.status !== 200) {
					const errorDetails = await response.json();
					throw new Error(
						errorDetails.detail || "Failed to search for location",
					);
				}

				return await response.json();
			})();

			const results = await searchPromises.current[searchValue];
			dispatch({ type: "SET_SEARCH_RESULTS", payload: results });
			return results;
		} catch (error) {
			console.error("Location search error:", error);
			message.error("Failed to search for locations.");
			return [];
		} finally {
			dispatch({ type: "SET_LOADING", payload: false });
			delete searchPromises.current[searchValue];
		}
	}, []);

	const updateSearchValue = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			const val = e.target.value;
			dispatch({ type: "SET_SEARCH_VALUE", payload: val });

			if (val.trim() === "") {
				dispatch({ type: "SET_SEARCH_RESULTS", payload: [] });
				return;
			}

			// Clear any existing timer
			if (searchDebounceTimer.current) {
				clearTimeout(searchDebounceTimer.current);
			}

			dispatch({ type: "SET_LOADING", payload: true });

			// Set new debounce timer
			searchDebounceTimer.current = setTimeout(() => {
				searchForLocation(val);
			}, 500) as unknown as NodeJS.Timeout;
		},
		[searchForLocation],
	);

	// Cleanup debounce timer on unmount
	useEffect(() => {
		return () => {
			if (searchDebounceTimer.current) {
				clearTimeout(searchDebounceTimer.current);
			}
		};
	}, []);

	const toggleModal = useCallback(() => {
		const newIsOpen = !isOpen;
		dispatch({ type: "TOGGLE_MODAL", payload: newIsOpen });

		if (newIsOpen) {
			// When the user manually opens the modal, cancel auto‑select.
			cancelAutoSelect.current = true;
			dispatch({ type: "SET_SEARCH_RESULTS", payload: [] });
			dispatch({ type: "SET_SEARCH_VALUE", payload: "" });
			// Even if a geolocation request is already in flight from initialization,
			// its auto‑selection result will be ignored.
			if (!geolocationProcessPromise.current) {
				requestGeolocation();
			}
		}
	}, [isOpen, requestGeolocation]);

	useEffect(() => {
		const initializeLocation = async () => {
			if (value) {
				try {
					const locationData = await fetchLocationById(value);
					const text = formatLocationText(locationData);
					dispatch({ type: "SET_LOCATION_TEXT", payload: text });
				} catch {
					dispatch({
						type: "SET_LOCATION_TEXT",
						payload: "Error loading location",
					});
				}
			} else {
				dispatch({ type: "SET_LOCATION_TEXT", payload: "Select a Location" });
				// On initial mount, if no location is chosen and auto‑select hasn’t been cancelled,
				// then request geolocation.
				if (
					!geolocationProcessPromise.current &&
					value === undefined &&
					!cancelAutoSelect.current
				) {
					requestGeolocation();
				}
			}
		};

		initializeLocation();
	}, [value, fetchLocationById, formatLocationText, requestGeolocation]);

	const renderLocations = () => {
		const locations = searchVal ? searchedLocations : geoLocation;
		const sortedLocations = [...locations].sort(
			(a, b) => (a.distance || 0) - (b.distance || 0),
		);

		if (isLoading) {
			return (
				<div style={{ textAlign: "center", marginTop: "20px" }}>
					<Spin tip="Loading locations..." />
				</div>
			);
		}

		if (sortedLocations.length === 0) {
			return (
				<DFPEmpty
					image={
						<HiOutlineLocationMarker size={"4rem"} color={token.colorPrimary} />
					}
					description="No locations found. Please try a different search term."
				/>
			);
		}

		return (
			<List
				itemLayout="vertical"
				dataSource={sortedLocations}
				renderItem={(loc, i) => (
					<List.Item
						key={`loc-${loc.id}-${i}`}
						onClick={() => selectLocation(loc)}
						style={{ cursor: "pointer" }}
					>
						<div>
							{searchVal === "" && loc.distance && (
								<div style={{ fontSize: "12px", color: "#888" }}>
									{i === 0
										? `Closest Location - ${loc.distance} miles`
										: i === 1
											? `2nd Closest Location - ${loc.distance} miles`
											: i === 2
												? `3rd Closest Location - ${loc.distance} miles`
												: null}
								</div>
							)}
							<div>
								{`${loc.name ? `${loc.name}: ` : ""}${
									loc.address1 || loc.address2 || ""
								}, ${loc.city}, ${loc.state}`}
							</div>
						</div>
					</List.Item>
				)}
			/>
		);
	};

	return (
		<div>
			<Button
				type="primary"
				size="large"
				loading={isLoading}
				onClick={toggleModal}
				disabled={disabled}
				style={{ width: "100%", textAlign: "left" }}
				icon={<HiOutlineLocationMarker size={"1.25rem"} />}
			>
				{locationText}
				{allowClear && value && (
					<Button
						type="text"
						size="small"
						onClick={handleClear}
						style={{
							position: "absolute",
							right: "8px",
							top: "50%",
							transform: "translateY(-50%)",
							padding: "4px",
							height: "auto",
							color: "white",
						}}
						icon={<CloseCircleFilled />}
					/>
				)}
			</Button>
			<Modal
				open={isOpen}
				onCancel={toggleModal}
				footer={null}
				title="Select a Location"
				destroyOnClose
			>
				<Search
					placeholder={placeholder || "Type to Search for Location"}
					onChange={updateSearchValue}
					autoFocus={true}
					prefix={<FiSearch />}
					allowClear
					style={{ marginBottom: "20px" }}
				/>
				<div style={{ overflowY: "auto", maxHeight: "400px" }}>
					{renderLocations()}
				</div>
			</Modal>
		</div>
	);
};
