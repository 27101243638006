import { DeleteOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { type FC } from "react";

interface DeleteButtonProps {
	checkedItems: unknown[];
	viewingDeleted: boolean;
	onClick: () => void;
}

const DeleteButton: FC<DeleteButtonProps> = ({
	checkedItems,
	viewingDeleted,
	onClick,
}) => {
	return (
		<Button
			onClick={onClick}
			type="text"
			danger
			icon={<DeleteOutlined style={{ fontSize: "20px" }} />}
			disabled={checkedItems?.length === 0 || viewingDeleted}
		/>
	);
};

export { DeleteButton };
